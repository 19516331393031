import React, { FC, useEffect, useState } from "react";
import { Page, QuestionTypes, Option, Subquestion, MatrixQuestion } from "../../../../../models/SurveyTemplate";
import { Condition, Dependency } from "../../../../../models/SurveyVm";
import _ from "lodash";
import { Button, Switch, Cascader, Input, DatePicker, Select, Space, Radio, Empty } from "antd";
import { PlusOutlined, PauseOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import styles from "./DependencyProperties.module.css";
import moment from "moment";
import { RadioChangeEvent } from "antd/lib/radio";

const DependencyProperties: FC<DependencyPropertiesProps> = ({ question, pages, onUpdateQuestion }) => {
    const availableQuestions = () => {
        const allQuestions = pages.flatMap(p => p.questions);
        const selectedQuestionIdx = allQuestions.findIndex(q => q.id === question.id);
        return allQuestions.slice(0, selectedQuestionIdx).filter(q => q.id !== question.id);
    };

    const updateConnector = (e: RadioChangeEvent) => {
        console.log(question);
        const dependency = question.dependency || emptyDependency();
        onUpdateQuestion({ ...question, dependency: { ...dependency, connector: e.target.value } })
    }

    const addCondition = () => {
        const dependency: Dependency = _.cloneDeep(question.dependency || emptyDependency());
        dependency.conditions.push(emptyCondition());
        onUpdateQuestion({ ...question, dependency });
    }

    const updateCondition = (condition: Condition, index: number) => {
        const dependency = _.cloneDeep(question.dependency);
        if (dependency) {
            dependency.conditions.splice(index, 1, condition);
            onUpdateQuestion({ ...question, dependency });
        }
    }

    const deleteCondition = (index: number) => {
        const dependency = _.cloneDeep(question.dependency);
        if (dependency) {
            dependency.conditions.splice(index, 1);
            onUpdateQuestion({ ...question, dependency });
        }
    }

    return (<>
        {availableQuestions().length > 0 ?
            <Space style={{ width: "100%" }} direction="vertical">
                <Radio.Group name="connector"
                    value={question.dependency?.connector || "And"}
                    onChange={updateConnector}
                >
                    <Radio className={styles.connectors} value="And">Und-Verknüpfung</Radio>
                    <Radio className={styles.connectors} value="Or">Oder-Verknüpfung</Radio>
                </Radio.Group>
                {question.dependency?.conditions &&
                    question.dependency?.conditions
                        .map((c, i) =>
                            <DependencyCondition
                                key={i}
                                condition={c}
                                questions={availableQuestions()}
                                onUpdateCondition={(c) => updateCondition(c, i)}
                                onDeleteCondition={() => deleteCondition(i)}
                            />)
                }
                <Button size="small" type="primary" onClick={addCondition} icon={<PlusOutlined />}>Bedingung hinzufügen</Button>
            </Space> :
            <Empty description={
                <span>Es sind keine Fragen vorhanden, zu denen Abhängigkeiten angelegt werden können.</span>
            }/>
        }
    </>);
}

const DependencyCondition: FC<DependencyConditionProps> = ({ condition, questions, onUpdateCondition, onDeleteCondition }) => {
    const createCascaderOption = (label: string, value: string, children?: Subquestion[]): CascaderOptionType => ({
        label,
        value,
        children: children?.map(c => createCascaderOption(c.description, c.id))
    });

    const cascaderOptions = questions.map(q => {
        if (q.type === "Matrix") {
            const matrix = q as MatrixQuestion;
            return createCascaderOption(matrix.title, matrix.id, matrix.questions);
        }
        return createCascaderOption(q.title, q.id);
    });

    const getSelectedValues = () => {
        const selectedValues = [condition.questionId] || [];
        if (condition.subquestionId) {
            selectedValues.push(condition.subquestionId);
        }
        return selectedValues;
    };

    const updateValue = (value: string) => {
        onUpdateCondition({ ...condition, value, comment: undefined });
    };

    const updateComment = (comment: string) => {
        onUpdateCondition({ ...condition, comment });
    };

    const getConditionComponent = () => {
        const question = questions.find(q => q.id === condition.questionId);
        if (!question) {
            return;
        }

        const { value, comment } = condition;
        const getConditionComponent = () => {
            switch (question.type) {
                case "Input": return <InputCondition value={value} onUpdateValue={updateValue} />;
                case "Number": return <NumberCondition value={value} onUpdateValue={updateValue} />;
                case "Date": return <DateCondition value={value} onUpdateValue={updateValue} />;
                case "SingleSelection": return <OptionCondition value={value} onUpdateValue={updateValue} options={question.options} />;
                case "MultiSelection": return <OptionCondition value={value} onUpdateValue={updateValue} options={question.options} />;
                case "RadioGroup": return <OptionCondition value={value} onUpdateValue={updateValue} options={question.options} comment={comment} onUpdateComment={updateComment} />;
                case "Matrix": return <OptionCondition value={value} onUpdateValue={updateValue} options={question.options} />;
            }
        };

        return (
            <>
                <PauseOutlined className={styles.icons} rotate={90} />
                {getConditionComponent()}
            </>
        );
    };

    const onSelectQuestion = (value: CascaderValueType) => {
        const cond = emptyCondition();
        if (value.length > 0) {
            cond.questionId = value[0].toString();
        }
        if (value.length > 1) {
            cond.subquestionId = value[1].toString();
        }
        onUpdateCondition(cond);
    };

    return (
        <div className={styles.condition}>
            <div style={{ width: "40%" }}>
                <Cascader
                    className={styles.conditionitem}
                    size="small"
                    options={cascaderOptions}
                    value={getSelectedValues()}
                    onChange={onSelectQuestion}
                    allowClear={false}
                />
            </div>
            {getConditionComponent()}
            <MinusCircleOutlined className={styles.icons} onClick={onDeleteCondition} />
        </div>
    );
}

const InputCondition: FC<ConditionProps> = ({ value, onUpdateValue }) => {
    const [input, setInput] = useState("");
    useEffect(() => setInput(value), [value]);

    return (
        <Input
            size="small"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onBlur={(e) => onUpdateValue(e.target.value)}
        />
    );
};

const NumberCondition: FC<ConditionProps> = ({ value, onUpdateValue }) => {
    const [input, setInput] = useState("");
    useEffect(() => setInput(value), [value]);

    return (
        <Input
            type="number"
            size="small"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onBlur={(e) => onUpdateValue(e.target.value)}
        />
    );
};

const DateCondition: FC<ConditionProps> = ({ value, onUpdateValue }) => {
    const [input, setInput] = useState("");
    useEffect(() => setInput(value), [value]);

    return (
        <DatePicker
            size="small"
            format="DD.MM.YYYY"
            value={input ? moment(input) : null}
            onChange={(e) => setInput(e?.toISOString() || "")}
            onBlur={() => onUpdateValue(input)}
        />
    );
};

const OptionCondition: FC<OptionConditionProps> = ({ options, value, onUpdateValue, comment, onUpdateComment }) => {
    const [input, setInput] = useState("");
    useEffect(() => setInput(comment || ""), [comment]);

    return (<>
        <Select
            className={styles.conditionitem}
            size="small"
            value={value}
            onChange={(value) => onUpdateValue(value)}
        >
            {options?.map(opt => (
                <Select.Option key={opt.value} value={opt.value}>
                    {opt.text}
                </Select.Option>
            ))}
        </Select>
        {options?.find(o => o.value === value)?.withComment &&
            <Input
                size="small"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onBlur={(e) => onUpdateComment ? onUpdateComment(e.target.value) : {}}
            />}
    </>);
};

export default DependencyProperties;

function emptyDependency(): Dependency {
    return ({
        connector: "And",
        conditions: []
    });
}

function emptyCondition(): Condition {
    return ({
        questionId: "",
        value: ""
    });
}

interface DependencyPropertiesProps {
    question: QuestionTypes;
    pages: Page[];
    onUpdateQuestion: (question: QuestionTypes) => void;
}

interface DependencyConditionProps {
    condition: Condition;
    questions: QuestionTypes[];
    onUpdateCondition: (condition: Condition) => void;
    onDeleteCondition: () => void;
}

interface ConditionProps {
    value: string;
    onUpdateValue: (value: string) => void;
}

interface OptionConditionProps extends ConditionProps {
    options: Option[];
    comment?: string;
    onUpdateComment?: (comment: string) => void;
}