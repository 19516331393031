import { Button, Form, Input, Space, Switch } from "antd";
import React, { FC, useState } from "react";
import { UserOutlined, MailOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./FormItems.module.css";
import { FormInstance } from "antd/lib/form";

export const UserName: FC<{ readonly?: boolean }> = ({ readonly }) => {
    return (
        <Form.Item name="userName" rules={[
            { 
                required: true, 
                message: "Bitte geben Sie einen Benutzernamen an"
            },    
            {
                required: true,
                pattern: new RegExp("^[a-zA-Z0-9]*$"),
                message: "Der Benutzername ist ungültig und darf nur Buchstaben oder Ziffern enthalten"
            }
          ]}>
            <Input prefix={<UserOutlined />} placeholder="Benutzername" disabled={readonly} />
        </Form.Item>
    );
}

export const Email: FC<{ readonly?: boolean }> = ({ readonly }) => {
    return (
        <Form.Item className={styles.formItem} name="email" rules={[
                { 
                    required: true, 
                    message: "Bitte geben Sie eine E-Mail an" 
                },
                {
                    required: false,
                    type: "email",
                    message:
                        'Die eingegebene E-Mail Adresse ist nicht valide',
                }
            ]}>
            <Input prefix={<MailOutlined />} placeholder="E-Mail" disabled={readonly} />
        </Form.Item>
    );
}

export const PersonalData: FC = () => {
    return (
        <>
            <Form.Item className={styles.formItem} name="lastName" rules={[{ required: true, message: "Bitte geben Sie einen Namen an" }]}>
                <Input placeholder="Name" />
            </Form.Item>
            <Form.Item className={styles.formItem} name="firstName" rules={[{ required: true, message: "Bitte geben Sie einen Vornamen an" }]}>
                <Input placeholder="Vorname" />
            </Form.Item>
        </>
    );
}

export const Claims: FC<ClaimsProps> = ({form, isdefaultadmin }) => {
    const [isAdmin, setIsAdmin] = useState(form.getFieldValue("isAdmin"));
    const changeIsAdmin = (isAdmin: boolean) => {
        setIsAdmin(isAdmin);
        if(!isAdmin){
            return;
        }

        form.setFieldsValue({
            ...form.getFieldsValue(),
            isAdmin: isAdmin,
            canAddTemplates: true
        });
    };

    return (<>
        <Form.Item valuePropName="checked" className={styles.formItem} label="Ist Administrator" name="isAdmin">
            <Switch disabled={isdefaultadmin} onChange={changeIsAdmin} />
        </Form.Item>
        <Form.Item valuePropName="checked" className={styles.formItem} label="Kann Vorlagen anlegen" name="canAddTemplates">
            <Switch disabled={isdefaultadmin || isAdmin}></Switch>
        </Form.Item>
    </>);
}

interface ClaimsProps {
    form: FormInstance;
    isdefaultadmin: boolean;
}

export const CommandFooter: FC<{ onClose: () => void }> = ({ onClose }) => {
    return (
        <Form.Item>
            <div style={{ textAlign: "right" }}>
                <Space>
                    <Button icon={<CloseOutlined />} htmlType="button" type="primary" danger onClick={onClose}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} type="primary" htmlType="submit">Speichern</Button>
                </Space>
            </div>
        </Form.Item>
    );
}