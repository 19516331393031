import React, { FC } from "react";
import { PermissionClaim } from "../../../../../models/Claim";
import { Select } from "antd";

export const PermissionClaimSelection: FC<PermissionClaimSelectionProps> = ({ selectedClaim, claimOptions, onChange }) => {
    const options = claimOptions.map((claim) => ({
        key: claim.value,
        value: claim.value,
        label: claim.displaytext
    }));

    const changeClaim = (value: string) => {
        const selectedClaim = claimOptions.find(c => c.value === value);
        if (selectedClaim) {
            onChange(selectedClaim);
        }
    }

    return (
        <Select
            style={{ width: "110px"}}
            value={selectedClaim.value}
            onChange={changeClaim}
            options={options}
        />
    );
};

interface PermissionClaimSelectionProps {
    selectedClaim: PermissionClaim;
    claimOptions: PermissionClaim[];
    onChange: (value: PermissionClaim) => void;
}