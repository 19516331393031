import { SurveyTemplate, QuestionTypes, Page } from "../../../models/SurveyTemplate";
import { EditorItem, PageItem, QuestionItem, SurveyItem } from "../../../models/Editor";

export function findEditorItem(id: string, type: string, template: SurveyTemplate): EditorItem | undefined {
    switch (type) {
        case "page": {
            return {
                type: "page",
                item: template.pages.find(p => p.id === id)
            } as PageItem;
        };
        case "question": {
            return {
                type: "question",
                item: template.pages.flatMap(p => p.questions).find(question => question.id === `${id}`)
            } as QuestionItem;
        };
        default: {
            return {
                type: "survey",
                item: template
            } as SurveyItem;
        };
    }
}

export function canHaveDependencies(object: EditorItem) {
    return "dependency" in object;
}

export function canHaveMappings(object: EditorItem) {
    return "mapping" in object;
}

export function getQuestionTypeTitle(question: QuestionTypes): string {
    switch (question.type) {
        case "Input": return "Text";
        case "Textarea": return "Text (mehrzeilig)";
        case "Number": return "Zahl";
        case "Date": return "Datum";
        case "SingleSelection": return "Auswahl";
        case "MultiSelection": return "Auswahl (mehrfach)";
        case "RadioGroup": return "Auswahl (Liste)";
        case "Matrix": return "Matrix";
    }
}

export function getPageByQuestionId(pages: Page[], questionId: string) : Page | undefined {
    return pages.find(p => p.questions.some(q => q.id === questionId));
}