import { Space, Progress } from "antd";
import React from "react";
import { FC } from "react";
import { SurveTokenStateEnum, SurveyToken } from "../../../../models/SurveyTokens";

export const TokenAnswersProgress: FC<TokenAnswersProgressProps> = ({ tokens }) => {
    const tokenCount = tokens.length;
    const completedTokenCount = tokens.filter(t => t.surveyTokenState && t.surveyTokenState.state === SurveTokenStateEnum.Completed).length;

    return (
        <Space style={{ marginBottom: '1rem' }}>
            <Progress style={{ width: '200px' }} percent={completedTokenCount * 100 / tokenCount} size="small" showInfo={false} strokeColor="#82ca9d" />
            <div>
                <span>{completedTokenCount}</span>
                <span style={{ fontSize: '1rem', fontWeight: 500 }}>/{tokenCount} Antworten</span>
            </div>
        </Space>
    );
}

interface TokenAnswersProgressProps {
    tokens: SurveyToken[];
}