import React, { FC, useEffect, useState } from "react";
import { Button, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PermissionClaim } from "../../../../../models/Claim";
import { PermissionClaimSelection } from "./PermissionClaimSelection";
import { useData } from "../../../../../shared/hooks/useData";
import { userService } from "../../../../../shared/services/userService";
import { OptionsType } from "./NewPermissionClaim";
import { IUserInfo } from "../../../../../models/IUserInfo";

export const NewUsersPermissionClaim: FC<NewUsersPermissionClaimProps> = ({ claimoptions, onAdd }) => {
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedClaim, setSelectedClaim] = useState(claimoptions[0]);
    const { data: users, loading: usersLoading } = useData("", () => userService.getUsers());
    const [useroptions, setUserOptions] = useState<OptionsType[]>([]);

    useEffect(() => {
        var options = users?.map((u) => ({
            key: u.id,
            value: u.id,
            label: u.displayName
        }));

        setUserOptions(options || []);

    }, [users]);

    const addPermissionEntities = () => {
        const selectedUsers = users?.filter(u => selectedKeys.includes(u.id)) || [];
        onAdd(selectedUsers, selectedClaim);
        setSelectedKeys([]);
    };

    return (
        <Space>
            <Select style={{ width: '30em' }}
                mode="multiple"
                allowClear
                showSearch
                placeholder={"Benutzer"}
                value={selectedKeys}
                filterOption={(input, option) => ((option?.label as any)?.key.toString() ?? "").toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                onChange={setSelectedKeys}
                options={useroptions}
                loading={usersLoading}
            />
            <PermissionClaimSelection 
                selectedClaim={selectedClaim}
                claimOptions={claimoptions}
                onChange={setSelectedClaim}
            />
            <Button
                icon={<PlusOutlined />}
                onClick={addPermissionEntities} />
        </Space>);
};

interface NewUsersPermissionClaimProps {
    claimoptions: PermissionClaim[];
    onAdd: (selectedUsers: IUserInfo[], claim: PermissionClaim) => void;
}
