import React from "react";
import { Avatar, Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { SurveyListItem } from "../../../models/SurveyVm";
import { CalendarOutlined, DeleteOutlined, EditOutlined, EyeOutlined, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";
import styles from "./SurveyList.module.less";
import HtcSpin from "../../../shared/components/HtcSpin/HtcSpin";
import { userService } from "../../../shared/services/userService";

export const SurveyList: FC<SurveyListProps> = ({ surveys, loading, onOpenDetails, onOpenPermissions, onDelete }) => {
    const userClaims = userService.getUserClaimsObject();
    
    const getColumns = (): ColumnsType<SurveyListItem> => {
        return [
            {
                title: "Titel",
                key: "title",
                render: (record: SurveyListItem) => <Space><Avatar icon={<CalendarOutlined />} className={styles.listicon} /> {record.title}</Space>,
                sorter: (a: SurveyListItem, b: SurveyListItem) => a.title?.localeCompare(b.title)
            },
            {
                title: "Gültig von",
                key: "validFrom",
                render: (record: SurveyListItem) => (moment(record.validFrom).format("DD.MM.YYYY")),
                sorter: (a: SurveyListItem, b: SurveyListItem) => moment(a.validFrom).unix() - moment(b.validFrom).unix()
            },
            {
                title: "Gültig bis",
                key: "validTo",
                render: (record: SurveyListItem) => (moment(record.validTo).format("DD.MM.YYYY")),
                sorter: (a: SurveyListItem, b: SurveyListItem) => moment(a.validTo).unix() - moment(b.validTo).unix()
            },
            {
                title: "Erstellt am",
                key: "created",
                defaultSortOrder: 'descend',
                render: (record: SurveyListItem) => (moment(record.created).format("DD.MM.YYYY")),
                sorter: (a: SurveyListItem, b: SurveyListItem) => moment(a.created).unix() - moment(b.created).unix()
            },
            {
                title: "Beschreibung",
                dataIndex: "description",
                key: "description",
                sorter: (a: SurveyListItem, b: SurveyListItem) => a.description?.localeCompare(b.description)
            },
            {
                key: 'action',
                align: "right",
                render: (record: SurveyListItem) => renderActions(record)
            }
        ]
    }

    const renderActions = (survey: SurveyListItem) => {
        const canEditSurvey = userClaims.hasPermissionClaim("CanEditSurveys", survey.id) || userClaims.isAdministrator();
        return (
            <Space>
                <Button
                    disabled={!canEditSurvey}
                    icon={<UnlockOutlined />}
                    type="text"
                    title="Benutzer berechtigen"
                    onClick={() => onOpenPermissions(survey)}
                />
                {
                    canEditSurvey ?
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            title="Umfrage verwalten"
                            onClick={() => onOpenDetails(survey)}
                        />
                        :
                        <Button
                            type="text"
                            icon={<EyeOutlined />}
                            title="Umfrage anzeigen"
                            onClick={() => onOpenDetails(survey)}
                        />
                }
                <Button
                    disabled={!canEditSurvey}
                    type="text" icon={<DeleteOutlined />}
                    title="Umfrage löschen"
                    onClick={() => onDelete(survey)}
                />
            </Space>
        )
    }

    return (
        <Table columns={getColumns()}
            dataSource={surveys}
            rowKey="id"
            loading={{ indicator: <HtcSpin />, spinning: loading }}
        />

    );
};

interface SurveyListProps {
    surveys: SurveyListItem[];
    loading: boolean;
    onOpenDetails: (survey: SurveyListItem) => void;
    onOpenPermissions: (survey: SurveyListItem) => void;
    onDelete: (survey: SurveyListItem) => void;
}

