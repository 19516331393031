import React, { FC, useEffect, useState } from "react";
import { useData } from "../../../../shared/hooks/useData";
import { userService } from "../../../../shared/services/userService";
import { NewUsersPermissionClaim } from "./components/NewUsersPermissionClaim";
import { PermissionClaim, UserClaims, mapToClaim } from "../../../../models/Claim";
import { IUserClaimListItem, UsersPermissionClaimList } from "./components/UsersPermissionClaimList";
import HtcSpin from "../../../../shared/components/HtcSpin/HtcSpin";
import { Button, Modal, Space } from "antd";
import { IUserInfo } from "../../../../models/IUserInfo";

export const UsersPermissionClaims: FC<UsersPermissionClaimsProps> = ({ title, claimoptions, onClose }) => {
    const { data: users, loading: usersLoading } = useData("", () => userService.getUsersByClaim(claimoptions.map(c => mapToClaim(c))));
    const [userclaims, setUserclaims] = useState<UserClaims[]>([]);
    const [usersClaimListItems, setUserClaimListItems] = useState<IUserClaimListItem[]>([]);

    useEffect(() => {
        var tmpUserclaims = users?.map(u => new UserClaims(u));
        setUserclaims(tmpUserclaims || []);
    }, [users]);
    useEffect(() => setUserClaimListItems(mapToUserClaimListItem()), [userclaims, claimoptions]);

    const onSave = async () => {
        const users: IUserInfo[] = userclaims.map(u => u.getUserInfo());
        const response = await userService.updateUsers(users);
        onClose();
    };

    const deletePermissionClaim = (userid: string, claim: PermissionClaim) => {
        var userclaim = userclaims.find(u => u.getUserId() === userid);
        if (userclaim) {
            userclaim.removePermissionClaim(claim);
            setUserclaims([...userclaims.filter(u => u.getUserId() !== userid), userclaim]);
        }
    };

    const addPermissionClaims = (users: IUserInfo[], claim: PermissionClaim) => {
        const tmpUserclaims: UserClaims[] = [];
        users.forEach(user => {
            var userclaim = userclaims.find(u => u.getUserId() === user.id) || new UserClaims(user);
            userclaim.addPermissionClaim(claim);
            tmpUserclaims.push(userclaim);
        });

        setUserclaims([...userclaims.filter(u => !users.some(user => user.id === u.getUserId())), ...tmpUserclaims]);
    };

    const mapToUserClaimListItem = (): IUserClaimListItem[] => {
        var userclaimList: IUserClaimListItem[] = [];
        userclaims.forEach(userclaim => {
            claimoptions.forEach(claim => {
                if (userclaim.hasPermissionClaim(claim.value, claim.id)) {
                    userclaimList.push({
                        userid: userclaim.getUserId(),
                        userdisplayname: userclaim.getUserDisplayName(),
                        claim: claim
                    });
                }
            })
        });

        return userclaimList;
    };

    return (
        <Modal width={'60%'}
            visible={true}
            title={
                <Space>
                    {title}
                </Space>
            }
            onCancel={onClose}
            footer={
                <Space >
                    <Button type="primary" onClick={onClose} danger>Abbrechen</Button>
                    <Button type="primary" onClick={() => onSave()}>Speichern</Button>
                </Space>
            }>
            {usersLoading ?
                <HtcSpin /> : <>
                    <NewUsersPermissionClaim
                        claimoptions={claimoptions}
                        onAdd={addPermissionClaims}
                    />
                    <UsersPermissionClaimList
                        userclaims={usersClaimListItems}
                        onDelete={deletePermissionClaim}
                    />
                </>
            }
        </Modal>
    );
}

interface UsersPermissionClaimsProps {
    title: string;
    claimoptions: PermissionClaim[];
    onClose: () => void;
}