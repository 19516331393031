import React, { CSSProperties, FC } from "react";

const OpinioLogo: FC<OpinioLogoProps> = ({ darkMode, style }) => {
    if (darkMode) {
        return (
            <img style={style} src={process.env.PUBLIC_URL + "/assets/rios-opinio_logo-invers.svg"} />
        );
    } else {
        return (
            <img style={style} src={process.env.PUBLIC_URL + "/assets/rios-opinio_logo.svg"} />
        );
    }
}

export default OpinioLogo;

interface OpinioLogoProps {
    darkMode: boolean;
    style?: CSSProperties;
}