import React from "react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom";
import { settingsService } from "./shared/services/settingsService";
import deDE from 'antd/es/locale/de_DE';
import App from "./App";
import moment from 'moment';
import "moment/locale/de";

moment.locale("de");

export const initialize = async () => {
    await settingsService.init();
    let rootComponent;

    rootComponent = (
        <ConfigProvider locale={deDE}>
            <App />
        </ConfigProvider>
    );

    ReactDOM.render(rootComponent, document.getElementById('root'));
};