import React, { FC, useEffect, useState } from "react";
import { HtcCard } from "../../shared/components/HtcCard/HtcCard";
import { Layout, Space } from "antd";
import styles from "./Settings.module.css";
import HtcPageHeader from "../../shared/components/HtcPageHeader/HtcPageHeader";
import { ExchangeSettingsComponent } from "./ExchangeSettings/ExchangeSettings";
import { ExchangeSettings } from "../../models/Settings";
import { useAsync } from "react-use";
import configurationService from "../../shared/services/configurationService";
import HtcSpin from "../../shared/components/HtcSpin/HtcSpin";
import { SettingOutlined } from "@ant-design/icons";
const { Content } = Layout;

export const Settings: FC = () => {
    const { value, loading } = useAsync(() => configurationService.getExchangeSettings());
    const [exchangeSettings, setExchangeSettings] = useState<ExchangeSettings>(value || {} as ExchangeSettings);
    useEffect(() => setExchangeSettings(value || {} as ExchangeSettings), [value])

    const saveExchangeSettings = async (exchangeSettings: ExchangeSettings) => {
        await configurationService.updateExchangeSettings(exchangeSettings);
        setExchangeSettings(exchangeSettings);
    };

    if (loading) {
        return <HtcSpin />
    }

    return (
        <Layout className={styles.layout}>
            <HtcPageHeader title={<Space>Anwendungseinstellungen</Space>} >
            </HtcPageHeader>
            <Content className={styles.content}>
                <HtcCard>
                    <ExchangeSettingsComponent settings={exchangeSettings} onSave={saveExchangeSettings} />
                </HtcCard>
            </Content>
        </Layout>
    );
}