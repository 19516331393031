import React, { FC } from "react";
import { Avatar, Button, Space, Table } from "antd";
import { IUserInfo } from "../../../../models/IUserInfo";
import { UserOutlined, EditOutlined, UserDeleteOutlined, UnlockOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import HtcSpin from "../../../../shared/components/HtcSpin/HtcSpin";
import styles from "./UserList.module.less";

export const UserList: FC<UserListProps> = ({ users, loading, onOpenPermissions, onEdit, onDelete }) => {
    const columns: ColumnsType<IUserInfo> = [
        {
            title: 'Benutzername',
            dataIndex: 'userName',
            key: 'userName',
            defaultSortOrder: 'ascend',
            sorter: (a: IUserInfo, b: IUserInfo) => a.userName?.localeCompare(b.userName),
            render: (userName) =>
                <Space>
                    <Avatar icon={<UserOutlined />} className={styles.listicon} /> {userName}
                </Space>
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
            sorter: (a: IUserInfo, b: IUserInfo) => a.displayName?.localeCompare(b.displayName),
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            sorter: (a: IUserInfo, b: IUserInfo) => a.email?.localeCompare(b.email),
        },
        {
            key: "actions",
            align: "right",
            render: (user: IUserInfo) => (
                <Space>
                    <Button icon={<UnlockOutlined />} type="text" title="Benutzer berechtigen" onClick={() => onOpenPermissions(user)} ></Button>
                    <Button title="Benutzer bearbeiten" type="text" onClick={() => onEdit(user)} icon={<EditOutlined />}></Button>
                    <Button disabled={user.userName === "admin"} title="Benutzer löschen" type="text" onClick={() => onDelete(user)} icon={<UserDeleteOutlined />}></Button>
                </Space>
            )
        }
    ];

    return (
        <Table
            loading={{ spinning: loading, indicator: <HtcSpin /> }}
            columns={columns}
            dataSource={users}
            rowKey="id"
        />
    );
}

interface UserListProps {
    users: IUserInfo[];
    loading: boolean;
    onOpenPermissions: (user: IUserInfo) => void;
    onEdit: (user: IUserInfo) => void;
    onDelete: (user: IUserInfo) => void;
    onClose: () => void;
}