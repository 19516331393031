import React, { FC } from "react";
import HtcPageHeader from "../../../shared/components/HtcPageHeader/HtcPageHeader";
import { Space, Button, notification } from "antd";
import CommandBar from "../../../shared/components/CommandBar/CommandBar";
import { EyeOutlined, MailOutlined, CopyOutlined } from "@ant-design/icons";
import { SurveyVm } from "../../../models/SurveyVm";
import { settingsService } from "../../../shared/services/settingsService";

export const SurveyHeader: FC<SurveyHeaderProps> = ({ survey, hasEditAccess, onPreviewClick, onSendSurveyClick }) => {
    const getSurveyLink = () => {
        const config = settingsService.getConfig();
        let url = `${config.identity.redirectBaseUrl}/surveys`;

        if (survey.isAnonymous) {
            url = `${config.identity.redirectBaseUrl}/surveys/autoprovisioning/${survey.id}`;
        }

        return url;
    };

    const copySurveyUrl = () => {
        navigator.clipboard.writeText(getSurveyLink());
        notification.success({ message: "Der Umfragelink wurde in die Zwischenablage kopiert." })
    };

    const surveyLink = getSurveyLink();
    return (
        <HtcPageHeader
            title={`${survey.title} verwalten`}
            subTitle={
                <Space>
                    <span>{surveyLink}</span>
                    <Button icon={<CopyOutlined />} size="small" onClick={copySurveyUrl}></Button>
                </Space>
            }
        >
            <CommandBar>
                <Button icon={<EyeOutlined />} onClick={onPreviewClick}>Vorschau</Button>
                {hasEditAccess && <Button type="primary" icon={<MailOutlined />} onClick={onSendSurveyClick}>Umfrage senden</Button>}
            </CommandBar>
        </HtcPageHeader>
    );
}


interface SurveyHeaderProps {
    survey: SurveyVm;
    hasEditAccess: boolean;
    onPreviewClick: () => void;
    onSendSurveyClick: () => void;
}