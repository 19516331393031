import { settingsService } from "./settingsService";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";

class TokenService {
    private accessToken: string = "";
    private surveyToken: string = "";

    public loginWithToken = async (surveyToken: string): Promise<boolean> => {
        this.surveyToken = surveyToken;
        const client = this.createInstance();
        const params = new URLSearchParams();
        params.append("client_id", "SurveyTokenClient");
        params.append("grant_type", "surveytoken");
        params.append("scope", "api.surveytoken");
        params.append("surveytoken", surveyToken);

        try {
            const response = await client.post("connect/token", params);
            this.accessToken = response.data.access_token;
        }
        catch (e) {
            console.log(e);
            return false;
        }

        return true;
    }

    public invalidateToken = async () => {
        this.accessToken = "";
    }

    public getAccessToken() {
        return this.accessToken;
    }
    public getSurveyToken() {
        return this.surveyToken;
    }

    private createInstance = (): AxiosInstance => {
        const appConfig = settingsService.getConfig();

        const config: AxiosRequestConfig = {
            headers: {
                'Cache-Control': 'no-cache'
            },
            baseURL: `${appConfig.apiUrl}/`,
            withCredentials: true
        };

        const instance = axios.create(config);
        return instance;
    }
}

export const tokenService = new TokenService();
