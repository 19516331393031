import { settingsService } from "./settingsService";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { tokenService } from "./tokenService";

const createInstance = (): AxiosInstance => {
    const appConfig = settingsService.getConfig();
    const accessToken = tokenService.getAccessToken();

    const config: AxiosRequestConfig = {
        headers: {
            Authorization: "Bearer " + accessToken,
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        },
        baseURL: `${appConfig.apiUrl}/api`,
        withCredentials: true
    };

    const instance = axios.create(config);
    return instance;
}

const getInstance = (): AxiosInstance => {
    return createInstance();
};

enum httpMethod {
    get,
    post,
    delete
}

const internalCall = async (url: string, retryByUnauthenticated: boolean, method: httpMethod, data?: any): Promise<any> => {
    try {
        if (method === httpMethod.get) {
            const response = await getInstance().get(url);
            return response;
        }
        else if (method === httpMethod.post) {
            const response = await getInstance().post(url, data);
            return response;
        }
        else if (method === httpMethod.delete) {
            const response = await getInstance().delete(url);
            return response;
        }
        else {
            throw "Unsupported HTTP-Method";
        }
    }
    catch (e: any) {
        console.log(e.response);

        if (retryByUnauthenticated && e?.response?.status === 401) {
            const accessToken = tokenService.getAccessToken();
            await tokenService.loginWithToken(tokenService.getSurveyToken());

            if (accessToken != tokenService.getAccessToken()) {
                return await internalCall(url, false, method, data);
            }
        }

        return e?.response;
    }
}

export default { getInstance }