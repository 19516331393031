import { settingsService } from "./settingsService";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { userService } from "./userService";

const createInstance = async (): Promise<AxiosInstance> => {
    const appConfig = settingsService.getConfig();

    if (!userService.isInitialized()) {
        await userService.init();
    }
    
    if (await userService.isExpired()) {
        await userService.signinSilent();
    }

    const user = userService.getCurrentUser();

    const config: AxiosRequestConfig = {
        headers: {
            Authorization: "Bearer " + user.access_token,
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        },
        baseURL: `${appConfig.apiUrl}/api`,
        withCredentials: true
    };

    const instance = axios.create(config);
    return instance;
}

const getInstance = async (): Promise<AxiosInstance> => {
    return createInstance();
};

export default { getInstance }