import React, { FC, ReactNode } from "react";
import styles from "./HtcPageHeader.module.less";

const HtcPageHeader: FC<HtcPageHeaderProps> = ({ title, subTitle, children }) => {
    return (
        <div className={styles.headerwrapper}>
            <div>
                <div className={styles.headertitle}>
                    {title}
                </div>
                <div className={styles.subtitle}>{subTitle}</div>
            </div>
            {children}
        </div>
    );
}

export default HtcPageHeader;

interface HtcPageHeaderProps {
    title?: ReactNode;
    subTitle?: ReactNode;
}