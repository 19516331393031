import React, { FC } from "react";
import { SurveyToken } from "../../../models/SurveyTokens";
import { TokensList } from "./components/TokensList";
import { SurveyVm } from "../../../models/SurveyVm";
import surveyService from "../../../shared/services/surveyService";
import { TokenAnswersProgress } from "./components/TokenAnswersProgress";
import { TokensCommandBar } from "./components/TokensCommandBar";

export const SurveyTokensOverview: FC<SurveyTokensOverviewProps> = ({ survey, tokens, hasEditAccess, onCreateTokens, onCreateReceivers, onDeleteToken, onDeleteTokens }) => {
    const downloadTokens = () => surveyService.downloadSurveyTokensExcelFile(survey.id);

    return (<>
        <TokensCommandBar
            isAnonymous={survey.isAnonymous}
            hasEditAccess={hasEditAccess}
            onCreateTokens={onCreateTokens}
            onCreateReceivers={onCreateReceivers}
            onDeleteTokens={onDeleteTokens}
            onDownloadTokens={downloadTokens}
        />
        <TokenAnswersProgress
            tokens={tokens}
        />
        <TokensList
            tokens={tokens}
            metadataColumns={survey.metadataColumns}
            isAnonymous={survey.isAnonymous}
            hasEditAccess={hasEditAccess}
            onDeleteToken={onDeleteToken}
        />
    </>)
}

interface SurveyTokensOverviewProps {
    survey: SurveyVm;
    tokens: SurveyToken[];
    hasEditAccess: boolean;
    onCreateTokens: () => void;
    onCreateReceivers: () => void;
    onDeleteToken: (tokenId: string) => void;
    onDeleteTokens: () => void;
}