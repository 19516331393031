import React, { FC } from "react";
import { Layout, Space } from "antd";
import styles from "./AppHeader.module.less";
import { InfoCircleOutlined } from '@ant-design/icons';
const { Header } = Layout;
import OpinioLogo from "./shared/components/OpinioLogo/OpinioLogo";

const AppHeader: FC = ({ children }) => {
    return (
        <Header className={styles.header}>
            <div style={{ fontSize: "1.5rem", display: "flex", alignItems: "center" }}>
                <OpinioLogo darkMode style={{width: "150px"}} />
            </div>
            <Space>
                {children}
            </Space>
        </Header>
    );
}

const VersionInfo = () => {
    return (
        <Space>
            <InfoCircleOutlined />
            {process.env.REACT_APP_VERSION}
            <span></span>
        </Space>
    );
}

export default AppHeader;

