import { IUserInfo } from "./IUserInfo";

export interface IClaim {
    type: string;
    value: string;
}

export type ClaimTypes = RoleClaim | PermissionClaim;

export interface RoleClaim {
    type: "Role";
    value: string;
}

export interface AdministratorClaim extends RoleClaim {
    value: "Administrator";
}

export interface UserClaim extends RoleClaim {
    value: "User";
}

export interface PermissionClaim {
    type: "Permission";
    value: string;
    id?: string;
    displaytext: string;
}

export interface CanAddTemplatesClaim extends PermissionClaim {
    value: "CanAddTemplates";
    displaytext: "Kann Vorlage anlegen";
}

export interface CanEditTemplatesClaim extends PermissionClaim {
    value: "CanEditTemplates";
    displaytext: "Schreiben";
}

export interface CanReadTemplatesClaim extends PermissionClaim {
    value: "CanReadTemplates";
    displaytext: "Lesen";
}

export interface CanEditSurveysClaim extends PermissionClaim {
    value: "CanEditSurveys";
    displaytext: "Schreiben";
}

export interface CanReadSurveysClaim extends PermissionClaim {
    value: "CanReadSurveys";
    displaytext: "Lesen";
}

export interface CanReadStatisticClaim extends PermissionClaim {
    value: "CanReadStatistic";
    displaytext: "Kann Statistik lesen"
}

export class UserClaims {
    private userinfo: IUserInfo;
    private claims: ClaimTypes[] = [];

    public constructor(user: IUserInfo) {
        this.userinfo = user;
        this.initClaims(user.claims);
    };

    private initClaims = (rawClaims: IClaim[]) => {
        rawClaims?.forEach(claim => {
            const claimType = mapToClaimType(claim);
            if (claimType) {
                this.claims.push(claimType);
            }
        });
    };

    public getUserId = () => this.userinfo.id;
    public getUserDisplayName = () => this.userinfo.displayName;
    public getUserInfo = () => {
        this.userinfo.claims = this.getRawClaims();
        return this.userinfo;
    };
    public getClaims = () => this.claims;
    public getRawClaims = () => this.claims.map(mapToClaim);
    public getPermissionClaims = (values?: string[]): PermissionClaim[] => this.claims.filter(c => c.type === "Permission" && (values === undefined || values.includes(c.value))) as PermissionClaim[];

    public isAdministrator = () => this.hasRoleClaim("Administrator");
    public hasRoleClaim = (role: string): boolean => this.claims.some(c => c.type === "Role" && c.value === role);
    public hasPermissionClaim = (permission: string, id?: string): boolean => this.claims.some(c => c.type === "Permission"
        && c.value === permission
        && (c.id === undefined || id === undefined || c.id === id));

    public removeRoleClaim = (claim: RoleClaim) => {
        this.claims = this.claims.filter(c => !this.isSameRoleClaim(c, claim));
    };

    private isSameRoleClaim = (claim: ClaimTypes, claimToCompare: RoleClaim) => {
        if (claim.type === "Permission") return false;
        var roleClaim = claim as RoleClaim;
        return roleClaim.type === claimToCompare.type && roleClaim.value === claimToCompare.value;
    };

    public removePermissionClaim = (claim: PermissionClaim) => {
        this.claims = this.claims.filter(c => !this.isSamePermissionClaim(c, claim));
    };

    private isSamePermissionClaim = (claim: ClaimTypes, claimToCompare: PermissionClaim) => {
        if (claim.type === "Role") return false;
        var permissionClaim = claim as PermissionClaim;
        return permissionClaim.type === claimToCompare.type && permissionClaim.value === claimToCompare.value && permissionClaim.id == claimToCompare.id;
    };

    public addRoleClaim = (claim: RoleClaim) => {
        this.removeRoleClaim(claim);
        this.claims.push(claim);
    };

    public addPermissionClaim = (claim: PermissionClaim) => {
        this.removePermissionClaim(claim);
        this.claims.push(claim);
    };
}

export const mapToClaim = (claim: ClaimTypes): IClaim => {
    switch (claim.type) {
        case "Role": return claim;
        case "Permission":
            return {
                type: claim.type,
                value: `${claim.value}${claim.id ? `.${claim.id}` : ''}`
            };
    }
};

export const mapToClaimType = (rawClaim: IClaim): ClaimTypes | undefined => {
    const splitedValue = rawClaim.value.split(".");
    const value = splitedValue[0];
    const id = splitedValue.length > 1 && splitedValue[1];
    switch (value) {
        case "Administrator": return { type: "Role", value } as AdministratorClaim;
        case "User": return { type: "Role", value } as UserClaim;
        case "CanAddTemplates": return { type: "Permission", value, id, displaytext: "Kann Vorlage anlegen" } as CanAddTemplatesClaim;
        case "CanEditTemplates": return { type: "Permission", value, id, displaytext: "Schreiben" } as CanEditTemplatesClaim;
        case "CanReadTemplates": return { type: "Permission", value, id, displaytext: "Lesen" } as CanReadTemplatesClaim;
        case "CanEditSurveys": return { type: "Permission", value, id, displaytext: "Schreiben" } as CanEditSurveysClaim;
        case "CanReadSurveys": return { type: "Permission", value, id, displaytext: "Lesen" } as CanReadSurveysClaim;
        case "CanReadStatistic": return { type: "Permission", value, id, displaytext: "Kann Statistik lesen" } as CanReadStatisticClaim;
    };
};

export const AdministratorRole = { type: "Role", value: "Administrator" } as AdministratorClaim;

export const CanAddTemplatePermission = { type: "Permission", value: "CanAddTemplates" } as CanAddTemplatesClaim

export const TemplateClaimTypes: PermissionClaim[] = [
    { type: "Permission", value: "CanEditTemplates", displaytext: "Schreiben" } as CanEditTemplatesClaim,
    { type: "Permission", value: "CanReadTemplates", displaytext: "Lesen" } as CanReadTemplatesClaim
];

export const SurveyClaimTypes: PermissionClaim[] = [
    { type: "Permission", value: "CanEditSurveys", displaytext: "Schreiben" } as CanEditSurveysClaim,
    { type: "Permission", value: "CanReadSurveys", displaytext: "Lesen" } as CanReadSurveysClaim
];