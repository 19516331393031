export interface SurveyToken {
    id: string;
    surveyId: string;
    token: string;
    validFrom: Date;
    validTo: Date;
    state: string;
    surveyTokenState: SurveyTokenState;
    metadata: {};
}
export interface SurveyTokenState
{
    state:SurveTokenStateEnum;
    info:string;
}
export enum SurveTokenStateEnum
{
    NotValid = "NotValid",
    InProgress = "InProgress",
    Completed = "Completed",
    Valid = "Valid"
}
export interface SurveyReceiver {
    metadata: {};
}