import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { PageHeader, TreeSelect, Divider, Collapse, Input, Select } from "antd";
import { SurveyTemplate, QuestionTypes, MailSettings } from "../../../../models/SurveyTemplate";
import { findEditorItem } from "../EditorHelpers";
import { EditorItem } from "../../../../models/Editor";
import { MappingDefinition } from "../../../../models/MappingDefinition";
import MappingProperties from "./MappingProperties/MappingProperties";
import styles from "./PropertiesPane.module.css";
import GeneralProperties from "./GeneralProperties/GeneralProperties";
import MailProperties from "./MailProperties/MailProperties";
import DependencyProperties from "./DependencyProperties/DependencyProperties";
import TextArea from "antd/lib/input/TextArea";
import { ValidationProperty } from "../../../../models/SurveyTemplateValidator";
import { settingsService } from "../../../../shared/services/settingsService";
import ReactQuill from "react-quill";
import { ValidationProperties } from "./ValidationProperties/ValidationProperties";

const PropertiesPane: FC<PropertiesPaneProps> = ({
    currentItem,
    mappingDefinitions,
    surveyTemplate,
    focusId,
    focusType,
    focusChange,
    titleRef,
    descriptionRef,
    mailSenderRef,
    subjectRef,
    bodyRef,
    mappingRef,
    mappingValueRef,
    onItemSelect,
    onUpdateItem,
    onUpdateMailSettings,
    onUpdateReminderMailSettings,
    onUpdateQuestion
}) => {
    const appConfig = settingsService.getConfig();
    const { title } = currentItem.item;
    const [activePanels, setActivePanels] = useState<string[]>(["general"]);

    useEffect(() => {
        switch (focusType) {
            case ValidationProperty.Title: addActivePanel("general"); break;
            case ValidationProperty.Description: addActivePanel("general"); break;
            case ValidationProperty.MailSender: addActivePanel("general"); break;
            case ValidationProperty.Subject: addActivePanel("mailSettings"); break;
            case ValidationProperty.Body: addActivePanel("mailSettings"); break;
            case ValidationProperty.MappingDefinition: addActivePanel("mapping"); break;
            case ValidationProperty.MappingFields: addActivePanel("mapping"); break;
            case ValidationProperty.MappingValue: addActivePanel("mapping"); break;
            default: addActivePanel("general"); break;
        }
    }, [focusChange]);
    useEffect(() => {
        if (surveyTemplate.useAutoMailSend) {
            addActivePanel("mailSettings");
        }
    }, [surveyTemplate.useAutoMailSend]);

    const treeData = createSurveyTreeData(surveyTemplate);

    const addActivePanel = (panel: string) => {
        setActivePanels(activePanels.find(p => p === panel) ? activePanels : [...activePanels, panel]);
    }

    const onSelect = (id: string, option: any) => {
        const selectedItem = findEditorItem(id, option.type, surveyTemplate);
        onItemSelect(selectedItem as EditorItem);
    };

    return (
        <PageHeader className={styles.properties} title="Eigenschaften">
            <TreeSelect
                treeDefaultExpandAll
                className={styles.propertiesselect}
                bordered={false}
                treeData={treeData}
                value={title}
                onSelect={onSelect}
            />
            <Divider />
            <Collapse activeKey={activePanels} onChange={(key) => setActivePanels(key as string[])}>
                <Collapse.Panel key="general" header="Allgemein">
                    <GeneralProperties
                        editorItem={currentItem}
                        onUpdateItem={onUpdateItem}
                        titleRef={titleRef}
                        descriptionRef={descriptionRef}
                        mailSenderRef={mailSenderRef}
                    />
                </Collapse.Panel>
                {currentItem.type === "survey" &&
                    <Collapse.Panel key="mailSettings" header="Mailversand">
                        <MailProperties
                            mailSettings={currentItem.item.mailSettings || {} as MailSettings}
                            onUpdateMailSettings={onUpdateMailSettings}
                            subjectRef={subjectRef}
                            bodyRef={bodyRef}
                            isAnonymous={surveyTemplate.isAnonymous}
                        />
                    </Collapse.Panel>
                }
                {currentItem.type === "survey" && currentItem.item.useAutoMailSend && !currentItem.item.isAnonymous && appConfig.enableRiosConnection &&
                    <Collapse.Panel key="reminderMailSettings" header="Nachfassmail (optional)">
                        <MailProperties
                            mailSettings={currentItem.item.reminderMailSettings || {} as MailSettings}
                            onUpdateMailSettings={onUpdateReminderMailSettings}
                            isAnonymous={surveyTemplate.isAnonymous}
                        />
                    </Collapse.Panel>
                }
                {currentItem.type === "question" &&
                    <Collapse.Panel key="dependencies" header="Abhängigkeiten">
                        <DependencyProperties
                            question={currentItem.item as QuestionTypes}
                            pages={surveyTemplate.pages}
                            onUpdateQuestion={onUpdateQuestion}
                        />
                    </Collapse.Panel>
                }
                {
                    currentItem.type === "question" &&
                    (currentItem.item.type === "Input" ||
                        currentItem.item.type === "Textarea" ||
                        currentItem.item.type === "Number") &&
                    <Collapse.Panel key="validationProperties" header="Validierung">
                        <ValidationProperties
                            question={currentItem.item as QuestionTypes}
                            onUpdateQuestion={onUpdateQuestion}
                        />
                    </Collapse.Panel>
                }
                {(currentItem.type === "survey" || currentItem.type === "question") && appConfig.enableRiosConnection &&
                    <Collapse.Panel key="mapping" header="Externe Feldzuordnung">
                        <MappingProperties
                            item={currentItem}
                            selectedMapping={surveyTemplate.mappingDefinition}
                            mappingDefinitions={mappingDefinitions}
                            surveyTemplate={surveyTemplate}
                            mappingRef={mappingRef}
                            focusId={focusId}
                            mappingValueRef={mappingValueRef}
                            onUpdateItem={onUpdateItem}
                        />
                    </Collapse.Panel>
                }
            </Collapse>
        </PageHeader>
    );
}

export default PropertiesPane;

function createSurveyTreeData(template: SurveyTemplate) {
    return [{
        title: template.title,
        value: template.id,
        type: "survey",
        children: template?.pages?.map((page) => ({
            value: page.id,
            title: page.title,
            pageKey: page.id,
            type: "page",
            children: page?.questions?.map(question => ({
                value: question.id,
                title: question.title,
                pageKey: page.id,
                type: "question",
            }))
        }))
    }];
}

interface PropertiesPaneProps {
    currentItem: EditorItem;
    surveyTemplate: SurveyTemplate;
    mappingDefinitions: MappingDefinition[];
    focusId: string;
    focusType: ValidationProperty | undefined;
    focusChange: boolean;
    titleRef: MutableRefObject<Input | null>;
    descriptionRef: MutableRefObject<TextArea | null>;
    mailSenderRef: MutableRefObject<TextArea | null>;
    subjectRef: MutableRefObject<Input | null>;
    bodyRef: MutableRefObject<ReactQuill | null>;
    mappingRef: MutableRefObject<Select<string> | null>;
    mappingValueRef: MutableRefObject<Select<string> | null>;
    onItemSelect: (item: EditorItem) => void;
    onUpdateItem: (updated: EditorItem) => void;
    onUpdateMailSettings: (mailSettings: MailSettings) => void;
    onUpdateReminderMailSettings: (reminderMailSettings: MailSettings) => void;
    onUpdateQuestion: (question: QuestionTypes) => void;
}







