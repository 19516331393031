import React, { FC, ReactNode } from "react"
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./HtcConfirm.module.css";

const HtcConfirm: FC<IHtcConfirmProps> = ({ visible, title, message, okText, cancelText, children, onOk, onCancel, }) => {
    return (
        <Modal
            visible={visible}
            closable={false}
            footer={null}
        >
            <Space className={styles.header}>
                <ExclamationCircleOutlined className={styles.icon} />
                <Space direction="vertical">
                    {title && <div className={styles.title}>{title}</div>}
                    {message && <div className={styles.message}>{message}</div>}
                </Space>
            </Space>
            {children}
            <div className={styles.footer}>
                <Space>
                    <Button
                        type={"primary"}
                        onClick={onOk}
                    >
                        {okText || "Ok"}
                    </Button>
                    <Button
                        onClick={onCancel}
                    >
                        {cancelText || "Abbrechen"}
                    </Button>
                </Space>
            </div>
        </Modal>
    );
}

interface IHtcConfirmProps {
    visible: boolean;
    title?: string;
    message?: string;
    okText?: string;
    cancelText?: string;
    children?: ReactNode;
    onOk: () => void;
    onCancel: () => void;
}

export default HtcConfirm;