import { Form, Input, Button } from "antd";
import React from "react";
import { FC } from "react";

export const MetadataForm: FC<MetadataFormProps> = ({ metadataColumns, onSave }) => {
    return (
        <Form layout="inline" onFinish={onSave}>
            {metadataColumns.map(column => {
                return (
                    <Form.Item rules={[{
                        required: true,
                        message: `${column} ist ein Pflichtfeld.`,
                        type: column === "Email" ? "email" : "string"
                    }]} name={column} style={{ margin: ".25rem .25rem 1.5rem 0" }} key={column}>
                        <Input placeholder={column} />
                    </Form.Item>
                );
            })}
            <Form.Item style={{ margin: ".25rem .25rem 1.5rem .25rem" }} key="submit">
                <Button type="primary" htmlType="submit">
                    Hinzufügen
                </Button>
            </Form.Item>
        </Form>
    );
};

interface MetadataFormProps {
    metadataColumns: string[];
    onSave: (values: any) => void;
}