import React, { FC, MutableRefObject, useEffect } from 'react';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageFormats } from '@xeger/quill-image-formats';
import { ImageActions } from '@xeger/quill-image-actions';

Quill.register('modules/imageFormats', ImageFormats, true);
Quill.register('modules/imageActions', ImageActions, true);

var Font = Quill.import("formats/font");
Font.whitelist = ["Arial", "Verdana", "Tahoma", "Georgia", "Garamond", "CourierNew"];
Quill.register(Font, true);

const toolbarOptions = [
    [{ font: Font.whitelist }],
    ['bold', 'italic', 'underline'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'size': ['small', 'normal', 'huge'] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [false, 'center', 'right'] }],
    ['link', 'image', 'video'],
    ['clean']
];

const formats = [
    'header', 'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'image', 'video', 'link', 'align', 'float', 'size', "width", 'color', 'background', 'height'
];

const RichText: FC<RichTextProperties> = ({ id, value, placeholder, quillRef, className, onChange, onBlur }) => {
    const modules = {
        toolbar: toolbarOptions,
        clipboard: {
            matchVisual: true
        },
        imageActions: {},
        imageFormats: {}
    };

    return (
        <ReactQuill
            ref={quillRef ? e => quillRef.current = e : undefined}
            id={id}
            theme="snow"
            modules={modules}
            formats={formats}
            value={value}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}

export default RichText;

export interface RichTextProperties extends ReactQuillProps {
    quillRef?: MutableRefObject<ReactQuill | null>;
}
