import React, { FC } from "react";
import { Form, Input, Button, Result } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router";
import { Store } from "antd/lib/form/interface";
import styles from "./TokenLogin.module.css";
import Logo from "../shared/components/HtcLogo/HtcLogo";

const TokenLogin: FC<TokenLoginProps> = ({ authStatus }) => {
    const history = useHistory();
    const [form] = useForm();

    const navigate = async (values: Store) => {
        history.push(`/surveys/${values.token}`);
    }

    return (
        <div>
            <Result
                icon={<Logo />}
                title="Bitte loggen Sie sich mit Ihrem Umfrage-Token ein."
            />
            <Form
                className={styles.tokenform}
                layout="vertical"
                form={form}
                onFinish={navigate}
            >
                <Form.Item
                    className={styles.tokenformitem}
                    label="Token"
                    name="token"
                    rules={[{ required: true }]}
                    validateStatus={authStatus}
                    help={authStatus && "Fehler beim Anmelden. Bitte überprüfen Sie Ihre Login-Daten."}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button size="large" className={styles.button} type="primary" htmlType="submit">Anmelden</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default TokenLogin;

interface TokenLoginProps {
    authStatus?: "error"
}


