import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { Tabs, PageHeader, Input, Card, Space, Button, Switch, Divider, Modal, Select, Form, Alert } from "antd";
import { MinusCircleOutlined, DeleteOutlined, PlusOutlined, DragOutlined } from "@ant-design/icons";
import { QuestionTypes, Page, MatrixQuestion, OptionQuestionTypes, Subquestion } from "../../../../../models/SurveyTemplate";
import styles from "./QuestionEditor.module.less";
import { Option } from "../../../../../models/SurveyVm";
import { v4 } from "uuid";
import { Store } from "antd/lib/form/interface";
import { getPageByQuestionId, getQuestionTypeTitle } from "../../EditorHelpers";
import { SelectValue } from "antd/lib/select";
import _ from "lodash";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { EditorItem } from "../../../../../models/Editor";
import { ValidationProperty } from "../../../../../models/SurveyTemplateValidator";
import RichText from "../../../../../shared/components/RichText/RichText";
import ReactQuill from "react-quill";


const { TabPane } = Tabs;

const QuestionEditor: FC<QuestionProps> = ({
    question,
    optionRef,
    subquestionRef,
    optionsRef,
    subquestionsRef,
    focusId,
    focusType,
    onItemSelect,
    onUpdateQuestion,
    onDeleteQuestion,
    onOpenQuestionPosition
}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    useEffect(() => setTitle(question.title), [question.title]);
    useEffect(() => setDescription(question.description), [question.description]);

    const addOption = () => {
        const { options } = question as OptionQuestionTypes;
        const newOption: Option = {
            value: v4(),
            text: `Antwort ${(options?.length || 0) + 1}`,
            mappingValue: ""
        }
        onUpdateQuestion({ ...question, options: options ? [...options, newOption] : [newOption] } as OptionQuestionTypes);
    };
    const updateOption = (option: Option) => {
        const { options } = question as OptionQuestionTypes;
        onUpdateQuestion({ ...question, options: options.map(o => o.value === option.value ? option : o) } as OptionQuestionTypes);
    };
    const deleteOption = (value: string) => {
        const { options } = question as OptionQuestionTypes;
        onUpdateQuestion({ ...question, options: options ? options.filter(o => o.value !== value) : [] } as OptionQuestionTypes);
    };

    const addSubquestion = () => {
        const { questions } = question as MatrixQuestion;
        const newQuestion: Subquestion = {
            id: v4(),
            description: `Frage ${(questions?.length || 0) + 1}`
        }
        onUpdateQuestion({ ...question, questions: questions ? [...questions, newQuestion] : [newQuestion] } as MatrixQuestion);
    };
    const updateSubquestion = (subquestion: Subquestion) => {
        const { questions } = question as MatrixQuestion;
        onUpdateQuestion({ ...question, questions: questions.map(q => q.id === subquestion.id ? subquestion : q) } as MatrixQuestion);
    };
    const deleteSubquestion = (id: string) => {
        const { questions } = question as MatrixQuestion;
        onUpdateQuestion({ ...question, questions: questions ? questions.filter((o) => o.id !== id) : [] } as MatrixQuestion);
    };

    const onBlurHtmlDescription = (previousSelection: ReactQuill.Range, source: any, editor: ReactQuill.UnprivilegedEditor) => {
        question.htmlDescription = editor.getHTML();
    }

    return (
        <Card
            className={styles.question}
            onFocus={(e) => onItemSelect({ type: "question", item: question })}
            title={
                <PageHeader
                    className={styles.questionpageheader}
                    title={
                        <Input
                            id={`title_${question.id}`}
                            className={styles.titleinput}
                            placeholder="Titel"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={(e) => onUpdateQuestion({ ...question, title: e.target.value })}
                        />
                    }
                    subTitle={getQuestionTypeTitle(question)}
                    extra={[
                        <Space key={`optional_${question.id}`}>
                            <Switch
                                size="small"
                                className={styles.switch}
                                checked={!question.required}
                                onChange={(e) => onUpdateQuestion({ ...question, required: !e })}
                            />
                            <span>Optional</span>
                        </Space>,
                        <Button key={`move_${question.id}`} onClick={() => onOpenQuestionPosition(question)} icon={<DragOutlined />} />,
                        <Button key={`delete_${question.id}`} onClick={() => onDeleteQuestion(question)} icon={<DeleteOutlined />} />
                    ]}
                />
            }
        >
            <Space className={styles.space} direction="vertical">
                <TextArea
                    id={`desc_${question.id}`}
                    placeholder="Beschreibung"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={(e) => onUpdateQuestion({ ...question, description: e.target.value })}
                />
                <RichText key={`desc_${question.id}`}
                    id={`desc_${question.id}`}
                    value={question.htmlDescription}
                    placeholder="Media Elemente"
                    onChange={(value) => question.htmlDescription = value}
                    onBlur={onBlurHtmlDescription}
                />
                {getQuestionComponent(
                    question,
                    optionRef,
                    subquestionRef,
                    optionsRef,
                    subquestionsRef,
                    focusId,
                    focusType,
                    addOption,
                    updateOption,
                    deleteOption,
                    addSubquestion,
                    updateSubquestion,
                    deleteSubquestion
                )}
            </Space>
        </Card >
    );
}

const EditorOptionList: FC<OptionProps> = ({
    questionId,
    options,
    optionRef,
    optionsRef,
    focusId,
    onAddOption,
    onUpdateOption,
    onDeleteOption,
    allowComment
}) => {
    const updateText = (text: string, option: Option) => {
        onUpdateOption({ ...option, text });
    };

    const setOptionRef = (e: Input | null, value: string) => {
        if (focusId === value) {
            optionRef.current = e;
        }
    };
    const setOptionsRef = (e: HTMLElement | null) => {
        if (focusId === questionId) {
            optionsRef.current = e;
        }
    };

    return (
        <div>
            {options && options.map(o => {
                return (
                    <div key={o.value} className={styles.options}>
                        <Input
                            ref={e => setOptionRef(e, o.value)}
                            defaultValue={o.text}
                            placeholder="Neue Antwortmöglichkeit"
                            onBlur={(e) => updateText(e.target.value, o)}
                        />
                        {allowComment &&
                            <Space className={styles.allowComment}>
                                <Switch
                                    size="small"
                                    className={styles.switch}
                                    checked={o.withComment}
                                    onChange={(e) => onUpdateOption({ ...o, withComment: e })}
                                />
                                <span>Textfeld</span>
                                <Divider type="vertical" />
                            </Space>
                        }
                        <MinusCircleOutlined className={styles.icons} onClick={() => onDeleteOption(o.value)} />
                    </div>
                )
            })}
            <Button
                ref={e => setOptionsRef(e)}
                className={styles.buttons}
                type="primary"
                onClick={() => onAddOption()}
                icon={<PlusOutlined />}
            >
                Antwort hinzufügen
            </Button>
        </div>
    );
};

const EditorSubquestionList: FC<SubquestionProps> = ({
    questionId,
    subquestions,
    subquestionRef,
    subquestionsRef,
    focusId,
    onAddSubquestion,
    onUpdateSubquestion,
    onDeleteSubquestion
}) => {
    const updateDescription = (description: string, question: Subquestion) => {
        onUpdateSubquestion({ ...question, description });
    };

    const setSubquestionRef = (e: Input | null, id: string) => {
        if (focusId === id) {
            subquestionRef.current = e;
        }
    };
    const setSubquestionsRef = (e: HTMLElement | null) => {
        if (focusId === questionId) {
            subquestionsRef.current = e;
        }
    };

    return (
        <div>
            {subquestions && subquestions.map(q => {
                return (
                    <div key={q.id} className={styles.subquestions}>
                        <Input
                            ref={e => setSubquestionRef(e, q.id)}
                            placeholder="Neue Frage"
                            defaultValue={q.description}
                            onBlur={(e) => updateDescription(e.target.value, q)}
                        />
                        <MinusCircleOutlined className={styles.icons} onClick={() => onDeleteSubquestion(q.id)} />
                    </div>
                );
            })}
            <Button
                ref={e => setSubquestionsRef(e)}
                className={styles.buttons}
                type="primary"
                onClick={() => onAddSubquestion()}
                icon={<PlusOutlined />}
            >
                Frage hinzufügen
            </Button>
        </div>
    );
};

const EditorMatrixQuestion: FC<MatrixProps> = ({
    questionId,
    options,
    subquestions,
    optionRef,
    subquestionRef,
    optionsRef,
    subquestionsRef,
    focusId,
    focusType,
    onAddOption,
    onUpdateOption,
    onDeleteOption,
    onAddSubquestion,
    onUpdateSubquestion,
    onDeleteSubquestion
}) => {

    const [activeTab, setActiveTab] = useState<string>("fragen");

    useEffect(() => {
        if (subquestions?.find(q => q.id === focusId) || options?.find(o => o.value === focusId)) {
            switch (focusType) {
                case ValidationProperty.Subquestion: setActiveTab("fragen"); break;
                case ValidationProperty.Option: setActiveTab("antworten"); break;
                default: setActiveTab("fragen"); break;
            }
        } else if (questionId === focusId) {
            switch (focusType) {
                case ValidationProperty.Subquestions: setActiveTab("fragen"); break;
                case ValidationProperty.Options: setActiveTab("antworten"); break;
                default: setActiveTab("fragen"); break;
            }
        }
    }, [focusType, focusId]);

    return (
        <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
            <TabPane tab="Fragen" key="fragen">
                <EditorSubquestionList
                    questionId={questionId}
                    subquestions={subquestions}
                    subquestionRef={subquestionRef}
                    subquestionsRef={subquestionsRef}
                    focusId={focusId}
                    onAddSubquestion={onAddSubquestion}
                    onUpdateSubquestion={onUpdateSubquestion}
                    onDeleteSubquestion={onDeleteSubquestion}
                />
            </TabPane>
            <TabPane tab="Antworten" key="antworten">
                <EditorOptionList
                    questionId={questionId}
                    options={options}
                    optionRef={optionRef}
                    optionsRef={optionsRef}
                    focusId={focusId}
                    onAddOption={onAddOption}
                    onUpdateOption={onUpdateOption}
                    onDeleteOption={onDeleteOption}
                />
            </TabPane>
        </Tabs>
    );
};

const QuestionPosition: FC<PositionProps> = ({ question, pages, onMoveQuestion, onCancel }) => {
    const [form] = useForm();
    const [selectedPage, setSelectedPage] = useState<Page | undefined>(undefined);
    useEffect(() => {
        const page = getPageByQuestionId(pages, question.id);
        if (page) {
            form.setFieldsValue({
                pageId: page.id,
                index: page.questions.findIndex(q => q.id === question.id)
            });
            setSelectedPage(page);
        }
    }, [question]);

    const moveQuestion = (values: Store) => onMoveQuestion(values.pageId, values.index);
    const onSelectPage = (value: SelectValue) => setSelectedPage(pages?.find(p => p.id === value));

    return (
        <Modal
            title="Frage neu positionieren"
            visible={true}
            onOk={form.submit}
            onCancel={onCancel}
        >
            <p className={styles.questionpostitle}>{question.title}</p>
            {question.dependency &&
                <Alert className={styles.warning} message="Diese Frage enthält Abhängigkeiten. Beim Verschieben der Frage gehen diese verloren." type="warning" />}
            <Form
                form={form}
                name={`questionposition_${question.id}`}
                onFinish={moveQuestion}
                layout="vertical"
            >
                <Form.Item
                    label="Seite"
                    name="pageId"
                >
                    <Select onChange={onSelectPage}>
                        {pages?.map(page =>
                            <Select.Option key={page.id} value={page.id}>
                                {page.title}
                            </Select.Option>)}
                    </Select>
                </Form.Item>
                {selectedPage && selectedPage.questions?.length > 0 &&
                    <Form.Item
                        label="Position"
                        name="index"
                    >
                        <Select>
                            {selectedPage.questions.map((q, i) =>
                                <Select.Option key={q.id} value={i}>
                                    {`${i + 1} - ${q.title}`}
                                </Select.Option>)}
                        </Select>
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
}

export default { QuestionEditor, QuestionPosition };

function getQuestionComponent(
    question: QuestionTypes,
    optionRef: MutableRefObject<Input | null>,
    subquestionRef: MutableRefObject<Input | null>,
    optionsRef: MutableRefObject<HTMLElement | null>,
    subquestionsRef: MutableRefObject<HTMLElement | null>,
    focusId: string,
    focusType: ValidationProperty | undefined,
    onAddOption: () => void,
    onUpdateOption: (option: Option) => void,
    onDeleteOption: (value: string) => void,
    onAddSubquestion: () => void,
    onUpdateSubquestion: (subquestion: Subquestion) => void,
    onDeleteSubquestion: (id: string) => void
) {
    switch (question.type) {
        case "Matrix": {
            return (
                <EditorMatrixQuestion
                    questionId={question.id}
                    options={question.options}
                    subquestions={question.questions}
                    optionRef={optionRef}
                    subquestionRef={subquestionRef}
                    optionsRef={optionsRef}
                    subquestionsRef={subquestionsRef}
                    focusId={focusId}
                    focusType={focusType}
                    onAddOption={onAddOption}
                    onUpdateOption={onUpdateOption}
                    onDeleteOption={onDeleteOption}
                    onAddSubquestion={onAddSubquestion}
                    onUpdateSubquestion={onUpdateSubquestion}
                    onDeleteSubquestion={onDeleteSubquestion}
                />
            );
        }
        case "SingleSelection": {
            return (
                <EditorOptionList
                    questionId={question.id}
                    options={question.options}
                    optionRef={optionRef}
                    optionsRef={optionsRef}
                    focusId={focusId}
                    onAddOption={onAddOption}
                    onUpdateOption={onUpdateOption}
                    onDeleteOption={onDeleteOption}
                />
            );
        }
        case "MultiSelection": {
            return (
                <EditorOptionList
                    questionId={question.id}
                    options={question.options}
                    optionRef={optionRef}
                    optionsRef={optionsRef}
                    focusId={focusId}
                    onAddOption={onAddOption}
                    onUpdateOption={onUpdateOption}
                    onDeleteOption={onDeleteOption}
                />
            );
        }
        case "RadioGroup": {
            return (
                <EditorOptionList
                    questionId={question.id}
                    options={question.options}
                    optionRef={optionRef}
                    optionsRef={optionsRef}
                    focusId={focusId}
                    onAddOption={onAddOption}
                    onUpdateOption={onUpdateOption}
                    onDeleteOption={onDeleteOption}
                    allowComment={true}
                />
            );
        }
    }
}

interface QuestionProps {
    question: QuestionTypes;
    optionRef: MutableRefObject<Input | null>;
    subquestionRef: MutableRefObject<Input | null>;
    optionsRef: MutableRefObject<HTMLElement | null>;
    subquestionsRef: MutableRefObject<HTMLElement | null>;
    focusId: string;
    focusType: ValidationProperty | undefined;
    onItemSelect: (item: EditorItem) => void;
    onUpdateQuestion: (question: QuestionTypes) => void;
    onDeleteQuestion: (question: QuestionTypes) => void;
    onOpenQuestionPosition: (question: QuestionTypes) => void;
}

interface OptionProps {
    questionId: string;
    options: Option[];
    optionRef: MutableRefObject<Input | null>;
    optionsRef: MutableRefObject<HTMLElement | null>;
    focusId: string;
    onAddOption: () => void;
    onUpdateOption: (option: Option) => void;
    onDeleteOption: (value: string) => void;
    allowComment?: boolean;
}

interface SubquestionProps {
    questionId: string;
    subquestions: Subquestion[];
    subquestionRef: MutableRefObject<Input | null>;
    subquestionsRef: MutableRefObject<HTMLElement | null>;
    focusId: string;
    onAddSubquestion: () => void;
    onUpdateSubquestion: (subquestion: Subquestion) => void;
    onDeleteSubquestion: (id: string) => void;
}

interface MatrixProps extends OptionProps, SubquestionProps {
    focusType: ValidationProperty | undefined;
}

interface PositionProps {
    question: QuestionTypes;
    pages: Page[];
    onMoveQuestion: (pageId: string, index: number) => void;
    onCancel: () => void;
}