import React, { FC, MutableRefObject } from "react";
import { Select, Space, Switch } from "antd";
import { SurveyTemplate, QuestionTypes, Option as QuestionOption, SingleSelectionQuestion } from "../../../../../models/SurveyTemplate";
import { MappingDefinition, MappingField, MappingFieldsType, SingleSelectionField } from "../../../../../models/MappingDefinition";
import { EditorItem } from "../../../../../models/Editor";
import styles from "./MappingProperties.module.css";
import { settingsService } from "../../../../../shared/services/settingsService";
const { Option } = Select;

const MappingProperties: FC<MappingPropertiesProps> = ({
    surveyTemplate,
    item,
    selectedMapping,
    mappingDefinitions,
    mappingRef,
    focusId,
    mappingValueRef,
    onUpdateItem
}) => {
    const mappingDefinition = mappingDefinitions.find(d => d.name === selectedMapping);

    const mappedFields = () => surveyTemplate.pages
        .flatMap(page => page.questions)
        .map(question => question.mappingField)

    const isMatchingFieldType = (question: QuestionTypes, mappingField: MappingFieldsType) => {
        switch (question.type) {
            case "Input":
            case "Textarea": return mappingField.type === "Input";
            case "SingleSelection":
            case "RadioGroup": return mappingField.type === "SingleSelection";
            default: return question.type === mappingField.type;
        }
    }

    const availableFields = () => mappingDefinition?.fields
        .filter(field => (!mappedFields().includes(field.field) || field.allowMultiMapping || surveyTemplate.allowMultiMapping)
            && isMatchingFieldType(item.item as QuestionTypes, field)) || [];

    const mappingDefinitionChange = (mappingDefinition: string) => {
        const updated = { ...surveyTemplate, mappingDefinition: mappingDefinition };
        onUpdateItem({ item: updated, type: "survey" });
    }

    const allowMultiMappingChange = (allowMultiMapping: boolean) => {
        const updated = { ...surveyTemplate, allowMultiMapping };
        onUpdateItem({ item: updated, type: "survey" });
    }

    const questionMappingChange = (updatedQuestion: QuestionTypes) => {
        onUpdateItem({ item: updatedQuestion, type: "question" });
    }

    return (<>
        {item.type === "survey" &&
            <SurveyMapping
                survey={item.item}
                mappingDefinitions={mappingDefinitions}
                mappingRef={mappingRef}
                onMappingDefinitionChange={mappingDefinitionChange}
                onAllowMultiMappingChange={allowMultiMappingChange}
            />
        }
        {mappingDefinition && item.type === "question" &&
            <QuestionMapping
                question={item.item}
                mappingField={mappingDefinition?.fields.find(f => f.field === item.item.mappingField)}
                focusId={focusId}
                mappingValueRef={mappingValueRef}
                availableFields={availableFields()}
                onQuestionMappingChange={questionMappingChange}
            />
        }
    </>);
};


const SurveyMapping: FC<SurveyMappingProps> = ({ survey, mappingDefinitions, mappingRef, onMappingDefinitionChange, onAllowMultiMappingChange }) => {
    const appConfig = settingsService.getConfig();
    const options = mappingDefinitions.map(def => (
        <Option key={def.name} value={def.name}>{def.name}</Option>
    ));

    return (
        <Space direction="vertical" style={{ width: "100%" }} >
            {
                appConfig.enableMultiMapping &&
                <Space>
                    <Switch
                        className={styles.switch}
                        size="small"
                        checked={survey.allowMultiMapping}
                        onChange={(value) => onAllowMultiMappingChange(value)}
                    />
                    <span>Mehrfachverknüpfungen erlauben</span>
                </Space>
            }
            <Select
                ref={e => mappingRef.current = e}
                className={styles.propertiesselect}
                value={survey.mappingDefinition}
                onChange={(value) => onMappingDefinitionChange(value)}
                allowClear
            >
                {options}
            </Select>
        </Space>
    );
};

const QuestionMapping: FC<QuestionMappingProps> = ({ question, mappingField, availableFields, focusId, mappingValueRef, onQuestionMappingChange }) => {
    const fieldMappingSelectionChange = (question: QuestionTypes, value: string) => onQuestionMappingChange({
        ...question,
        mappingField: value
    });

    const options = availableFields?.map(f => <Option key={f.field} value={f.field}>{f.field}</Option>);
    const withOptions = () => question.type === "SingleSelection" || question.type === "RadioGroup";

    return (
        <div>
            <Select
                className={styles.propertiesselect}
                value={question.mappingField}
                onChange={(value) => fieldMappingSelectionChange(question, value)}
                allowClear
            >
                {options}
            </Select>
            {withOptions() &&
                <QuestionOptionsMapping
                    question={question as SingleSelectionQuestion}
                    mappingField={mappingField as SingleSelectionField}
                    focusId={focusId}
                    mappingValueRef={mappingValueRef}
                    onQuestionMappingChange={onQuestionMappingChange}
                />
            }
        </div>
    );
}

const QuestionOptionsMapping: FC<QuestionOptionsMappingProps> = ({ question, mappingField, focusId, mappingValueRef, onQuestionMappingChange }) => {
    const optionMappingChange = (option: QuestionOption, value: string) => {
        onQuestionMappingChange({
            ...question,
            options: question.options.map(opt => opt.value === option.value ?
                { ...option, mappingValue: value } : opt)
        });
    }

    const setFocusRef = (e: Select<string> | null, value: string) => {
        if (focusId === value) {
            mappingValueRef.current = e;
        }
    };

    return (
        <div>
            {question.options?.map(option => (
                <div key={option.value} className={styles.mappingoption}>
                    <div style={{ width: "40%" }}>{option.text}</div>
                    <Select
                        ref={e => setFocusRef(e, option.value)}
                        size="small"
                        className={styles.propertiesselect}
                        onChange={(value) => optionMappingChange(option, value)}
                        value={option.mappingValue}
                        allowClear
                    >
                        {mappingField && mappingField.options.map(opt => (
                            <Option key={opt.value} value={opt.value}>{opt.name}</Option>
                        ))}
                    </Select>
                </div>
            ))}
        </div>
    );
}

export default MappingProperties;

interface MappingPropertiesProps {
    item: EditorItem;
    surveyTemplate: SurveyTemplate;
    selectedMapping: string;
    mappingDefinitions: MappingDefinition[];
    mappingRef: MutableRefObject<Select<string> | null>;
    focusId: string;
    mappingValueRef: MutableRefObject<Select<string> | null>;
    onUpdateItem: (item: EditorItem) => void;
}

interface SurveyMappingProps {
    survey: SurveyTemplate;
    mappingDefinitions: MappingDefinition[];
    mappingRef: MutableRefObject<Select<string> | null>;
    onMappingDefinitionChange: (mappingDefinition: string) => void;
    onAllowMultiMappingChange: (allowMultiMapping: boolean) => void;
}

interface QuestionMappingProps {
    question: QuestionTypes;
    mappingField?: MappingField;
    availableFields: MappingField[];
    focusId: string;
    mappingValueRef: MutableRefObject<Select<string> | null>;
    onQuestionMappingChange: (updatedQuestion: QuestionTypes) => void;
}

interface QuestionOptionsMappingProps {
    question: SingleSelectionQuestion;
    mappingField: SingleSelectionField;
    focusId: string;
    mappingValueRef: MutableRefObject<Select<string> | null>;
    onQuestionMappingChange: (updatedQuestion: QuestionTypes) => void;
}