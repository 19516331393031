import { ExchangeSettings } from "../../models/Settings";
import httpClient from "./httpClient";

const getExchangeSettings = async () => {
    const client = await httpClient.getInstance();
    const result = await client.get<ExchangeSettings>(`configuration/exchange`);
    return result.data;
};

const updateExchangeSettings = async (exchangeSettings: ExchangeSettings) => {
    const client = await httpClient.getInstance();
    const result = await client.post("configuration/exchange", exchangeSettings);
    return result.data;
};

const validateExchangeSettings = async (exchangeSettings: ExchangeSettings) => {
    const client = await httpClient.getInstance();
    const result = await client.post("configuration/exchange/validate", exchangeSettings);
    return result.data;
};

export default { getExchangeSettings, updateExchangeSettings, validateExchangeSettings }