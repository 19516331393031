import { Space, Button } from "antd";
import React, { FC } from "react";
import CommandBar from "../../../../shared/components/CommandBar/CommandBar";
import { FileExcelOutlined, NumberOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";

export const TokensCommandBar: FC<TokensCommandBarProps> = ({ isAnonymous, hasEditAccess, onCreateTokens, onCreateReceivers, onDownloadTokens, onDeleteTokens }) => {
    return (
        <CommandBar title={<Space><NumberOutlined /> {isAnonymous ? "Tokenübersicht" : "Empfängerliste"}</Space>}>
            <Space>
                {hasEditAccess && isAnonymous && <Button type="primary" icon={<NumberOutlined />} onClick={onCreateTokens}>Tokens erstellen</Button>}
                {hasEditAccess && !isAnonymous && <Button type="primary" icon={<UserAddOutlined />} onClick={onCreateReceivers}>Empfänger anlegen</Button>}
                <Button icon={<FileExcelOutlined />} title="Export nach Excel" onClick={onDownloadTokens}></Button>
                {hasEditAccess && <Button icon={<DeleteOutlined />} title="Alle Tokens löschen" onClick={onDeleteTokens}></Button>}
            </Space>
        </CommandBar>
    );
}

interface TokensCommandBarProps {
    isAnonymous: boolean;
    hasEditAccess: boolean;
    onCreateTokens: () => void;
    onCreateReceivers: () => void;
    onDownloadTokens: () => void;
    onDeleteTokens: () => void;
}