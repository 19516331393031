import { RcFile } from "antd/lib/upload";
import { SurveyReceiver, SurveyToken } from "../../models/SurveyTokens";
import { SurveyListItem, SurveyVm } from "../../models/SurveyVm";
import { downloadFile } from "./FileDownloadHelper";
import httpClient from "./authHttpClient";
import administrationHttpClient from "./httpClient";
import moment from "moment";
import { IFilterConfig } from "../../administration/surveys/SurveyList/SurveyListFilter";
import { MailSettings, TokenAnswers } from "../../models/SurveyTemplate";
const dateFormat = "YYYY.MM.DD HH:mm:ss";

const getSurveyList = async (filterConfig: IFilterConfig, ids?: string[] ): Promise<SurveyListItem[]> => {
    const { from, to } = filterConfig;
    const client = await administrationHttpClient.getInstance();
    const response = await client.post<SurveyListQueryResult>("surveys/list", {
        from: moment(from).format(dateFormat),
        to: to ? moment(to).format(dateFormat): '',
        ids: ids || []
    });

    return response.data.surveys;
}

const getSurveyVm = async (token: string): Promise<SurveyVm> => {
    const response = await httpClient.getInstance().get<GetSurveyVmQueryResult>(`tokens/${token}/survey/empty`);
    return response.data.surveyVm;
}

const getSurveyVmWithAnswers = async (token: string): Promise<SurveyVm> => {
    const response = await httpClient.getInstance().get<GetSurveyVmQueryResult>(`tokens/${token}/survey`);
    return response.data.surveyVm;
}

const completeSurveyVm = async (surveyToken: string, surveyVm: SurveyVm): Promise<SurveyCompletedResult> => {
    const command: CompleteSurveyCommand = {
        surveyVm,
        surveyToken
    }
    const response = await httpClient.getInstance().post<SurveyCompletedResult>(`tokens/${surveyToken}/survey`, command);
    localStorage.setItem("", surveyVm.id)
    return response.data;
}

const createSurveyFromTemplate = async (command: CreateSurveyFromTemplateCommand) => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.post<CreateSurveyFromTemplateResult>(`surveys/createfromtemplate`, command);
    return response.data.surveyId;
};

const getSurveyById = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get<GetSurveyByIdResult>(`surveys/${surveyId}`);
    return response.data.survey;
}

const getValidSurveyTokens = async (surveyId: string): Promise<string[]> => {
    const response = await httpClient.getInstance().get<GetValidTokensResult>(`surveys/${surveyId}/validtokens`);
    return response.data.tokens;
}

const getTokenOverview = async (surveyId: string): Promise<SurveyToken[]> => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get<SurveyTokensResult>(`surveys/${surveyId}/tokens/overview`);
    return response.data.tokens;
};

const generateSurveyToken = async (surveyId: string, count: number): Promise<SurveyTokensResult> => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get<SurveyTokensResult>(`surveys/${surveyId}/tokens/generate/${count}`);
    return response.data;
};

const getSurveyStatisticChartData = async (surveyId: string): Promise<StatisticChartData> => {
    const client = await administrationHttpClient.getInstance();
    const barChartResponse = await client.get<SurveyBarChartVm>(`surveys/${surveyId}/charts/barchartvm`);
    const textChartResponse = await client.get<SurveyTextChartVm>(`surveys/${surveyId}/charts/textchartvm`);

    return { barChartRecords: barChartResponse.data.records, textChartRecords: textChartResponse.data.records }
};

const downloadSurveyTokensExcelFile = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get(`surveys/${surveyId}/tokens/file`, { responseType: 'blob' })
    downloadFile(response.data, `Empfänger_Tokensliste_${surveyId}.xlsx`);
}

const downloadSurveyReceiverExcelFile = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get(`surveys/${surveyId}/receivers/filetemplate`, { responseType: 'blob' })
    downloadFile(response.data, `Vorlage_Empfängerliste_${surveyId}.xlsx`);
}

const downloadAnswersExcelFile = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const response = await client.get(`surveys/${surveyId}/answers/file`, { responseType: 'blob' });
    downloadFile(response.data, `Antworten_${surveyId}.xlsx`);
}

const uploadReceiversAndGet = async (surveyId: string, file: RcFile, onProgress: (arg0: { percent: number; }, arg1: RcFile) => void) => {
    const client = await administrationHttpClient.getInstance();

    const config = {
        headers: { "content-type": file.type },
        onUploadProgress: (event: { loaded: number; total: number; }) => {
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
        }
    };

    const response = await client.post<UploadReceiversResult>(`surveys/${surveyId}/receivers/upload`, file, config);
    return response.data.receiversData;
};


const generateSurveyTokensFromExcel = async (surveyId: string, file: RcFile, onProgress: (arg0: { percent: number; }, arg1: RcFile) => void): Promise<SurveyToken[]> => {
    const client = await administrationHttpClient.getInstance();

    const config = {
        headers: { "content-type": file.type },
        onUploadProgress: (event: { loaded: number; total: number; }) => {
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
        }
    };

    const result = await client.post<SurveyTokensResult>(`surveys/${surveyId}/tokens/createfromreceiversexcel`, file, config);
    return result.data.tokens;
};

const createTokensByReceivers = async (command: CreateTokensFromReceiversCommand) => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.post<SurveyTokensResult>(`surveys/${command.surveyId}/tokens/createfromreceivers`, command);
    return result.data.tokens;
};

const getInvitationMail = async (tokenId: string): Promise<GetInvitationMailResult> => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.get<GetInvitationMailResult>(`surveys/${tokenId}/mails/invitation`);
    return result.data;
}

const updateSurveyMetadata = async (command: UpdateSurveyMetadataCommand) => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.post(`surveys/${command.surveyId}/metadata`, command);
}

const deleteSurvey = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.delete(`surveys/${surveyId}`);
    return result.data.success;
};

const deleteSurveyTokens = async (surveyId: string) => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.delete(`surveys/${surveyId}/tokens`);
    return result.data;
}

const deleteSurveyAnswersByToken = async (surveyId: string, token: string) => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.delete(`surveys/${surveyId}/answers?token=${token}`);
    return result.data;
}

const getTokensAnswers = async(surveyId:string, token: string):Promise<TokenAnswers[]> =>
{
    const client = await administrationHttpClient.getInstance();
    const response = await client.get<TokenAnswersResponse>(`tokens/${token}/answersvm/?surveyToken=${token}&surveyId=${surveyId}`);
    return response.data.tokenAnswers;
}

const sendInvitationMail = async (surveyId: string, mailData: MailData[]): Promise<MailResult[]> => {
    const client = await administrationHttpClient.getInstance();
    const result = await client.post(`surveys/${surveyId}/mails/invitation/send`, { payload: mailData });
    return result.data.results;
};

export default {
    getSurveyVm,
    getSurveyVmWithAnswers,
    completeSurveyVm,
    getValidSurveyTokens,
    getSurveyList,
    createSurveyFromTemplate,
    getTokenOverview,
    generateSurveyToken,
    getSurveyById,
    downloadSurveyReceiverExcelFile,
    downloadSurveyTokensExcelFile,
    downloadAnswersExcelFile,
    uploadReceiversAndGet,
    generateSurveyTokensFromExcel,
    getSurveyStatisticChartData,
    createTokensByReceivers,
    getInvitationMail,
    deleteSurvey,
    updateSurveyMetadata,
    deleteSurveyTokens,
    deleteSurveyAnswersByToken,
    getTokensAnswers,
    sendInvitationMail
};

interface MailData {
    surveyId: string; 
    surveyTokenId: string;
    metadata: {};
}

interface MailResult {
    email: string;
    success: boolean;
    message: string;
}

interface CompleteSurveyCommand {
    surveyVm: SurveyVm;
    surveyToken: string;
}

interface GetSurveyVmQueryResult {
    surveyVm: SurveyVm;
}

interface SurveyCompletedResult {
}

interface GetValidTokensResult {
    tokens: string[];
}

interface SurveyTokensResult {
    tokens: SurveyToken[];
}

interface SurveyListQueryResult {
    surveys: SurveyListItem[];
}

export interface CreateSurveyFromTemplateCommand {
    surveyTemplateId: string;
    validFrom: Date;
    validTo: Date;
}

export interface CreateTokensAndSendSurveyCommand {
    surveyId: string;
    receivers: SurveyReceiver[];
}

export interface CreateTokensFromReceiversCommand {
    surveyId: string;
    receivers: SurveyReceiver[];
}

interface CreateSurveyFromTemplateResult {
    surveyId: string;
}

interface GetSurveyByIdResult {
    survey: SurveyVm;
}

interface UploadReceiversResult {
    receiversData: SurveyReceiver[];
}

export interface UpdateSurveyMetadataCommand {
    surveyId: string;
    title: string;
    validFrom: string;
    validTo: string;
    mailSettings: MailSettings;
}

interface SurveyBarChartVm {
    records: BarChartRecord[];
}

export interface BarChartRecord {
    questionId: string;
    question: string;
    option: string;
    amount: number;
}

interface SurveyTextChartVm {
    records: TextChartRecord[];
}

export interface TextChartRecord {
    questionId: string;
    question: string;
    answer: string;
}

export interface StatisticChartData {
    barChartRecords: BarChartRecord[];
    textChartRecords: TextChartRecord[];
}

interface GetInvitationMailResult {
    success: boolean;
    errorMessage: string;
    email: Mail;
}

export interface Mail {
    to: string;
    from: string;
    subject: string;
    body: string;
}
interface TokenAnswersResponse
{
    tokenAnswers:TokenAnswers[];
}