import { Form, Input, InputNumber, Space, Switch } from "antd";
import React, { FC, useEffect, useState } from "react";
import { QuestionTypes } from "../../../../../models/SurveyTemplate";
import { Validation } from "../../../../../models/SurveyVm";
import styles from "./ValidationProperties.module.css";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
const mailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";

export const ValidationProperties: FC<ValidationPropertiesProps> = ({ question, onUpdateQuestion }) => {
    const [isEmail, setIsEmail] = useState(false);
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({
            regex: question.validation?.regex,
            min: question.validation?.min,
            max: question.validation?.max,
            message: question.validation?.message
        });

        setIsEmail(question.validation?.regex === mailPattern);
    }, [question.validation]);

    const onFinish = (values: Store) => {
        onUpdateQuestion({
            ...question,
            validation: {
                regex: values.regex,
                min: values.min,
                max: values.max,
                message: values.message
            }
        });
    }

    const onChangeEmailValidation = (checked: boolean) => {
        const tmpValidation: Validation = emptyValidation();
        if (checked) {
            tmpValidation.regex = mailPattern;
            tmpValidation.message = "Ungültige E-Mail-Adresse";
        }

        form.resetFields();
        form.setFieldsValue({ ...tmpValidation });
        setIsEmail(checked);
        submit();
    }

    const regexValidator = (value: string) => {
        if (isValidRegex(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error('Ungültiger Regex-Ausdruck'));
        }
    }

    const minValidator = (value: string) => {
        const max = form.getFieldValue("max");
        if (max && value && value > max) {
            return Promise.reject(new Error('Der min. Wert darf nicht größer als der max. Wert sein.'));            
        } else {
            return Promise.resolve();
        }
    }

    const maxValidator = (value: string) => {
        const min = form.getFieldValue("min");
        if (min && value && min > value) {
            return Promise.reject(new Error('Der min. Wert darf nicht größer als der max. Wert sein.'));            
        } else {
            return Promise.resolve();
        }
    }

    const submit = () => form.submit();
    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            {(question.type === "Input" || question.type === "Textarea") &&
                <>
                    <Space className={styles.item}>
                        <Switch
                            className={styles.switch}
                            size="small"
                            checked={isEmail}
                            onChange={onChangeEmailValidation}
                        />
                        <span>E-Mail Validierung</span>
                    </Space>
                    {!isEmail &&
                        <Form.Item
                            className={styles.item}
                            name="regex"
                            rules={[{ validator: (_, value) => regexValidator(value) }]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder="Bitte verwenden Sie einen gültigen Regex-Ausdruck"
                                onBlur={submit}
                            />
                        </Form.Item>
                    }
                </>}
            {question.type === "Number" &&
                <>
                    <div className={styles.formitemcontainer}>
                        <div className={styles.label}>minimaler Wert</div>
                        <Form.Item
                            className={styles.item}
                            name="min"
                            rules={[{ validator: (_, value) => minValidator(value) }]}
                            validateTrigger="onBlur"
                        >
                            <InputNumber onBlur={submit} />
                        </Form.Item>
                    </div>
                    <div className={styles.formitemcontainer}>
                        <div className={styles.label}>maximaler Wert</div>
                        <Form.Item
                            className={styles.item}
                            name="max"
                            rules={[{ validator: (_, value) => maxValidator(value) }]}
                            validateTrigger="onBlur"
                        >
                            <InputNumber onBlur={submit} />
                        </Form.Item>
                    </div>
                </>
            }
            <Form.Item
                className={styles.item}
                name="message"
            >
                <Input
                    placeholder="Fehlermeldung"
                    onBlur={submit}
                />
            </Form.Item>
        </Form>
    );
}

interface ValidationPropertiesProps {
    question: QuestionTypes;
    onUpdateQuestion: (question: QuestionTypes) => void;
}

function emptyValidation(): Validation {
    return ({
        regex: ""
    });
}

export function isValidRegex(pattern: string): boolean {
    try {
        new RegExp(pattern);
        return true;
    } catch (e) {
        return false;
    }
}