import React, { FC } from "react";
import { List, Button, Avatar, Space } from "antd";
import { SurveyTemplate } from "../../../models/SurveyTemplate";
import { useHistory } from "react-router-dom";
import { LayoutOutlined } from "@ant-design/icons";
import { CopyOutlined, EditOutlined, DeleteOutlined, EyeOutlined, UnlockOutlined } from "@ant-design/icons";
import styles from "./SurveyTemplateList.module.less";
import templateService from "../../../shared/services/templateService";
import HtcSpin from "../../../shared/components/HtcSpin/HtcSpin";
import { userService } from "../../../shared/services/userService";

const SurveyTemplateList: FC<SurveyTemplateListProps> = ({ loading, templates, reload, onOpenPermissions }) => {
    const history = useHistory();    
    const userClaims = userService.getUserClaimsObject();

    const onEdit = (id: string) => history.push(`/administration/templates/edit/${id}`);
    const onCopy = (id: string) => history.push(`/administration/templates/copy/${id}`);
    const onDelete = async (id: string) => {
        if (window.confirm("Wollen Sie diese Vorlage wirklich endgültig löschen?")) {
            await templateService.deleteTemplate(id);
            reload();
        }
    };

    const renderActions = (template: SurveyTemplate) => {
        const canEditTemplate = userClaims.hasPermissionClaim("CanEditTemplates", template.id) || userClaims.isAdministrator();
        const canAddTemplate = userClaims.hasPermissionClaim("CanAddTemplates") || userClaims.isAdministrator();
        return (
            <Space>
                <Button
                    disabled={!canEditTemplate}
                    icon={<UnlockOutlined />}
                    type="text"
                    title="Benutzer berechtigen"
                    onClick={() => onOpenPermissions(template)}
                />
                {
                    canEditTemplate ?
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            title="Vorlage bearbeiten"
                            onClick={() => onEdit(template.id)}
                        />
                        :
                        <Button
                            type="text"
                            icon={<EyeOutlined />}
                            title="Vorlage anzeigen"
                            onClick={() => onEdit(template.id)}
                        />
                }
                <Button
                    disabled={!canAddTemplate}
                    icon={<CopyOutlined />}
                    type="text"
                    title="Vorlage kopieren"
                    onClick={() => onCopy(template.id)}
                />
                <Button
                    disabled={!canEditTemplate}
                    type="text" icon={<DeleteOutlined />}
                    title="Vorlage löschen"
                    onClick={() => onDelete(template.id)}
                />
            </Space>
        );
    }

    return (
        <div className={styles.templates}>
            <List
                dataSource={templates}
                loading={{ indicator: <HtcSpin />, spinning: loading }}
                renderItem={(template) => (
                    <List.Item extra={renderActions(template)}>
                        <List.Item.Meta
                            avatar={<Avatar icon={<LayoutOutlined />} className={styles.listicon} />}
                            title={template.title}
                            description={template.description}
                        />
                    </List.Item>
                )} />
        </div>
    );
}

export default SurveyTemplateList;

interface SurveyTemplateListProps {
    loading: boolean;
    templates: SurveyTemplate[];
    reload: () => void;
    onOpenPermissions: (template: SurveyTemplate) => void;
}