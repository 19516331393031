import React, { FC } from "react";
import { ColumnsType } from "antd/lib/table";
import { Button, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { PermissionClaim } from "../../../../../models/Claim";

export const UsersPermissionClaimList: FC<UsersPermissionClaimListProps> = ({ userclaims, onDelete }) => {
    const columns: ColumnsType<IUserClaimListItem> = [
        {
            title: "Benutzer",
            dataIndex: "userdisplayname",
            defaultSortOrder: 'ascend',
            sorter: (a: IUserClaimListItem, b: IUserClaimListItem) => a.userdisplayname?.localeCompare(b.userdisplayname),
        },
        {
            title: "Rechte",
            render: (userclaim: IUserClaimListItem) => userclaim.claim.displaytext
        },
        {
            key: "action",
            render: (userclaim: IUserClaimListItem) => <Button icon={<DeleteOutlined />} title="Berechtigung löschen" onClick={() => onDelete(userclaim.userid, userclaim.claim)} />
        }
    ];

    return (
        <Table
            style={{ marginTop: "15px" }}
            columns={columns}
            dataSource={userclaims}
            rowKey={(uc) => `${uc.userid}${uc.claim.value}.${uc.claim.id}`}
        />
    );
}

interface UsersPermissionClaimListProps {
    userclaims: IUserClaimListItem[];
    onDelete: (userid: string, claim: PermissionClaim) => void;
}

export interface IUserClaimListItem {
    userid: string;
    userdisplayname: string;
    claim: PermissionClaim;
}
