import { MailSettings } from "./SurveyTemplate";

export interface SurveyListItem {
    id: string;
    title: string;
    description: string;
    closingWords: string;
    validFrom: Date;
    validTo: Date;
    creator: string;
    created: Date;
}

export interface SurveyVm {
    id: string;
    title: string;
    description: string;
    closingWords: string;
    validFrom: Date;
    validTo: Date;
    pages: Page[];
    enableLastTokenAnswerTransfer: boolean;
    mailSender: string;
    mailSettings: MailSettings;
    reminderMailSettings: MailSettings;
    isAnonymous: boolean;
    metadataColumns: string[];
}

export interface Page {
    title: string;
    questions: QuestionTypes[];
}

export type QuestionTypes = InputQuestion
    | TextareaQuestion
    | SingleSelectionQuestion
    | MultiSelectionQuestion
    | RadioGroupQuestion
    | MatrixQuestion
    | DateQuestion
    | NumberQuestion;

export interface QuestionBase {
    id: string;
    title: string;
    description: string;
    htmlDescription:string;
    required: boolean;
    dependency?: Dependency;
    validation?: Validation;
    mappingField: string;
}

export interface InputQuestion extends QuestionBase {
    type: "Input";
    value: string;
}

export interface TextareaQuestion extends QuestionBase {
    type: "Textarea";
    value: string;
}

export interface DateQuestion extends QuestionBase {
    type: "Date",
    value: string
}

export interface NumberQuestion extends QuestionBase {
    type: "Number",
    value: number
}

export interface SingleSelectionQuestion extends QuestionBase {
    type: "SingleSelection";
    options: Option[];
    value: string;
}

export interface MultiSelectionQuestion extends QuestionBase {
    type: "MultiSelection";
    options: Option[];
    values: string[];
}

export interface RadioGroupQuestion extends QuestionBase {
    type: "RadioGroup";
    options: Option[];
    value: string;
    comment?: string;
}

export interface MatrixQuestion extends QuestionBase {
    type: "Matrix";
    questions: Subquestion[];
    options: Option[];
}

export interface Option {
    value: string;
    text: string;
    withComment?: boolean;
    comment?: string;
    mappingValue: string;
}

export interface Dependency {
    connector: string;
    conditions: Condition[];
}

export interface Condition {
    questionId: string;
    subquestionId?: string;
    value: string;
    comment?: string;
}

export interface Validation {
    regex: string;
    min?: number;
    max?: number;
    message?: string;
}

export interface Subquestion {
    id: string;
    description: string;
    value: string;
}

export enum Connector {
    And,
    Or
}

export const isQuestionAvailable = (question: QuestionBase, pages: Page[]): boolean => {
    return !question.dependency || checkDependency(question.dependency, pages);
}

const checkDependency = (dependency: Dependency, pages: Page[]): boolean => {
    return dependency.connector === Connector[Connector.Or] ?
        dependency.conditions.some(c => compareQuestionValues(c, pages)) :
        dependency.conditions.every(c => compareQuestionValues(c, pages));
}

const compareQuestionValues = (condition: Condition, pages: Page[]): boolean => {
    const question = pages.flatMap(p => p.questions).find(q => q.id === condition.questionId);
    switch (question?.type) {
        case "Input": return (question as InputQuestion).value === condition.value;
        case "Textarea": return (question as TextareaQuestion).value === condition.value;
        case "Number": return (question as NumberQuestion).value === parseInt(condition.value);
        case "Date": return (question as DateQuestion).value === condition.value;
        case "SingleSelection": return (question as SingleSelectionQuestion).value === condition.value;
        case "MultiSelection": return (question as MultiSelectionQuestion).values?.some(v => v === condition.value);
        case "RadioGroup": return (question as RadioGroupQuestion).value === condition.value;
        case "Matrix": return (question as MatrixQuestion).questions?.find(q => q.id === condition.subquestionId)?.value === condition.value;
    }
    return false;
}

export const hasValue = (question: QuestionTypes): boolean => {
    switch (question?.type) {
        case "Input": return (question as InputQuestion).value?.length > 0;
        case "Textarea": return (question as TextareaQuestion).value?.length > 0;
        case "Number": return (question as NumberQuestion).value !== (null || undefined);
        case "Date": return (question as DateQuestion).value !== (null || undefined);
        case "SingleSelection": return (question as SingleSelectionQuestion).value?.length > 0;
        case "MultiSelection": return (question as MultiSelectionQuestion).values?.length > 0;
        case "RadioGroup": return (question as RadioGroupQuestion).value?.length > 0;
        case "Matrix": return (question as MatrixQuestion).questions?.some(q => q.value?.length > 0);
        default: return false;
    }
}