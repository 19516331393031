import React, { FC, useState, useEffect } from "react";
import templateService from "../../shared/services/templateService";
import SurveyTemplateList from "./SurveyTemplateList/SurveyTemplateList";
import { Button, Layout, Space } from "antd";
import HtcPageHeader from "../../shared/components/HtcPageHeader/HtcPageHeader";
import CommandBar from "../../shared/components/CommandBar/CommandBar";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useData } from "../../shared/hooks/useData";
import styles from "./SurveyTemplates.module.css";
import { SurveyTemplate } from "../../models/SurveyTemplate";
import { userService } from "../../shared/services/userService";
import { SurveyTemplatePermissionClaims } from "./SurveyTemplatePermissonClaims/SurveyTemplatePermissonClaims";
const { Content } = Layout;

const SurveyTemplates: FC = () => {
    const { data, loading, triggerReload } = useData("", () => templateService.getTemplates());
    const [templates, setTemplates] = useState(data || []);
    const [selectedTemplateForPermissions, setSelectedTemplateForPermissions] = useState<SurveyTemplate>();
    const history = useHistory();
    useEffect(() => setTemplates(data || []), [data]);
    const userClaims = userService.getUserClaimsObject();

    const onNewTemplate = () => history.push("/administration/templates/new");
    const onClosePermissions = () => setSelectedTemplateForPermissions(undefined);
    const canAddTemplate = userClaims.hasPermissionClaim("CanAddTemplates") || userClaims.isAdministrator();
    return (
        <Layout className={styles.layout}>
            <HtcPageHeader title={<Space>Vorlagen verwalten</Space>}>
                <CommandBar >
                    <Button disabled={!canAddTemplate} onClick={() => onNewTemplate()} type="primary" icon={<PlusOutlined />}>Neue Vorlage</Button>
                </CommandBar>
            </HtcPageHeader>
            <Content>
                <SurveyTemplateList
                    loading={loading}
                    templates={templates}
                    reload={triggerReload}
                    onOpenPermissions={setSelectedTemplateForPermissions}
                />
                {
                    selectedTemplateForPermissions &&
                    <SurveyTemplatePermissionClaims
                        template={selectedTemplateForPermissions}
                        onClose={onClosePermissions}
                    />
                }
            </Content>
        </Layout>
    );
}

export default SurveyTemplates;