import React, { FC, MutableRefObject } from "react";
import { PageHeader, List, Button, Tooltip } from "antd";
import { HighlightOutlined, AlignLeftOutlined, CalendarOutlined, UnorderedListOutlined, TableOutlined, SelectOutlined, MenuOutlined, NumberOutlined } from "@ant-design/icons";
import { ToolboxItem } from "../../../../models/Editor";
import styles from "./ToolboxPane.module.css";

const ToolboxPane: FC<ToolboxPaneProps> = ({ toolboxRef, onAddQuestion }) => {
    const toolboxItems = createToolboxData();

    const setFocusRef = (e: HTMLElement | null, type: string) => {
        if (type === "Input") {
            toolboxRef.current = e;
        }
    };

    return (
        <PageHeader title="Toolbox">
            <span className={styles.subtitle}>Verfügbare Fragen</span>
            <List dataSource={toolboxItems} renderItem={(item) => (
                <List.Item
                    onClick={() => onAddQuestion(item.type)}
                >
                    <Tooltip placement="right" title={item.title}>
                        <Button ref={e => setFocusRef(e, item.type) } icon={item.icon} className={styles.toolboxbtn}>
                            {item.title}
                        </Button>
                    </Tooltip>
                </List.Item>
            )} />
        </PageHeader>
    );
}

export default ToolboxPane;

function createToolboxData(): ToolboxItem[] {
    return [
        { title: "Text", type: "Input", icon: <HighlightOutlined /> },
        { title: "Text (mehrzeilig)", type: "Textarea", icon: <AlignLeftOutlined /> },
        { title: "Zahl", type: "Number", icon: <NumberOutlined /> },
        { title: "Datum", type: "Date", icon: <CalendarOutlined /> },
        { title: "Auswahl", type: "SingleSelection", icon: <SelectOutlined /> },
        { title: "Auswahl (mehrfach)", type: "MultiSelection", icon: <MenuOutlined /> },
        { title: "Auswahl (Liste)", type: "RadioGroup", icon: <UnorderedListOutlined /> },
        { title: "Matrix", type: "Matrix", icon: <TableOutlined /> }
    ];
}

interface ToolboxPaneProps {
    toolboxRef: MutableRefObject<HTMLElement | null>;
    onAddQuestion: (type: string) => void;
}