import React, { FC, useState, useEffect, useRef, MutableRefObject } from "react";
import { useParams, useHistory, RouteComponentProps, match, Prompt } from "react-router";
import { Layout, Button, message, Input, Select, Tag } from "antd";
import styles from "./SurveyTemplateEditor.module.less";
import HtcPageHeader from "../../../../shared/components/HtcPageHeader/HtcPageHeader";
import CommandBar from "../../../../shared/components/CommandBar/CommandBar";
import Workbench from "../Workbench/Workbench";
import PropertiesPane from "../PropertiesPane/PropertiesPane";
import ToolboxPane from "../ToolboxPane/ToolboxPane";
import { Page, QuestionTypes, InputQuestion, DateQuestion, RadioGroupQuestion, MatrixQuestion, SingleSelectionQuestion, MultiSelectionQuestion, SurveyTemplate, QuestionBase, NumberQuestion, MailSettings, TextareaQuestion } from "../../../../models/SurveyTemplate";
import { EditorItem } from "../../../../models/Editor";
import { findEditorItem, getPageByQuestionId } from "../EditorHelpers";
import _ from "lodash";
import templateService from "../../../../shared/services/templateService";
import mappingDefinitionService from "../../../../shared/services/mappingDefinitionService";
import { v4 } from "uuid";
import { EyeOutlined, CloseOutlined, SaveOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useData } from "../../../../shared/hooks/useData";
import HtcSpin from "../../../../shared/components/HtcSpin/HtcSpin";
import SurveyPreview from "../../../survey/SurveyPreview/SurveyPreview";
import ValidationSummary from "../../../../shared/components/ValidationSummary/ValidationSummary";
import TextArea from "antd/lib/input/TextArea";
import { hasMessages, hasMessagesFromType, SurveyTemplateValidator, ValidationData, ValidationProperty } from "../../../../models/SurveyTemplateValidator";
import HtcConfirm from "../../../../shared/components/HtcConfirm/HtcConfirm";
import { CanEditTemplatesClaim } from "../../../../models/Claim";
import { userService } from "../../../../shared/services/userService";
import ReactQuill from "react-quill";
const { Content, Sider } = Layout;

const ABORT_MESSAGE = "Wollen Sie die Bearbeitung wirklich abbrechen? Alle bisher vorgenommenen Änderungen gehen hiermit verloren.";

export const SurveyTemplateEdit: FC<SurveyTemplateEditProps> = ({ match }) => {
    const { id } = useParams<{ id: string }>();
    const { loading, data } = useData<SurveyTemplate>("", () => templateService.getTemplate(id));
    const [template, setTemplate] = useState(data);
    useEffect(() => setTemplate(data), [data])

    const templateCopy: SurveyTemplate = template ? {
        ...template,
        id: "",
        title: `${template?.title} - Kopie`
    } : emptyTemplate();

    if (loading) {
        return <HtcSpin />
    };

    return (<>
        {template && <SurveyTemplateEditor surveyTemplate={isCopyUrl(match) ? templateCopy : template} />}
    </>);
}

interface SurveyTemplateEditProps extends RouteComponentProps<{ id: string }> {
}

function isCopyUrl(match: match) {
    return match.path === `/administration/templates/copy/:id`;
}

export const SurveyTemplateNew: FC = () => {
    return (
        <SurveyTemplateEditor surveyTemplate={emptyTemplate()} />
    );
}

const SurveyTemplateEditor: FC<SurveyTemplateEditorProps> = ({ surveyTemplate }) => {
    const history = useHistory();
    const [template, setTemplate] = useState(surveyTemplate);
    const [openPreview, setOpenPreview] = useState(false);
    const { pages } = template;
    const [currentPageId, setCurrentPageId] = useState(surveyTemplate?.pages[0].id);
    const [currentItem, setCurrentItem] = useState<EditorItem>({ type: "survey", item: surveyTemplate });
    const { data } = useData("", () => mappingDefinitionService.getMappingDefinitions());
    const [mappingDefintions, setMappingDefinitions] = useState(data || []);
    const [templateTitle, setTemplateTitle] = useState("");
    const [validationData, setValidationData] = useState<ValidationData>();
    const [showValidationData, setShowValidationData] = useState(false);
    const [openWarnings, setOpenWarnings] = useState(false);
    const [focusChange, setFocusChange] = useState(false);
    const [focusType, setFocusType] = useState<ValidationProperty | undefined>();
    const [focusId, setFocusId] = useState<string | undefined>();
    const titleRef = useRef() as MutableRefObject<Input | null>;
    const descriptionRef = useRef() as MutableRefObject<TextArea | null>;
    const mailSenderRef = useRef() as MutableRefObject<TextArea | null>;
    const subjectRef = useRef() as MutableRefObject<Input | null>;
    const bodyRef = useRef() as MutableRefObject<ReactQuill | null>;
    const optionRef = useRef() as MutableRefObject<Input | null>;
    const subquestionRef = useRef() as MutableRefObject<Input | null>;
    const mappingRef = useRef() as MutableRefObject<Select<string> | null>;
    const mappingValueRef = useRef() as MutableRefObject<Select<string> | null>;
    const toolboxRef = useRef() as MutableRefObject<HTMLElement | null>;
    const optionsRef = useRef() as MutableRefObject<HTMLElement | null>;
    const subquestionsRef = useRef() as MutableRefObject<HTMLElement | null>;
    useEffect(() => setMappingDefinitions(data || []), [data]);
    useEffect(() => setTemplateTitle(template.title), [template.title]);
    useEffect(() => { validateData() }, [template, mappingDefintions]);
    useEffect(() => { setFocus() }, [focusChange]);

    const userClaims = userService.getUserClaimsObject();

    const validateData = () => {
        const validator: SurveyTemplateValidator = new SurveyTemplateValidator();
        const validationResult = validator.getSurveyValidationData(template, mappingDefintions);

        if (hasMessages(validationResult)) {
            setValidationData(validationResult);
            return;
        }

        setValidationData(undefined);
        setShowValidationData(false);
    }

    const onSave = async () => {
        if (!showValidationSummaryIfInvalid()) {
            return;
        }

        setOpenWarnings(false);
        try {
            const result = template.id
                ? await templateService.updateTemplate(template)
                : await templateService.addTemplate(template);

            userClaims.addPermissionClaim({ type: "Permission", value: "CanEditTemplates", displaytext: "Schreiben", id: result.surveyTemplateId } as CanEditTemplatesClaim);
            userService.setUserClaimsObject(userClaims);

            history.push("/administration/templates")
        }
        catch (e) {
            console.log(e);
            message.error('Die Umfrage konnte nicht erfolgreich gespeichert werden. Bitte wenden Sie sich an die Systembetreuung.')
        }
    }

    const focusItem = (item: EditorItem, type: ValidationProperty, focusId: string) => {
        onItemSelect(item);
        setFocusId(focusId);
        setFocusType(type);
        setFocusChange(!focusChange);
    };

    const setFocus = () => {
        switch (focusType) {
            case ValidationProperty.Title: titleRef.current?.focus(); break;
            case ValidationProperty.Description: descriptionRef.current?.focus(); break;
            case ValidationProperty.Subject: subjectRef.current?.focus(); break;
            case ValidationProperty.Body: bodyRef.current?.focus(); break;
            case ValidationProperty.Option: optionRef.current?.focus(); break;
            case ValidationProperty.Subquestion: subquestionRef.current?.focus(); break;
            case ValidationProperty.MappingDefinition: mappingRef.current?.focus(); break;
            case ValidationProperty.MappingFields: mappingRef.current?.focus(); break;
            case ValidationProperty.MappingValue: mappingValueRef.current?.focus(); break;
            case ValidationProperty.Pages: addPage(emptyPage()); break;
            case ValidationProperty.Questions: toolboxRef.current?.focus(); break;
            case ValidationProperty.Options: optionsRef.current?.focus(); break;
            case ValidationProperty.Subquestions: subquestionsRef.current?.focus(); break;
            case ValidationProperty.MailSender: mailSenderRef.current?.focus(); break;
        };
    }

    const showValidationSummaryIfInvalid = (): boolean => {
        if (!validationData) {
            setShowValidationData(false);
            setOpenWarnings(false);
            return true;
        }

        setShowValidationData(true);

        if (hasMessagesFromType(validationData, "Error")) {
            return false;
        }

        if (!openWarnings && hasMessagesFromType(validationData, "Warning")) {
            setOpenWarnings(true);
            return false;
        }

        return true;
    }

    const onCancel = () => {
        if (window.confirm(ABORT_MESSAGE)) {
            history.push("/administration/templates");
        }
    };

    const getCurrentPage = () => {
        return template.pages.find(p => p.id === currentPageId);
    }

    const navigateToPage = (pageId: string) => {
        const page = findEditorItem(pageId, "page", template) as EditorItem;
        onItemSelect(page);
    };

    const onItemSelect = (item: EditorItem) => {
        setCurrentItem(item);
        if (item.type === "page") {
            setCurrentPageId(item.item.id);
        }
        else if (item.type === "question") {
            const page = getPageByQuestionId(template.pages, item.item.id);
            page && setCurrentPageId(page.id);
        }
    };

    const addPage = (page?: Page) => {
        const newPage = page || emptyPage((pages?.length || 0) + 1);
        setTemplate({ ...template, pages: [...template?.pages, newPage] });
        onItemSelect({ type: "page", item: newPage });
    };

    const addNewQuestion = (type: string) => {
        const baseQuestion: QuestionBase = {
            id: v4(),
            title: `Frage ${(getCurrentPage()?.questions?.length || 0) + 1}`,
            description: "",
            htmlDescription: "",
            required: false,
            mappingField: ""
        }

        const newQuestion = () => {
            switch (type) {
                case "Input": return { ...baseQuestion, type: "Input" } as InputQuestion;
                case "Textarea": return { ...baseQuestion, type: "Textarea" } as TextareaQuestion;
                case "Date": return { ...baseQuestion, type: "Date" } as DateQuestion;
                case "Number": return { ...baseQuestion, type: "Number" } as NumberQuestion;
                case "SingleSelection": return { ...baseQuestion, type: "SingleSelection" } as SingleSelectionQuestion;
                case "MultiSelection": return { ...baseQuestion, type: "MultiSelection" } as MultiSelectionQuestion;
                case "RadioGroup": return { ...baseQuestion, type: "RadioGroup" } as RadioGroupQuestion;
                case "Matrix": return { ...baseQuestion, type: "Matrix" } as MatrixQuestion;
                default: throw "not supported question type";
            }
        };

        const question = newQuestion();
        addQuestion(question);
        onItemSelect({ type: "question", item: question });
    };

    const addQuestion = (question: QuestionTypes) => {
        const page = _.cloneDeep(getCurrentPage());
        if (page) {
            page.questions.push(question);
            updatePage(page);
        }
    };

    const update = (updated: EditorItem) => {
        switch (updated.type) {
            case "page": updatePage({ ...updated.item }); break;
            case "question": updateQuestion({ ...updated.item }); break;
            case "survey": updateTemplate({ ...updated.item }); break;
        }
    };
    const updateTemplate = (template: SurveyTemplate) => {
        setTemplate(template);
        setCurrentItem({ type: "survey", item: template });
    };
    const updateMailSettings = (mailSettings: MailSettings) => {
        updateTemplate({ ...template, mailSettings: mailSettings });
    };
    const updateReminderMailSettings = (reminderMailSettings: MailSettings) => {
        updateTemplate({ ...template, reminderMailSettings: reminderMailSettings });
    };
    const updatePages = (pages: Page[]) => {
        setTemplate({ ...template, pages: pages });
    };
    const updatePage = (page: Page) => {
        setTemplate({
            ...template,
            pages: template.pages.map((p) => p.id === page.id ? page : p)
        });
        setCurrentItem({ type: "page", item: page });
    };
    const updateQuestion = (question: QuestionTypes) => {
        const page = getCurrentPage();
        if (page) {
            updatePage({ ...page, questions: page.questions.map(q => q.id === question.id ? question : q) });
        }
        setCurrentItem({ type: "question", item: question });
    };

    const deletePage = (id: string) => {
        const templateCopy = _.cloneDeep(template);
        templateCopy.pages = template.pages.filter(p => p.id !== id);
        setTemplate(templateCopy);
        onItemSelect({ type: "survey", item: templateCopy });
        setCurrentPageId(surveyTemplate?.pages[0]?.id);
    };
    const deleteQuestion = (question: QuestionTypes) => {
        const page = _.cloneDeep(getCurrentPage());
        if (page) {
            page.questions = page.questions.filter(q => q.id !== question.id);
            updatePage(page);
            onItemSelect({ type: "page", item: page });
        }
    };

    const isNewTemplate = template.id === "";
    const canEditTemplate = userClaims.hasPermissionClaim("CanEditTemplates", template.id) || userClaims.isAdministrator() || isNewTemplate;
    return (<>
        {openPreview ?
            <SurveyPreview surveyOrTemplate={template} onClose={() => setOpenPreview(false)} />
            :
            <Layout >
                <HtcPageHeader
                    title={<Input
                        key={"Templatetitle"}
                        className={styles.templatetitle}
                        placeholder="Neue Vorlage"
                        value={templateTitle}
                        onChange={(e) => setTemplateTitle(e.target.value)}
                        onBlur={(e) => updateTemplate({ ...template, title: e.target.value })}
                        onFocus={() => onItemSelect({ type: "survey", item: template })}
                    />}
                    subTitle={
                        !canEditTemplate &&
                        <Tag style={{ marginTop: '0.5rem' }} icon={<ExclamationCircleOutlined />} color="warning">
                            Sie haben nur Leseberechtigung
                        </Tag>
                    }
                >
                    <CommandBar>
                        <Button icon={<EyeOutlined />} onClick={() => { setOpenPreview(true) }}>Vorschau</Button>
                        <Button type="primary" danger icon={<CloseOutlined />} onClick={onCancel}>Abbrechen</Button>
                        <Button disabled={!canEditTemplate} icon={<SaveOutlined />} onClick={onSave} type="primary">Speichern</Button>
                    </CommandBar>

                </HtcPageHeader>
                <Layout>
                    <Sider className={styles.toolbox} theme="light">
                        <ToolboxPane toolboxRef={toolboxRef} onAddQuestion={addNewQuestion} />
                    </Sider>
                    <Content className={styles.content}>
                        <Workbench
                            currentPageId={currentPageId}
                            pages={pages}
                            optionRef={optionRef}
                            subquestionRef={subquestionRef}
                            optionsRef={optionsRef}
                            subquestionsRef={subquestionsRef}
                            focusId={focusId || ""}
                            focusType={focusType}
                            onNavigatePage={navigateToPage}
                            onItemSelect={onItemSelect}
                            onAddPage={addPage}
                            onUpdatePage={updatePage}
                            onDeletePage={deletePage}
                            onUpdateQuestion={updateQuestion}
                            onDeleteQuestion={deleteQuestion}
                            onUpdatePages={updatePages}
                        />
                    </Content>
                    <Sider className={styles.settings} theme="light">
                        <PropertiesPane
                            currentItem={currentItem}
                            mappingDefinitions={mappingDefintions}
                            surveyTemplate={template}
                            focusId={focusId || ""}
                            focusType={focusType}
                            focusChange={focusChange}
                            titleRef={titleRef}
                            descriptionRef={descriptionRef}
                            mailSenderRef={mailSenderRef}
                            subjectRef={subjectRef}
                            bodyRef={bodyRef}
                            mappingRef={mappingRef}
                            mappingValueRef={mappingValueRef}
                            onItemSelect={onItemSelect}
                            onUpdateMailSettings={updateMailSettings}
                            onUpdateReminderMailSettings={updateReminderMailSettings}
                            onUpdateQuestion={updateQuestion}
                            onUpdateItem={update}
                        />
                    </Sider>
                    {validationData && showValidationData &&
                        <Sider className={styles.validationErrors} theme="light">
                            <ValidationSummary
                                validationData={validationData}
                                onClose={() => setShowValidationData(false)}
                                onFocusItem={focusItem}
                            />
                        </Sider>}
                </Layout>
                <HtcConfirm
                    title="Beim Speichern der Vorlage sind Warnungen aufgetreten. Wollen Sie trotzdem speichern?"
                    onOk={onSave}
                    onCancel={() => setOpenWarnings(false)}
                    visible={openWarnings}
                />
                <Prompt message={(location, action) => {
                    return action === "POP"
                        ? ABORT_MESSAGE
                        : true;
                }} />
            </Layout>}
    </>);
}

function emptyTemplate(): SurveyTemplate {
    return ({
        id: "",
        title: "Neue Vorlage",
        description: "",
        closingWords: "",
        pages: [emptyPage()],
        allowMultiMapping: false,
        mappingDefinition: "",
        mailSettings: {
            subject: "",
            body: ""
        },
        reminderMailSettings: {
            subject: "",
            body: ""
        },
        isAnonymous: false,
        useAutoMailSend: false,
        mailSender: "",
        enableLastTokenAnswerTransfer: false
    });
}

function emptyPage(pageNr?: number): Page {
    return ({
        id: v4(),
        title: `Seite ${pageNr || 1}`,
        questions: []
    });
}

interface SurveyTemplateEditorProps {
    surveyTemplate: SurveyTemplate,
}
