import React, { FC, useEffect, useState } from "react";
import { SurveyDetails } from "./SurveyDetails/SurveyDetails";
import { SurveyStatistics } from "./SurveyStatistics/SurveyStatistics";
import { Layout, notification } from "antd";
import { useParams } from "react-router";
import { HtcCard } from "../../shared/components/HtcCard/HtcCard";
import HtcSpin from "../../shared/components/HtcSpin/HtcSpin";
import surveyService, { UpdateSurveyMetadataCommand } from "../../shared/services/surveyService";
import surveyTokenService from "../../shared/services/surveyTokenService";
import { SurveyReceiver, SurveyToken } from "../../models/SurveyTokens";
import { Store } from "antd/lib/form/interface";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import { GenerateTokenModal } from "./SurveyTokensOverview/components/GenerateTokenModal";
import SurveyPreview from "./SurveyPreview/SurveyPreview";
import { useData } from "../../shared/hooks/useData";
import styles from "./Survey.module.css";
import { userService } from "../../shared/services/userService";
import { SurveyHeader } from "./SurveyHeader/SurveyHeader";
import { SurveyTokensOverview } from "./SurveyTokensOverview/SurveyTokenOverview";
import { SurveyReceiversManager } from "./SurveyReceiversManager/SurveyReceiversManager";
import moment from "moment";
import { SurveyVm } from "../../models/SurveyVm";
const Content = Layout;

export const Survey: FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data: value, loading, triggerReload } = useData("", async () => {
        const survey = await surveyService.getSurveyById(id);
        const tokens = await surveyService.getTokenOverview(survey.id);
        return { survey, tokens };
    });

    const [data, setData] = useState(value);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [receiversManagerOpen, setReceiversManagerOpen] = useState(false);
    const [generateTokenModalOpen, setGenerateTokenModalOpen] = useState(false);
    const [shouldSendInvitation, setShouldSendInvitation] = useState(false);
    const userClaims = userService.getUserClaimsObject();

    useEffect(() => setData(value), [value]);

    const openPreview = () => setPreviewOpen(true);
    const closePreview = () => setPreviewOpen(false);
    const openGenerateTokenModal = () => setGenerateTokenModalOpen(true);
    const closeGenerateTokenModal = () => setGenerateTokenModalOpen(false);
    const openReceiversManager = (isInvitationMode: boolean) => {
        setShouldSendInvitation(isInvitationMode);
        setReceiversManagerOpen(true);
    }
    const closeReceiversManager = () => {
        setShouldSendInvitation(false);
        setReceiversManagerOpen(false);
    }

    const updateSurvey = async (command: UpdateSurveyMetadataCommand) => {
        await surveyService.updateSurveyMetadata(command);
        triggerReload();
    }

    const createTokens = async (values: Store) => {
        const response = await surveyService.generateSurveyToken(id, values.tokens);
        if (data) {
            setData({ ...data, tokens: [...data.tokens.concat(response.tokens)] });
        }

        closeGenerateTokenModal();
    };

    const deleteToken = async (tokenId: string) => {
        const response = await surveyTokenService.deleteToken(tokenId);
        if (data) {
            const updatedTokens = data.tokens.filter(token => token.id !== tokenId);
            setData({ ...data, tokens: updatedTokens });
        }
    }

    const deleteTokens = async () => {
        if (!confirm("Wollen Sie die bestehenden Tokens wirklich löschen?")) {
            return;
        }

        const response = await surveyService.deleteSurveyTokens(id);
        if (data) {
            setData({ ...data, tokens: [] });
        }
    }

    const createReceiver = async (receiver: SurveyReceiver) => {
        const surveyToken = await surveyService.createTokensByReceivers({ surveyId: id, receivers: [receiver]});
        if(data){
            setData({...data, tokens: [...data?.tokens, ...surveyToken]});
        }        
    }

    const createReceivers = async (receivers: SurveyReceiver[]) => {
        const surveyTokens = await surveyService.createTokensByReceivers({ surveyId: id, receivers });

        if (data) {
            setData({ ...data, tokens: [...data.tokens.concat(surveyTokens)] });
        }

        if (shouldSendInvitation)
            await sendInvitationMail(surveyTokens);
        else
            notification.success({ message: "Die Empfänger wurde erfolgreich angelegt!" });

        closeReceiversManager();
    }

    const sendInvitationMail = async (surveyTokens: SurveyToken[]) => {
        const mailData = surveyTokens.map(token => ({ ...token, surveyTokenId: token.id }));
        const sendResult = await surveyService.sendInvitationMail(id, mailData);
        notification.success({ message: "Die Umfrage wurde erfolgreich versendet!" });
        closeReceiversManager();
    }

    if (loading || !data) {
        return <HtcSpin />
    }

    const hasEditAccess = userClaims.hasPermissionClaim("CanEditSurveys", id) || userClaims.isAdministrator();

    return (
        <Layout>
            {previewOpen &&
                <SurveyPreview
                    surveyOrTemplate={data.survey}
                    onClose={closePreview}
                />
            }
            {!previewOpen &&
                <Layout className={styles.layout}>
                    <SurveyHeader
                        survey={data.survey}
                        hasEditAccess={hasEditAccess}
                        onPreviewClick={openPreview}
                        onSendSurveyClick={() => openReceiversManager(true)}
                    />
                    <Content className={styles.content}>
                        <div className={styles.flexContainer}>
                            <HtcCard>
                                <SurveyDetails
                                    survey={data.survey}
                                    hasEditAccess={hasEditAccess}
                                    onSave={updateSurvey}
                                />
                            </HtcCard>
                            <HtcCard>
                                <SurveyStatistics survey={data.survey} />
                            </HtcCard>
                        </div>
                        <div>
                            <HtcCard>
                                <SurveyTokensOverview
                                    survey={data.survey}
                                    tokens={data.tokens}
                                    hasEditAccess={hasEditAccess}
                                    onCreateTokens={openGenerateTokenModal}
                                    onCreateReceivers={() => openReceiversManager(false)}
                                    onDeleteToken={deleteToken}
                                    onDeleteTokens={deleteTokens}
                                />
                            </HtcCard>
                        </div>
                        <SurveyReceiversManager
                            visible={receiversManagerOpen}
                            survey={data.survey}
                            shouldSendInvitation={shouldSendInvitation}
                            surveyTokens={data.tokens}
                            onCreateReceivers={createReceivers}
                            onInviteReceivers={sendInvitationMail}
                            onAddReceiverAndCreateToken={createReceiver}
                            onCancel={closeReceiversManager}
                        />
                        <GenerateTokenModal
                            surveyId={data.survey.id}
                            visible={generateTokenModalOpen}
                            onCancel={closeGenerateTokenModal}
                            onSubmit={createTokens}
                        />
                    </Content>
                </Layout>
            }
        </Layout>
    );
};