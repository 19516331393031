import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter as Router } from "react-router-dom";
import TokenAutoProvisioning from "./tokens/TokenAutoProvisioning";
import Surveys from "./surveys/Surveys";
import { AdministrationNavigation } from './administration/AdministrationNavigation';

const App: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/surveys/autoprovisioning/:surveyId" component={TokenAutoProvisioning} />
        <Route path="/surveys/:token" component={Surveys.Surveys} />
        <Route path="/surveys" component={Surveys.Surveys} />
        <Route path="/administration" component={AdministrationNavigation} />
        <Redirect exact from="/" to="surveys" />
      </Switch>
    </Router>
  );
};

export default App;