import { Avatar, Divider, Form, Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { IUserInfo } from "../../../../models/IUserInfo";
import { CommandFooter, Email, PersonalData, UserName, Claims } from "./FormItems";
import { Store } from "antd/lib/form/interface";
import { useForm } from "antd/lib/form/Form";
import { UserOutlined } from "@ant-design/icons";
import { AdministratorRole, CanAddTemplatePermission, UserClaims } from "../../../../models/Claim";

export const EditUserForm: FC<EditUserFormProps> = ({ user, onSave, onClose }) => {
    const [form] = useForm();
    const [userClaims, setUserClaims] = useState<UserClaims>(new UserClaims(user));

    useEffect(() => {
        if (!user) {
            return;
        }

        const tmpUserclaims = new UserClaims(user);
        form.setFieldsValue({
            ...user,
            isAdmin: tmpUserclaims.isAdministrator(),
            canAddTemplates: tmpUserclaims.hasPermissionClaim("CanAddTemplates")
        });

        setUserClaims(tmpUserclaims);
    }, [user])

    const formItemLayout = {
        labelCol: {
            xs: { span: 20 },
            sm: { span: 10 },
        },
    };

    const onFinish = (values: Store) => {
        updateClaims(values.isAdmin, values.canAddTemplates);
        const updatedUser = { ...values, id: user.id, claims: userClaims.getRawClaims() } as IUserInfo;
        onSave(updatedUser);
    };

    const updateClaims = (isAdmin: boolean, canAddTemplates: boolean) => {
        userClaims.removeRoleClaim(AdministratorRole);
        if (isAdmin) {
            userClaims.addRoleClaim(AdministratorRole);
        };
        
        userClaims.removePermissionClaim(CanAddTemplatePermission);
        if (canAddTemplates) {
            userClaims.addPermissionClaim(CanAddTemplatePermission);
        };
    };

    return (
        <Modal
            visible={true}
            title={<Space><Avatar icon={<UserOutlined />} /> {user?.userName} bearbeiten</Space>}
            footer={null}
            closable={false}
        >
            <Form form={form} labelAlign="left" {...formItemLayout} onFinish={onFinish}>
                <UserName readonly={true} />
                <Email />
                <Divider />
                <PersonalData />
                <Divider />
                <Claims
                    form={form}
                    isdefaultadmin={user.userName === "admin"}
                />
                <Divider />
                <CommandFooter onClose={onClose} />
            </Form>
        </Modal>
    );
}

interface EditUserFormProps {
    user: IUserInfo;
    onSave: (user: IUserInfo) => void;
    onClose: () => void;
}