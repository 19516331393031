import { FC } from "react";
import { SurveyTemplate } from "../../../models/SurveyTemplate";
import { Button, DatePicker, Form, Input, Modal, Select, Space } from "antd";
import React from "react";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { CreateSurveyFromTemplateCommand } from "../../../shared/services/surveyService";
import styles from "./NewSurveyModal.module.css";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";

export const NewSurveyModal: FC<NewSurveyModalProps> = ({ templates, visible, onCancel, onSave }) => {
    return (
        <Modal title="Neue Umfrage erstellen" visible={visible} onCancel={onCancel} footer={null}>
            <NewSurveyForm
                templates={templates}
                onNewSurveyFormFinish={onSave}
                onNewSurveyFromCancel={onCancel}
            />
        </Modal>
    );
}

export const NewSurveyForm: FC<NewSurveyFormProps> = ({ templates, onNewSurveyFromCancel, onNewSurveyFormFinish }) => {
    const [form] = useForm();

    const templateOptions = templates?.map(template => ({
        label: template.title,
        value: template.id
    }));

    const onFinish = (values: Store) => {
        onNewSurveyFormFinish({
            surveyTemplateId: values.template,
            validFrom: values.validFrom,
            validTo: values.validTo
        })
    }

    const dateFormat = 'DD.MM.YYYY';
    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="template" label="Vorlage" rules={[{ required: true, message: "Vorlage ist ein Pflichtfeld" }]}>
                <Select options={templateOptions} className={styles.formInput} />
            </Form.Item>
            <Form.Item name="validFrom" label="Gültig von" rules={[{ required: true, message: "Gültig von ist ein Pflichtfeld" }]}>
                <DatePicker format={dateFormat} className={styles.formInput} />
            </Form.Item>
            <Form.Item name="validTo" label="Gültig bis" rules={[{ required: true, message: "Gültig bis ist ein Pflichtfeld" }]}>
                <DatePicker format={dateFormat} className={styles.formInput} />
            </Form.Item>
            <Form.Item>
                <div style={{ textAlign: "right" }}>
                    <Space>
                        <Button htmlType="button" icon={<CloseOutlined />} type="primary" danger onClick={onNewSurveyFromCancel}>Abbrechen</Button>
                        <Button type="primary" icon={<SaveOutlined />} htmlType="submit">Speichern</Button>
                    </Space>
                </div>
            </Form.Item>
        </Form>
    );
};


interface NewSurveyModalProps {
    templates: SurveyTemplate[];
    visible: boolean;
    onSave: (command: CreateSurveyFromTemplateCommand) => void;
    onCancel: () => void;
}

interface NewSurveyFormProps {
    templates: SurveyTemplate[];
    onNewSurveyFromCancel: () => void;
    onNewSurveyFormFinish: (command: CreateSurveyFromTemplateCommand) => void;
}