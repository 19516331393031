import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons"
import styles from "./HtcSpin.module.css";
import { SpinProps } from "antd/lib/spin";

const HtcSpin: FC<IHtcSpinProps> = (props) => {
    return (
        <Spin
            className={styles.spin}
            {...props}
            indicator={<LoadingOutlined />}
        />
    );
}

export default HtcSpin;

interface IHtcSpinProps extends SpinProps {
}