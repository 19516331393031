import { Button, Layout, Space, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import HtcPageHeader from "../../shared/components/HtcPageHeader/HtcPageHeader";
import { SurveyList } from "./SurveyList/SurveyList";
import surveyService, { CreateSurveyFromTemplateCommand } from "../../shared/services/surveyService";
import { useData } from "../../shared/hooks/useData";
import CommandBar from "../../shared/components/CommandBar/CommandBar";
import { PlusOutlined } from "@ant-design/icons";
import { NewSurveyModal } from "./NewSurveyModal/NewSurveyModal";
import templateService from "../../shared/services/templateService";
import { useHistory } from "react-router";
import { SurveyListItem } from "../../models/SurveyVm";
import { HtcCard } from "../../shared/components/HtcCard/HtcCard";
import { IFilterConfig, SurveyListFilter, defaultFilterConfig } from "./SurveyList/SurveyListFilter";
import { useLocalStorage } from "react-use";
import styles from "./Surveys.module.css";
import { SurveyPermissionClaims } from "./SurveyPermissionClaims/SurveyPermissionClaims";
import { userService } from "../../shared/services/userService";
import { CanEditSurveysClaim } from "../../models/Claim";
const Content = Layout;

export const Surveys: FC = () => {
    const { data, loading, triggerReload } = useData("", async () => {
        let surveysPromise = surveyService.getSurveyList(filterConfig);
        let templatesPromise = templateService.getTemplates();
        const surveys = await surveysPromise;
        const templates = await templatesPromise;
        return { surveys, templates };
    });

    const [filterConfig, setFilterConfig] = useLocalStorage<IFilterConfig>("SURVEY_FILTER_CONFIG", defaultFilterConfig);
    const [templates, setTemplates] = useState(data?.templates || []);
    const [surveys, setSurveys] = useState(data?.surveys || []);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [selectedSurveyForPermissions, setSelectedSurveyForPermissions] = useState<SurveyListItem>();
    const history = useHistory();

    useEffect(() => {
        setSurveys(data?.surveys || [])
        setTemplates(data?.templates || [])
    }, [data]);
    useEffect(() => triggerReload(), [filterConfig]);

    const onNewSurvey = () => {
        setNewModalOpen(true);
    };

    const onNewSurveyCancel = () => {
        setNewModalOpen(false);
    }

    const onCreateNewSurveyFromTemplate = async (command: CreateSurveyFromTemplateCommand) => {
        const surveyId = await surveyService.createSurveyFromTemplate(command);
        var userClaims = userService.getUserClaimsObject();
        userClaims.addPermissionClaim({ type: "Permission", value: "CanEditSurveys", displaytext: "Schreiben", id: surveyId } as CanEditSurveysClaim);
        userService.setUserClaimsObject(userClaims);
        notification.success({ message: "Die Umfrage wurde erfolgreich erstellt" });
        setNewModalOpen(false);
        triggerReload();
    }

    const deleteSurvey = async (survey: SurveyListItem) => {
        if (confirm("Wollen Sie die Umfrage inklusiver aller Tokens und Antworten wirklich löschen?")) {
            await surveyService.deleteSurvey(survey.id);
            triggerReload();
        }
    };

    const onOpenDetails = (survey: SurveyListItem) => {
        history.push(`/administration/surveys/${survey.id}`);
    };

    const onClosePermissions = () => setSelectedSurveyForPermissions(undefined);

    return (
        <Layout className={styles.layout}>
            <HtcPageHeader title={<Space>Umfragen verwalten</Space>}>
                <CommandBar >
                    <SurveyListFilter
                        filterConfig={filterConfig}
                        onChange={setFilterConfig}
                    />
                    <Button onClick={() => onNewSurvey()} type="primary" icon={<PlusOutlined />}>Neue Umfrage</Button>
                </CommandBar>
            </HtcPageHeader>
            <Content>
                <HtcCard>
                    <SurveyList
                        loading={loading}
                        surveys={surveys}
                        onOpenDetails={onOpenDetails}
                        onDelete={deleteSurvey}
                        onOpenPermissions={setSelectedSurveyForPermissions}
                    />
                    <NewSurveyModal
                        visible={newModalOpen}
                        templates={templates}
                        onSave={onCreateNewSurveyFromTemplate}
                        onCancel={onNewSurveyCancel}
                    />
                    {
                        selectedSurveyForPermissions &&
                        <SurveyPermissionClaims
                            survey={selectedSurveyForPermissions}
                            onClose={onClosePermissions}
                        />
                    }
                </HtcCard>
            </Content>
        </Layout>
    );
};