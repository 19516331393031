import { Alert, Modal, notification } from "antd";
import React, { FC, useState } from "react";
import { SurveyReceiver, SurveyToken } from "../../../models/SurveyTokens";
import { SurveyVm } from "../../../models/SurveyVm";
import CommandBar from "../../../shared/components/CommandBar/CommandBar";
import { ReceiversListDownload, ReceiversListUpload, ReceiversList } from "./components/ReceiversList";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import surveyService from "../../../shared/services/surveyService";
import { TokenSelectionList } from "./components/TokenSelectionList";

export const SurveyReceiversManager: FC<SurveyReceiversManagerProps> = ({
    visible,
    survey,
    shouldSendInvitation,
    surveyTokens,
    onCancel,
    onCreateReceivers,
    onInviteReceivers,
    onAddReceiverAndCreateToken
}) => {
    const [receivers, setReceivers] = useState<SurveyReceiver[]>([]);
    const [selectedTokens, setSelectedTokens] = useState<SurveyToken[]>([]);
    const onSelectionChange = (newSelectedRowKeys: React.Key[], selectedRows: SurveyToken[]) => {
        setSelectedTokens(selectedRows);
    }

    const mainTitle = shouldSendInvitation ? "Umfrage senden" : "Empfänger anlegen";
    const invalidMailsetting = survey.mailSettings.subject === "" || survey.mailSettings.body === "" || survey.mailSettings.body === "<p><br></p>";
    const downloadReceivers = () => surveyService.downloadSurveyReceiverExcelFile(survey.id);
    const uploadReceivers = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, file, onProgress } = options;

        try {
            const uploadedReceivers = await surveyService.uploadReceiversAndGet(survey.id, file, onProgress);
            onSuccess([], file);
            setReceivers([...receivers, ...uploadedReceivers]);
        } catch (error) {
            onError(new Error("Error on upload Recivers."));
        }
    }

    const addReceiver = (receiverMetadata: SurveyReceiver) => {
        setReceivers([...receivers, { metadata: receiverMetadata }])
    }

    const onOk = () => {
        if (shouldSendInvitation && ((receivers.length <= 0 && survey.isAnonymous) || (selectedTokens.length <= 0 && !survey.isAnonymous))) {
            notification.error({ message: "Die Empfängerliste ist leer. Sie können die Mail nicht versenden." });
            return;
        }

        if (!survey.isAnonymous && shouldSendInvitation) {
            onInviteReceivers(selectedTokens);
        } else {
            onCreateReceivers([...receivers]);
        }

        setReceivers([]);
        setSelectedTokens([]);
    }

    return (
        <Modal
            title={<>
                <CommandBar title={mainTitle}>
                    <ReceiversListDownload onDownloadExcel={downloadReceivers} />
                    <ReceiversListUpload onUploadExcel={uploadReceivers} />
                </CommandBar>
            </>
            }
            width={800}
            closable={false}
            visible={visible}
            onCancel={onCancel}
            okText={mainTitle}
            onOk={onOk}
            okButtonProps={{ disabled: invalidMailsetting && shouldSendInvitation }}
        >
            {invalidMailsetting && shouldSendInvitation &&
                <Alert
                    style={{ marginBottom: "0.5rem" }}
                    message="Der Betreff und/oder Inhalt der Mail ist leer. Sie können die Mail nicht versenden."
                    type="error"
                    showIcon
                    banner
                />
            }
            {
                !survey.isAnonymous && shouldSendInvitation ?
                    <TokenSelectionList
                        metadataColumns={survey.metadataColumns}
                        surveyTokens={surveyTokens}
                        selectedTokenIds={selectedTokens.map(token => token.id)}
                        onSelectionChange={onSelectionChange}
                        onAddReceiver={onAddReceiverAndCreateToken}
                    /> :
                    <ReceiversList
                        metadataColumns={survey.metadataColumns}
                        receivers={receivers}
                        onAddReceiver={addReceiver}
                    />
            }
        </Modal>
    );

}


interface SurveyReceiversManagerProps {
    visible: boolean;
    survey: SurveyVm;
    shouldSendInvitation: boolean;
    surveyTokens: SurveyToken[];
    onCreateReceivers: (receivers: SurveyReceiver[]) => void;
    onInviteReceivers: (receivers: SurveyToken[]) => void;
    onAddReceiverAndCreateToken: (receiver: SurveyReceiver) => void;
    onCancel: () => void;
}