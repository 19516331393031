import httpClient from "./httpClient";
import { Placeholder, SurveyTemplate } from "../../models/SurveyTemplate";

const getTemplates = async (): Promise<SurveyTemplate[]> => {
    const client = await httpClient.getInstance();
    const response = await client.get<SurveyTemplateListResponse>(`templates`);
    return response.data.surveyTemplates;
}

const getTemplate = async (id: string): Promise<SurveyTemplate> => {
    const client = await httpClient.getInstance();
    const response = await client.get<SurveyTemplateResponse>(`templates/${id}`);
    return response.data.surveyTemplate;
};

const getPlaceholders = async (): Promise<Placeholder[]> => {
    const client = await httpClient.getInstance();
    const response = await client.get<PlaceholdersResponse>(`templates/placeholders`);
    return response.data.placeholders;
};

const addTemplate = async (surveyTemplate: SurveyTemplate) : Promise<CreateOrUpdateSurveyTemplateResponse> => {
    const command: createOrUpdateSurveyTemplateCommand = {
        surveyTemplate: surveyTemplate
    };
    const client = await httpClient.getInstance();
    const response = await client.post<CreateOrUpdateSurveyTemplateResponse>("templates", command);
    return response.data;
};

const updateTemplate = async (surveyTemplate: SurveyTemplate) : Promise<CreateOrUpdateSurveyTemplateResponse> => {
    const command: createOrUpdateSurveyTemplateCommand = {
        surveyTemplate: surveyTemplate
    };
    const client = await httpClient.getInstance();
    const response = await client.post<CreateOrUpdateSurveyTemplateResponse>(`templates/${surveyTemplate.id}`, command);
    return response.data;
}

const deleteTemplate = async (id: string) => {
    const client = await httpClient.getInstance();
    const response = await client.delete(`templates/${id}`);    
    return response.data;
}

export default { getTemplates, getTemplate, getPlaceholders, addTemplate, updateTemplate, deleteTemplate};

interface SurveyTemplateResponse {
    surveyTemplate: SurveyTemplate;
}

interface SurveyTemplateListResponse {
    surveyTemplates: SurveyTemplate[];
}

interface createOrUpdateSurveyTemplateCommand {
    surveyTemplate: SurveyTemplate;
}

interface CreateOrUpdateSurveyTemplateResponse {
    surveyTemplateId: string;
}

interface PlaceholdersResponse {
    placeholders: Placeholder[];
}