import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { AutoComplete, Button, Input, Space } from "antd";
import { MailSettings, Placeholder } from "../../../../../models/SurveyTemplate";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./MailProperties.module.css";
import _ from "lodash";
import { settingsService } from "../../../../../shared/services/settingsService";
import { useAsync } from "react-use";
import templateService from "../../../../../shared/services/templateService";
import RichText from "../../../../../shared/components/RichText/RichText";
import ReactQuill from "react-quill";

const MailProperties: FC<MailPropertiesProps> = ({ mailSettings, subjectRef, bodyRef, isAnonymous, onUpdateMailSettings }) => {
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [placeholderPosition, setPlaceholderPosition] = useState<{ element: "subject" | "body", position: number }>({
        element: "body",
        position: body.length
    });

    useEffect(() => setSubject(mailSettings?.subject || ""), [mailSettings?.subject]);
    useEffect(() => setBody(mailSettings?.body || ""), [mailSettings?.body]);
    useEffect(() => {
        bodyRef?.current?.getEditor().root.addEventListener('blur', function () {
            onBlurBody();
        });
    }, [bodyRef, mailSettings]);

    const onBlurBody = () => {
        const editor = bodyRef?.current?.getEditor()
        if (editor) {
            const selection = editor.getSelection();
            setBodyPlaceholderIndex(selection ? selection.index : body.length);
            updateBody(editor.root.innerHTML);
        }
    }

    const updateSubject = (value: string) => onUpdateMailSettings({ ...mailSettings, subject: value });
    const setSubjectPlaceholderIndex = (index: number) => setPlaceholderPosition({ element: "subject", position: index });
    const updateBody = (value: string) => onUpdateMailSettings({ ...mailSettings, body: value });
    const setBodyPlaceholderIndex = (index: number) => setPlaceholderPosition({ element: "body", position: index });

    const addPlaceholder = (placeholder: string) => {
        const { element, position } = placeholderPosition;
        if (element === "subject") {
            onUpdateMailSettings({ ...mailSettings, subject: insertAt(subject, placeholder, position) });
            return;
        }

        const editor = bodyRef?.current?.getEditor();
        if (editor) {
            editor.insertText(position, `[${placeholder}]`);
            onUpdateMailSettings({ ...mailSettings, body: editor.root.innerHTML });
        }
    };

    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <MailSubjectField
                subject={subject}
                subjectRef={subjectRef}
                onChange={setSubject}
                onUpdate={updateSubject}
                onPlaceholderIndexChange={setSubjectPlaceholderIndex}
            />
            <MailBodyField
                body={body}
                bodyRef={bodyRef}
                onChange={setBody}
            />
            <MailPlaceholders
                isAnonymous={isAnonymous}
                onAddPlaceholder={addPlaceholder}
            />
        </Space>
    );
}

export default MailProperties;

interface MailPropertiesProps {
    mailSettings: MailSettings;
    subjectRef?: MutableRefObject<Input | null>;
    bodyRef?: MutableRefObject<ReactQuill | null>;
    isAnonymous: boolean;
    onUpdateMailSettings: (mailSettings: MailSettings) => void;
}

export const MailSubjectField: FC<MailSubjectFieldProps> = ({ subject, subjectRef, onChange, onUpdate, onPlaceholderIndexChange }) => {
    const onBlurSubject = (e: React.FocusEvent<HTMLInputElement>) => {
        onPlaceholderIndexChange(e.target.selectionStart !== null ? e.target.selectionStart : subject.length);
        onUpdate(e.target.value);
    }

    return (
        <Input
            ref={subjectRef ? e => subjectRef.current = e : undefined}
            placeholder="Betreff"
            value={subject}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlurSubject(e)}
        />
    );
}

interface MailSubjectFieldProps {
    subject: string;
    subjectRef?: MutableRefObject<Input | null>;
    onChange: (subject: string) => void;
    onUpdate: (subject: string) => void;
    onPlaceholderIndexChange: (index: number) => void;
}

export const MailBodyField: FC<MailBodyFieldProps> = ({ body, bodyRef, onChange }) => {
    return (
        <RichText
            className={styles.mailbody}
            quillRef={bodyRef}
            placeholder="Inhalt"
            value={body}
            onChange={onChange}
        />
    );
}

interface MailBodyFieldProps {
    body: string;
    bodyRef?: MutableRefObject<ReactQuill | null>;
    onChange: (body: string) => void;
}

export const MailPlaceholders: FC<MailPlaceholdersProps> = ({ isAnonymous, onAddPlaceholder }) => {
    const appConfig = settingsService.getConfig();
    const { value } = useAsync(() => templateService.getPlaceholders());
    const [placeholders, setPlaceholders] = useState<Placeholder[]>([]);
    const [placeholder, setPlaceholder] = useState<string>("");

    useEffect(() => {
        setPlaceholders(value?.filter(v => (v.onlyNonAnonymous === false || isAnonymous === false) && (v.isRios === false || v.isRios === appConfig.enableRiosConnection)) || [])
    }, [value, isAnonymous]);

    const getOptions = () => {
        const options = placeholders.map(p => ({ label: p.description, value: p.name }));
        return _.orderBy(options, opt => opt.label.toLowerCase());
    };

    const addPlaceholder = () => onAddPlaceholder(placeholder);

    return (
        <div className={styles.placeholder}>
            <AutoComplete
                size="small"
                options={getOptions()}
                className={styles.placeholderselect}
                value={placeholders.find(p => p.name === placeholder)?.description || placeholder}
                onSelect={setPlaceholder}
                onSearch={setPlaceholder}
            />
            <Button size="small"
                type="primary"
                icon={<PlusOutlined />}
                onClick={addPlaceholder}>
                Platzhalter hinzufügen
            </Button>
        </div>
    );
}

interface MailPlaceholdersProps {
    isAnonymous: boolean;
    onAddPlaceholder: (placeholder: string) => void;
}

export function insertAt(text: string, value: string, pos: number) {
    return value.length > 0 ? [pos > 0 ? text.slice(0, pos) : '', `[${value}]`, text.slice(pos)].join('') : text;
}