import { Avatar, Button, Modal, Space, Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { LayoutOutlined, ContactsOutlined } from "@ant-design/icons";
import { IUserInfo } from "../../../../models/IUserInfo";
import { useData } from "../../../../shared/hooks/useData";
import templateService from "../../../../shared/services/templateService";
import surveyService from "../../../../shared/services/surveyService";
import { SurveyListFilter, defaultFilterConfig } from "../../../surveys/SurveyList/SurveyListFilter";
import { userService } from "../../../../shared/services/userService";
import HtcSpin from "../../../../shared/components/HtcSpin/HtcSpin";
import { SurveyTemplate } from "../../../../models/SurveyTemplate";
import { SurveyListItem } from "../../../../models/SurveyVm";
import { PermissionClaim, SurveyClaimTypes, TemplateClaimTypes, UserClaims } from "../../../../models/Claim";
import { PermissionClaimList } from "./components/PermissionClaimList";
import { NewPermissionClaim } from "./components/NewPermissionClaim";
import styles from "./UserPermissionClaims.module.less";
const { TabPane } = Tabs;

export const UserPermissionClaims: FC<UserPermissionClaimsProps> = ({ visible, user, onClose }) => {
    const { data: templateData, loading: templatesLoading } = useData("", () => templateService.getTemplates());
    const { data: surveyData, loading: surveysLoading, triggerReload: reloadSurveys } = useData("", () => surveyService.getSurveyList(surveyFilter || defaultFilterConfig));
    const { data: surveyTextData, loading: surveysTextLoading, triggerReload: reloadSurveysText } = useData("", () => surveyService.getSurveyList(defaultFilterConfig, surveyPermissions.map(s => s.id || "")));
    const [userClaims, setUserClaims] = useState<UserClaims>(new UserClaims(user));
    const [selectedTab, setSelectedTab] = useState("0");
    const [surveyFilter, setSurveyFilter] = useState(defaultFilterConfig);
    const [templates, setTemplates] = useState<SurveyTemplate[]>([]);
    const [surveys, setSurveys] = useState<SurveyListItem[]>([]);
    const [templatePermissions, setTemplatePermissions] = useState<PermissionClaim[]>([]);
    const [surveyPermissions, setSurveyPermissions] = useState<PermissionClaim[]>([]);
    useEffect(() => setTemplates(templateData || []), [templateData]);
    useEffect(() => setSurveys(surveyData || []), [surveyData]);
    useEffect(() => reloadSurveys(), [surveyFilter]);
    useEffect(() => reloadSurveysText(), [surveyPermissions]);
    useEffect(() => {
        setUserClaims(new UserClaims(user));
        refreshPermissionClaims();
    }, [user]);

    const onSave = async () => {
        user.claims = userClaims.getRawClaims();
        await userService.updateUser(user);
        onClose();
    };

    const deletePermissionClaim = (claim: PermissionClaim) => {
        userClaims.removePermissionClaim(claim);
        refreshPermissionClaims();
    };

    const addPermissionClaims = (claims: PermissionClaim[]) => {
        claims.forEach(c => userClaims.addPermissionClaim(c));
        refreshPermissionClaims();
    };

    const refreshPermissionClaims = () => {
        setTemplatePermissions(userClaims.getPermissionClaims(TemplateClaimTypes.map(c => c.value)));
        setSurveyPermissions(userClaims.getPermissionClaims(SurveyClaimTypes.map(c => c.value)));
    };

    const templateoptions = templates?.map((t) => ({
        key: t.id,
        value: t.id,
        label: t.title
    }));

    const surveyoptions = surveys?.map((s) => ({
        key: s.id,
        value: s.id,
        label: s.title
    }));

    const getTemplateTitle = (id: string) => templates.find(t => t.id === id)?.title;
    const getSurveyTitle = (id: string) => surveyTextData?.find(s => s.id === id)?.title;

    const loading = surveysLoading || templatesLoading || surveysTextLoading;

    return (
        <Modal width={'60%'}
            visible={visible}
            title={
                <Space>
                    <Avatar className={styles.avatar} >{user.lastName.charAt(0)?.toUpperCase()}{user.firstName?.charAt(0)?.toUpperCase()}</Avatar>
                    {user.displayName}
                </Space>
            }
            onCancel={onClose}
            footer={
                <Space >
                    <Button type="primary" onClick={onClose} danger>Abbrechen</Button>
                    <Button type="primary" onClick={() => onSave()}>Speichern</Button>
                </Space>
            }>
            {loading ?
                <HtcSpin /> :
                <Tabs defaultActiveKey={selectedTab} onChange={setSelectedTab}>
                    <TabPane tab={<span> <LayoutOutlined /> Vorlagen</span>} key="0">
                        <NewPermissionClaim
                            title="Vorlage"
                            options={templateoptions}
                            claimoptions={TemplateClaimTypes}
                            onAdd={addPermissionClaims}
                        />
                        <PermissionClaimList
                            title="Vorlage"
                            claims={templatePermissions}
                            getPermissionClaimItemName={getTemplateTitle}
                            onDelete={deletePermissionClaim}
                        />
                    </TabPane>
                    <TabPane tab={<span> <ContactsOutlined /> Umfragen</span>} key="1">
                        <Space>
                            <SurveyListFilter
                                filterConfig={surveyFilter}
                                onChange={setSurveyFilter}
                            />
                            <NewPermissionClaim
                                title="Umfrage"
                                options={surveyoptions}
                                claimoptions={SurveyClaimTypes}
                                onAdd={addPermissionClaims}
                            />
                        </Space>
                        <PermissionClaimList
                            title="Umfrage"
                            claims={surveyPermissions}
                            getPermissionClaimItemName={getSurveyTitle}
                            onDelete={deletePermissionClaim}
                        />
                    </TabPane>
                </Tabs>
            }
        </Modal>
    );
}

export interface UserPermissionClaimsProps {
    visible: boolean;
    user: IUserInfo;
    onClose: () => void;
}
