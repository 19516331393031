import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { Tabs, Input } from "antd";
import { QuestionTypes, Page } from "../../../../models/SurveyTemplate";
import styles from "./Workbench.module.less";
import _ from "lodash";
import QuestionEditor from "./QuestionEditor/QuestionEditor";
import { EditorItem } from "../../../../models/Editor";
import { ValidationProperty } from "../../../../models/SurveyTemplateValidator";
const { TabPane } = Tabs;

const Workbench: FC<WorkbenchProps> = ({
    currentPageId,
    pages,
    optionRef,
    subquestionRef,
    optionsRef,
    subquestionsRef,
    focusId,
    focusType,
    onNavigatePage,
    onItemSelect,
    onAddPage,
    onDeletePage,
    onUpdatePage,
    onUpdateQuestion,
    onDeleteQuestion,
    onUpdatePages
}) => {
    const [currentPage, setCurrentPage] = useState(pages.find(p => p.id === currentPageId) || {} as Page);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionTypes | undefined>(undefined);
    useEffect(() => setCurrentPage(pages.find(p => p.id === currentPageId) || {} as Page), [pages, currentPageId])

    const onEdit = (targetKey: any, action: "add" | "remove") => {
        if (action === "add") onAddPage();
        else if (action === "remove") onDeletePage(targetKey);
    };

    const openQuestionPosition = (question: QuestionTypes) => setSelectedQuestion(question);
    const closeQuestionPosition = () => setSelectedQuestion(undefined);

    const moveQuestion = (pageId: string, index: number) => {
        if (!selectedQuestion) {
            closeQuestionPosition();
            return;
        }

        const oldPage = _.cloneDeep(currentPage);
        oldPage.questions.splice(currentPage.questions.findIndex(q => q.id === selectedQuestion.id), 1);
        if (oldPage.id === pageId) {
            oldPage.questions.splice(index, 0, { ...selectedQuestion, dependency: undefined });
            onUpdatePage(oldPage);
        }
        else {
            const newPages = _.cloneDeep(pages);
            newPages.splice(pages.findIndex(p => p.id === oldPage.id), 1, oldPage);

            const newPage = _.cloneDeep(pages?.find(p => p.id === pageId));
            if (newPage) {
                newPage.questions?.splice(index, 0, { ...selectedQuestion, dependency: undefined });
                newPages.splice(pages.findIndex(p => p.id === pageId), 1, newPage);
                onUpdatePages(newPages);
            }
        }

        closeQuestionPosition();
    }

    return (<>
        <Tabs
            className={styles.page}
            activeKey={currentPageId}
            onChange={(activeKey: string) => onNavigatePage(activeKey)}
            onEdit={onEdit}
            type="editable-card"
        >
            {pages?.map((page) => (
                <TabPane key={page.id} tab={page.title} closable>
                    <EditorPagePane
                        page={page}
                        currentPage={currentPage}
                        optionRef={optionRef}
                        subquestionRef={subquestionRef}
                        optionsRef={optionsRef}
                        subquestionsRef={subquestionsRef}
                        focusId={focusId}
                        focusType={focusType}
                        onItemSelect={onItemSelect}
                        onUpdatePage={onUpdatePage}
                        onUpdateQuestion={onUpdateQuestion}
                        onDeleteQuestion={onDeleteQuestion}
                        onOpenQuestionPosition={openQuestionPosition}
                    />
                </TabPane>
            ))}
        </Tabs>
        {selectedQuestion &&
            <QuestionEditor.QuestionPosition
                question={selectedQuestion}
                pages={pages}
                onMoveQuestion={moveQuestion}
                onCancel={closeQuestionPosition}
            />}
    </>);
}

const EditorPagePane: FC<PagePaneProps> = ({
    page,
    currentPage,
    optionRef,
    subquestionRef,
    optionsRef,
    subquestionsRef,
    focusId,
    focusType,
    onItemSelect,
    onUpdatePage,
    onUpdateQuestion,
    onDeleteQuestion,
    onOpenQuestionPosition
}) => {
    const [title, setTitle] = useState("");
    useEffect(() => setTitle(page.title), [page.title]);

    return (
        <div className={styles.pagecontainer}>
            <Input
                placeholder="Titel"
                className={styles.pagetitle}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => onUpdatePage({ ...currentPage, title: e.target.value })}
                onFocus={() => onItemSelect({ type: "page", item: page })}
            />
            {page.questions.map(question => (
                <QuestionEditor.QuestionEditor
                    key={question.id}
                    question={question}
                    optionRef={optionRef}
                    subquestionRef={subquestionRef}
                    optionsRef={optionsRef}
                    subquestionsRef={subquestionsRef}
                    focusId={focusId}
                    focusType={focusType}
                    onItemSelect={onItemSelect}
                    onUpdateQuestion={onUpdateQuestion}
                    onDeleteQuestion={onDeleteQuestion}
                    onOpenQuestionPosition={onOpenQuestionPosition}
                />
            ))}
        </div>
    );
};

export default Workbench;

interface WorkbenchProps {
    currentPageId: string;
    pages: Page[];
    optionRef: MutableRefObject<Input | null>;
    subquestionRef: MutableRefObject<Input | null>;
    optionsRef: MutableRefObject<HTMLElement | null>;
    subquestionsRef: MutableRefObject<HTMLElement | null>;
    focusId: string;
    focusType: ValidationProperty | undefined;
    onAddPage: (page?: Page) => void;
    onUpdatePage: (page: Page) => void;
    onDeletePage: (id: string) => void;
    onNavigatePage: (id: string) => void;
    onItemSelect: (item: EditorItem) => void;
    onUpdateQuestion: (question: QuestionTypes) => void;
    onDeleteQuestion: (question: QuestionTypes) => void;
    onUpdatePages: (pages: Page[]) => void;
}

interface PagePaneProps {
    page: Page;
    currentPage: Page;
    optionRef: MutableRefObject<Input | null>;
    subquestionRef: MutableRefObject<Input | null>;
    optionsRef: MutableRefObject<HTMLElement | null>;
    subquestionsRef: MutableRefObject<HTMLElement | null>;
    focusId: string;
    focusType: ValidationProperty | undefined;
    onItemSelect: (item: EditorItem) => void;
    onUpdatePage: (page: Page) => void;
    onUpdateQuestion: (question: QuestionTypes) => void;
    onDeleteQuestion: (question: QuestionTypes) => void;
    onOpenQuestionPosition: (question: QuestionTypes) => void;
}



