import React, { FC } from "react";
import { ColumnsType } from "antd/lib/table";
import { Button, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { PermissionClaim } from "../../../../../models/Claim";

export const PermissionClaimList: FC<PermissionClaimListProps> = ({ title, claims, getPermissionClaimItemName, onDelete }) => {
    const columns: ColumnsType<PermissionClaim> = [
        {
            title: title,
            key: "claimitemname",
            dataIndex: "id",
            render: getPermissionClaimItemName
        },
        {
            title: "Rechte",
            key: "claimdisplaytext",
            dataIndex: "displaytext"
        },
        {
            key: "action",
            render: (claim: PermissionClaim) => <Button icon={<DeleteOutlined />} title="Berechtigung löschen" onClick={() => onDelete(claim)} />
        }
    ];

    return (
        <Table
            style={{ marginTop: "15px" }}
            columns={columns}
            dataSource={claims}
            rowKey={(c) => `${c.value}.${c.id}`}
        />
    );
}

interface PermissionClaimListProps {
    title: string;
    claims: PermissionClaim[];
    getPermissionClaimItemName: (id: string) => string | undefined;
    onDelete: (claim: PermissionClaim) => void;
}