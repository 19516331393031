import { Menu, Layout, Divider, Space, Avatar, Typography, Modal } from "antd";
import React, { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LayoutOutlined, ContactsOutlined, SettingOutlined, UserOutlined, KeyOutlined } from "@ant-design/icons";
import styles from "./AppSider.module.less";
import { UserInfoContext } from "./UserInfoContext";
import { ChangePasswordForm } from "./administration/security/users/UserChangePassword/UserChangePassword";
import { UserChangePassword, IUserInfo } from "./models/IUserInfo";
import { userService } from "./shared/services/userService";
import HtcSpin from "./shared/components/HtcSpin/HtcSpin";
import OpinioLogo from "./shared/components/OpinioLogo/OpinioLogo";
const { Sider } = Layout;
const { Text } = Typography;

export const AppSider: FC = () => {
    const { userInfo } = useContext(UserInfoContext);
    const [changePasswordErros, setChangePasswordErros] = useState<string[]>([]);
    const [showWaitSpin, setshowWaitSpin] = useState(false);
    const [wrongPassword, setwrongPassword] = useState(false);
    const [changePasswordFormOpen, setchangePasswordFormOpen] = useState(false);
    const [oldInput, setOldInput] = useState<UserChangePassword>();

    const onClose = () => setchangePasswordFormOpen(false);

    const changePassword = async (userData: UserChangePassword) => {
        setOldInput(userData);
        setwrongPassword(false);
        setshowWaitSpin(true);
        const result = await userService.changePassword({ changePassword: userData });
        if (result.success) {
            setchangePasswordFormOpen(false);
            return;
        }
        if (result.errors.find(x => x === "Incorrect password."))
            setwrongPassword(true);
        else
            setChangePasswordErros(result.errors);
        setshowWaitSpin(false);

    }

    return (
        <Sider className={styles.sider}>
            <div className={styles.siderContent}>
                <SiderHeader />
                <Divider style={{ margin: "1rem 0" }} />
                <AppSiderMenu />
                <Divider style={{ margin: ".5rem 0" }} />
                <UserProfileMenu
                    userInfo={userInfo}
                    setchangePasswordFormOpen={setchangePasswordFormOpen}
                />
            </div>
            <Modal title={<Space><Avatar icon={<KeyOutlined />} />Passwort ändern</Space>}
                visible={changePasswordFormOpen}
                onCancel={onClose}
                footer={null}>
                {
                    changePasswordErros &&
                    changePasswordErros.map((x) => <><Text>{x}</Text><br /></>)
                }
                {showWaitSpin ?
                    <HtcSpin /> :
                    <ChangePasswordForm
                        userInfo={userInfo}
                        oldInput={oldInput}
                        onSave={changePassword}
                        wrongPassword={wrongPassword}
                        onCancel={() => setchangePasswordFormOpen(false)}
                    />
                }
            </Modal>
        </Sider>
    );
};

const SiderHeader: FC = () => {
    return (
        <div style={{ fontSize: "1.5rem", display: "flex", padding: "1.5rem 0 0 1rem", alignItems: "center" }}>
            <div>
                <OpinioLogo darkMode style={{ width: "150px" }} />
            </div>
        </div>
    );
}

const AppSiderMenu: FC = () => {
    const userClaims = userService.getUserClaimsObject();

    return (
        <Menu className={styles.siderAppMenu} theme="dark">
            {
                <Menu.Item icon={<LayoutOutlined style={{ fontSize: "1rem" }} />}>
                    <Link className={styles.menuItem} to="/administration/templates">Vorlagen</Link>
                </Menu.Item>
            }
            {
                <Menu.Item icon={<ContactsOutlined style={{ fontSize: "1rem" }} />}>
                    <Link className={styles.menuItem} to="/administration/surveys">Umfragen</Link>
                </Menu.Item>
            }
            {
                userClaims.isAdministrator() &&
                <Menu.Item icon={<UserOutlined />}>
                    <Link className={styles.menuItem} to="/administration/users">Benutzer</Link>
                </Menu.Item>
            }
            {
                userClaims.isAdministrator() &&
                <Menu.Item icon={<SettingOutlined />}>
                    <Link className={styles.menuItem} to="/administration/settings">Einstellungen</Link>
                </Menu.Item>
            }
        </Menu>
    );
}

const UserProfileMenu: FC<UserProfileMenuProps> = ({ userInfo, setchangePasswordFormOpen }) => {
    if (!userInfo) {
        return (
            <HtcSpin />
        );
    }

    const name = `${userInfo?.lastName?.charAt(0)?.toUpperCase()}${userInfo?.firstName?.charAt(0)?.toUpperCase()}`;

    return (
        <Menu className={styles.userProfileMenu} theme="dark">
            {userInfo &&
                <Menu.SubMenu icon={<Avatar className={styles.avatar}>{name}</Avatar>} title={<span style={{ marginLeft: "10px" }}>{userInfo.firstName} {userInfo.lastName}</span>}>
                    <Menu.Item onClick={() => setchangePasswordFormOpen(true)}>
                        <div>Passwort ändern</div>
                    </Menu.Item>
                    <Menu.Item onClick={() => userService.logout()}>
                        <div>Abmelden</div>
                    </Menu.Item>
                </Menu.SubMenu>
            }
        </Menu>
    );
}

interface UserProfileMenuProps {
    userInfo: IUserInfo;
    setchangePasswordFormOpen: (open: boolean) => void;
}