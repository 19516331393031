import React, { FC, useState } from "react";
import { Input, Radio, Table, Select, Form, DatePicker, InputNumber, Space } from "antd";
import { QuestionTypes, RadioGroupQuestion, SingleSelectionQuestion, MultiSelectionQuestion, MatrixQuestion, Subquestion, InputQuestion, DateQuestion, NumberQuestion, TextareaQuestion, Validation } from "../../models/SurveyVm";
import { RadioChangeEvent } from "antd/lib/radio";
import styles from "./Questions.module.less";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { ColumnsType } from "antd/lib/table";
import { SelectValue } from "antd/lib/select";
import { Rule } from "antd/lib/form";
import { isValidRegex } from "../../administration/templates/editor/PropertiesPane/ValidationProperties/ValidationProperties";
const { Option } = Select;

const Question: FC<QuestionProps> = ({ question, showMatrixMessage, onMatrixValueChange, onCommentValueChange }) => {
    const { title, description, htmlDescription } = question;

    const getQuestionComponent = () => {
        switch (question.type) {
            case "Input": return <InputComponent question={question} />
            case "Textarea": return <TextareaComponent question={question} />
            case "Number": return <NumberComponent question={question} />
            case "Date": return <DateComponent question={question} />
            case "RadioGroup": return <RadioGroupComponent question={question} onCommentValueChange={onCommentValueChange} />
            case "SingleSelection": return <SingleSelectionComponent question={question} />
            case "MultiSelection": return <MultiSelectionComponent question={question} />
            case "Matrix": return <MatrixComponent question={question} showMessage={showMatrixMessage} onQuestionValueChange={onMatrixValueChange} />
        }
    }

    return (
        <div>
            <div className={styles.questiontitle}>{title}</div>
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: htmlDescription }} ></div>
            <div className={styles.questiondescription}>{description}</div>
            {getQuestionComponent()}
        </div>
    );
}

interface QuestionProps {
    question: QuestionTypes;
    showMatrixMessage: boolean;
    onMatrixValueChange: (questionId: string, subquestionId: string, value: string) => void;
    onCommentValueChange: (questionId: string, value: string, comment: string) => void;
}

export default Question;

const InputComponent: FC<{ question: InputQuestion }> = ({ question }) => {
    const { id, value } = question;

    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value}
            validateTrigger="onBlur"
            rules={getQuestionRules(question)}>
            <Input />
        </Form.Item>
    );
}

const TextareaComponent: FC<{ question: TextareaQuestion }> = ({ question }) => {
    const { id, value } = question;

    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value}
            rules={getQuestionRules(question)}>
            <Input.TextArea rows={4} />
        </Form.Item>
    );
}

const DateComponent: FC<{ question: DateQuestion }> = ({ question }) => {
    const { id, value } = question;
    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value ? moment(value) : null}
            rules={getQuestionRules(question)}>
            <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
    );
}

const NumberComponent: FC<{ question: NumberQuestion }> = ({ question }) => {
    const { id, value } = question;



    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value}
            rules={getQuestionRules(question)}>
            <InputNumber style={{ width: "20%" }} />
        </Form.Item>
    );
}

const RadioGroupComponent: FC<RadioGroupComponentProps> = ({ question, onCommentValueChange }) => {
    const { id, options, value, comment } = question;

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onCommentValueChange(id, e.target.id, e.target.value);
    }

    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value}
            rules={getQuestionRules(question)}>
            <Radio.Group>
                {options?.map(option =>
                    <Radio className={styles.radio} key={option.value} value={option.value}>
                        <Space>
                            {option.text}
                            {option.withComment &&
                                <Input

                                    id={option.value}
                                    className={styles.inputcomment}
                                    value={option.value === value ? comment : option.comment}
                                    onChange={onValueChange}
                                />
                            }
                        </Space>
                    </Radio>)}
            </Radio.Group>
        </Form.Item>
    );
}

const SingleSelectionComponent: FC<{ question: SingleSelectionQuestion }> = ({ question }) => {
    const { id, options, value } = question;
    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={value}
            rules={getQuestionRules(question)}>
            <Select allowClear
                getPopupContainer={trigger => trigger.parentNode}
            >
                {options?.map(option =>
                    <Option key={option.value} value={option.value}>
                        {option.text}
                    </Option>)}
            </Select>
        </Form.Item>
    );
}

const MultiSelectionComponent: FC<{ question: MultiSelectionQuestion }> = ({ question }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { id, options, values } = question;
    return (
        <Form.Item
            key={id}
            name={id}
            initialValue={values}
            rules={getQuestionRules(question)}>
            <Select
                mode="multiple"
                allowClear
                showArrow
                showSearch={false}
                getPopupContainer={trigger => trigger.parentNode}
                open={open}
                onClick={() => setOpen(!open)}
                onBlur={() => setOpen(false)}
            >
                {options?.map(option =>
                    <Select.Option key={option.value} value={option.value} >
                        {option.text}
                    </Select.Option>)}
            </Select>
        </Form.Item>
    );
}

const MatrixComponent: FC<MatrixComponentProps> = ({ question, showMessage, onQuestionValueChange }) => {
    const { id, questions, options } = question;

    const onSelect = (value: SelectValue, subQuestionId: string) => {
        onQuestionValueChange(id, subQuestionId, value?.toString());
    };

    const onValueChange = (e: RadioChangeEvent) => {
        if (e.target.name) {
            onQuestionValueChange(id, e.target.name, e.target.value);
        }
    }

    let columns: ColumnsType<Subquestion> = [
    ];

    if (isMobile) {
        columns = [
            {
                key: "options", title: "",
                render: (row: Subquestion) => (
                    <div>
                        <p>{row.description}</p>
                        <Select
                            style={{ width: "100%" }}
                            allowClear={true}
                            onChange={value => onSelect(value, row.id)}
                            getPopupContainer={trigger => trigger.parentNode}
                        >
                            {options?.map(o => <Option key={o.value} value={o.value}>{o.text}</Option>)}
                        </Select>
                    </div>)
            }
        ];
    }
    else {
        columns = [
            { key: 'description', render: (row: Subquestion) => row.description },
            ...options?.map(o => ({
                key: o.value,
                title: o.text,
                render: (row: Subquestion) => <Radio name={row.id} value={o.value} checked={row.value === o.value} onChange={onValueChange}></Radio>
            }))
        ];
    }

    return (
        <div>
            <Table
                rowKey={(row: Subquestion) => row.id}
                columns={columns}
                dataSource={questions}
                pagination={false}
                showHeader={!isMobile}
            />
            {showMessage && <div className={styles.errormessage}>Bitte beantworten Sie alle Fragen.</div>}
        </div>
    );
}

interface RadioGroupComponentProps {
    question: RadioGroupQuestion;
    onCommentValueChange: (questionId: string, value: string, comment: string) => void;
}

interface MatrixComponentProps {
    question: MatrixQuestion;
    showMessage: boolean;
    onQuestionValueChange: (questionId: string, subquestionId: string, value: string) => void;
}

function getQuestionRules(question: QuestionTypes): Rule[] {
    const { required, validation } = question;
    const rules: Rule[] = [{ required: required }];

    if(!validation){
        return rules;
    }

    if (validation.regex && validation.regex !== "" && isValidRegex(validation.regex)) {
        rules.push({ pattern: new RegExp(validation.regex), message: validation.message });
    }

    const numberRule = getNumberRule(validation);
    if(numberRule){
        rules.push(numberRule);
    }

    return rules;
}

function getNumberRule(validation: Validation): Rule | undefined {
    if (validation.max && validation.min) {
        return { type: "number", min: validation.min, max: validation.max, message: validation.message };
    }

    if (validation.max) {
        return { type: "number", max: validation.max, message: validation.message };
    }

    if (validation.min) {
        return { type: "number", min: validation.min, message: validation.message };
    }

    return undefined;
}