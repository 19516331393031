import React, { FC } from "react";
import { QuestionTypes } from "../../models/SurveyVm";
import Question from "../Questions/Questions";
import { Divider, Form, Button } from "antd";
import styles from "./SurveyPage.module.less";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { isMobile } from "react-device-detect";
import { settingsService } from "../../shared/services/settingsService";

const SurveyPage: FC<SurveyPageProps> = (
    {
        title,
        questions,
        showMatrixMessage,
        isFirst,
        isLast,
        onNext,
        onValueChange,
        onMatrixValueChange,
        onCommentValueChange,
        onBack
    }) => {
    const [form] = useForm();
    const questionsCssClass = isMobile ? styles.surveyquestionsmobile : styles.surveyquestions;
    const appConfig = settingsService.getConfig();

    return (
        <Form
            form={form}
            onFinish={onNext}
            onValuesChange={onValueChange}
            validateTrigger="onBlur"
            validateMessages={validateMessages}
        >
            <div className={styles.surveypagetitle}>{title}</div>
            <div className={questionsCssClass}>
                {questions.map(q =>
                    <div key={q.id}>
                        <Question
                            key={q.id}
                            question={q}
                            showMatrixMessage={showMatrixMessage}
                            onMatrixValueChange={onMatrixValueChange}
                            onCommentValueChange={onCommentValueChange}
                        />
                        <Divider />
                    </div>)
                }
            </div>
            <div className={styles.surveybuttons}>
                <Button className={styles.surveybutton} onClick={onBack} disabled={isFirst}>Zurück</Button>
                <Button className={styles.surveybutton} onClick={form.submit} type="primary">{isLast ? `${appConfig.mainTitle} abschließen` : "weiter"}</Button>
            </div>
        </Form>
    );
}

export default SurveyPage;

interface SurveyPageProps {
    title: string;
    questions: QuestionTypes[];
    showMatrixMessage: boolean;
    isFirst: boolean;
    isLast: boolean;
    onValueChange: (changedValues: Store) => void;
    onMatrixValueChange: (questionId: string, subquestionId: string, value: string) => void;
    onCommentValueChange: (questionId: string, value: string, comment: string) => void;
    onNext: () => void;
    onBack: () => void;
}

const validateMessages = {
    required: "Bitte geben Sie einen Wert an.",
    number: {
        min: "Die Zahl muss größer oder gleich ${min} sein.",
        max: "Die Zahl muss kleiner oder gleich ${max} sein.",
        range: "Die Zahl muss zwischen ${min} und ${max} liegen.",
    },
    pattern: {
        mismatch: "Ihre Eingabe enspricht nicht dem ${pattern} Muster.",
    }
}