import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { SurveyVm } from "../../../models/SurveyVm";
import { Button, DatePicker, Descriptions, Input, Space } from "antd";
import moment from "moment";
import CommandBar from "../../../shared/components/CommandBar/CommandBar";
import { EditOutlined, CloseOutlined, SaveOutlined, ProfileOutlined } from "@ant-design/icons";
import { UpdateSurveyMetadataCommand } from "../../../shared/services/surveyService";
import { MailSettings } from "../../../models/SurveyTemplate";
import styles from "./SurveyDetails.module.css";
import MailProperties, { MailBodyField, MailPlaceholders, MailSubjectField, insertAt } from "../../templates/editor/PropertiesPane/MailProperties/MailProperties";
import ReactQuill from "react-quill";
const dateFormat = "DD.MM.YYYY";
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const SurveyDetails: FC<SurveyDetailsProps> = ({ survey, hasEditAccess, onSave }) => {
    const [editMode, setEditMode] = useState(false);
    const [title, setTitle] = useState(survey.title);
    const [validFrom, setValidFrom] = useState(survey.validFrom);
    const [validTo, setValidTo] = useState(survey.validTo);
    const [mailSettings, setMailSettings] = useState(survey.mailSettings);

    const edit = () => setEditMode(true);
    const closeEditMode = () => setEditMode(false);
    const save = async () => {
        const command = {
            title,
            validFrom: moment(validFrom).format(dateTimeFormat),
            validTo: moment(validTo).format(dateTimeFormat),
            mailSettings: mailSettings,
            surveyId: survey.id
        };

        onSave(command);
        closeEditMode();
    };

    const cancel = () => {
        setTitle(survey.title);
        setValidFrom(survey.validFrom);
        setValidTo(survey.validTo);
        setMailSettings(survey.mailSettings);
        closeEditMode();
    };

    return (
        <>
            <CommandBar title={<Space><ProfileOutlined /> Details</Space>}>
                {hasEditAccess && !editMode && <Button type="primary" icon={<EditOutlined />} onClick={edit}>Bearbeiten</Button>}
                {hasEditAccess && editMode && <Button icon={<CloseOutlined />} onClick={cancel} type="primary" danger>Abbrechen</Button>}
                {hasEditAccess && editMode && <Button icon={<SaveOutlined />} onClick={save} type="primary">Speichern</Button>}
            </CommandBar>
            <div className={styles.details}>
                {editMode ?
                    <SurveyDetailsEditForm
                        title={title}
                        from={validFrom}
                        to={validTo}
                        onTitleChange={setTitle}
                        onFromChange={setValidFrom}
                        onToChange={setValidTo}
                    />
                    :
                    <SurveyDetailsDescriptions
                        title={title}
                        from={validFrom}
                        to={validTo}
                    />
                }
                {editMode ?
                    <SurveyMailSettingsEditForm
                        mailSettings={mailSettings}
                        isAnonymous={survey.isAnonymous}
                        onChange={setMailSettings}
                    />
                    :
                    <SurveyMailSettings mailSettings={mailSettings} />
                }
            </div>
        </>
    );
};

const SurveyDetailsDescriptions: FC<SurveyDetailsBoxProps> = ({ title, from, to }) => {
    return (
        <Descriptions className={styles.surveydetails} bordered layout="vertical">
            <Descriptions.Item label="Titel" span={3}>{title}</Descriptions.Item>
            <Descriptions.Item label="Gültig von" span={1}>{moment(from).format(dateFormat)}</Descriptions.Item>
            <Descriptions.Item label="Gültig bis">{moment(to).format(dateFormat)}</Descriptions.Item>
        </Descriptions>
    );
};

const SurveyDetailsEditForm: FC<SurveyDetailsEditFormProps> = ({ title, from, to, onTitleChange, onFromChange, onToChange }) => {
    const fromChange = (value: moment.Moment | null) => {
        if (value) {
            onFromChange(value.toDate());
        }
    }

    const toChange = (value: moment.Moment | null) => {
        if (value) {
            onToChange(value.toDate());
        }
    }

    return (
        <Descriptions className={styles.surveydetails} bordered layout="vertical">
            <Descriptions.Item label="Titel" span={3}><Input value={title} onChange={e => onTitleChange(e.target.value)} /></Descriptions.Item>
            <Descriptions.Item label="Gültig von" span={1}><DatePicker format={dateFormat} value={moment(from)} onChange={fromChange} /></Descriptions.Item>
            <Descriptions.Item label="Gültig bis"><DatePicker format={dateFormat} value={moment(to)} onChange={toChange} /></Descriptions.Item>
        </Descriptions>
    );
};

const SurveyMailSettings: FC<SurveyMailSettingsProps> = ({ mailSettings }) => {
    return (
        <Descriptions style={{ width: "100%" }} bordered layout="vertical">
            <Descriptions.Item label="Mail Betreff" span={3}>{mailSettings.subject}</Descriptions.Item>
            <Descriptions.Item label="Mail Inhalt" span={3}><div className={styles.mailbody} dangerouslySetInnerHTML={{ __html: mailSettings.body }} /></Descriptions.Item>
        </Descriptions>
    )
}

const SurveyMailSettingsEditForm: FC<SurveyMailSettingsEditFormProps> = ({ mailSettings, isAnonymous, onChange }) => {
    const subjectRef = useRef() as MutableRefObject<Input | null>;
    const bodyRef = useRef() as MutableRefObject<ReactQuill | null>;

    return (
        <Descriptions style={{ width: "100%" }} bordered layout="vertical">
            <Descriptions.Item label="Mailversand" span={3}>
                <MailProperties
                    mailSettings={mailSettings}
                    onUpdateMailSettings={onChange}
                    subjectRef={subjectRef}
                    bodyRef={bodyRef}
                    isAnonymous={isAnonymous}
                />
            </Descriptions.Item>
        </Descriptions>
    );
}

interface SurveyDetailsProps {
    survey: SurveyVm;
    hasEditAccess: boolean;
    onSave: (command: UpdateSurveyMetadataCommand) => void
}

interface SurveyDetailsBoxProps {
    title: string;
    from: Date;
    to: Date;
}

interface SurveyDetailsEditFormProps {
    title: string;
    from: Date;
    to: Date;
    onTitleChange: (value: string) => void;
    onFromChange: (value: Date) => void;
    onToChange: (value: Date) => void;
}

interface SurveyMailSettingsProps {
    mailSettings: MailSettings;
}

interface SurveyMailSettingsEditFormProps {
    mailSettings: MailSettings;
    isAnonymous: boolean;
    onChange: (value: MailSettings) => void
}