import { Button, Layout, message, Radio, Result, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import AppHeader from '../AppHeader';
import { hasValue, isQuestionAvailable, Page, SurveyVm } from '../models/SurveyVm';
import Survey from './Survey/Survey';
import styles from './Surveys.module.css';
import { SnippetsOutlined, FileDoneOutlined, CopyOutlined } from '@ant-design/icons';
import surveyService from '../shared/services/surveyService';
import { useParams } from 'react-router';
import TokenLogin from '../tokens/TokenLogin';
import { tokenService } from '../shared/services/tokenService';
import _ from 'lodash';
import { useAsync } from 'react-use';
import HtcSpin from '../shared/components/HtcSpin/HtcSpin';
import PageFrame from '../shared/components/PageFrame/PageFrame';
import clientStorage from "../shared/services/clientStorage";
import { settingsService } from "../shared/services/settingsService";
import { IAppConfig } from '../models/IAppConfig';
const { Content } = Layout;

const Surveys: FC = () => {
    const appConfig = settingsService.getConfig();
    const { token } = useParams<{ token: string }>();
    const [authorized, setAuthorized] = useState<boolean | undefined>();
    const [surveyView, setSurveyView] = useState<"welcome" | "survey" | "surveyWithAnswers" | "completed">("welcome");
    const [surveyVm, setSurveyVm] = useState<SurveyVm>({} as SurveyVm);
    const [canCompleteSurvey, setCanCompleteSurvey] = useState<boolean>(false);
    const { value, loading } = useAsync(() => authorizeToken(), [token]);
    const { loading: surveyLoading } = useAsync(() => getSurvey(), [authorized]);

    useEffect(() => setAuthorized(value), [value]);

    const authorizeToken = async () => {
        if (token) {
            return await tokenService.loginWithToken(token);
        }
        return false;
    }

    const getSurvey = async () => {
        if (!authorized) {
            return;
        }

        const surveyVmResult = appConfig.enableLastTokenAnswerTransfer ?
            await surveyService.getSurveyVmWithAnswers(token) :
            await surveyService.getSurveyVm(token);
        setSurveyVm(surveyVmResult || {} as SurveyVm);
    }

    const completeSurvey = async () => {
        const completedSurveyVm = _.cloneDeep(surveyVm);
        completedSurveyVm.pages.forEach(p => p.questions = p.questions.filter(q => isQuestionAvailable(q, surveyVm.pages)));

        try {
            await surveyService.completeSurveyVm(token, completedSurveyVm);
            clientStorage.addCompletedSurvey(surveyVm.id);
            setSurveyView("completed");
        }
        catch (e) {
            console.log(e);
            message.error('Die Umfrage konnte nicht erfolgreich abgeschlossen werden. Bitte laden Sie die Seite neu.')
        }
    }
    const updatePages = (pages: Page[]) => setSurveyVm({ ...surveyVm, pages: pages });
    const startSurvey = () => {
        const questionsWithAnswers = surveyVm?.pages?.flatMap(p => p.questions).filter(q => hasValue(q));
        if (!surveyVm.enableLastTokenAnswerTransfer || questionsWithAnswers.length <= 0) {
            setSurveyView("survey");
            return;
        }

        const requiredQuestions = surveyVm?.pages?.flatMap(p => p.questions).filter(q => q.required);
        const areRequiredQuestionsAnswered = !requiredQuestions?.some(q => !questionsWithAnswers.some(qwa => qwa.id === q.id));
        setSurveyView("surveyWithAnswers");
        setCanCompleteSurvey(areRequiredQuestionsAnswered);
    }

    const openSurvey = async (withAnswers: boolean) => {
        if (!withAnswers) {
            const surveyVmResult = await surveyService.getSurveyVm(token);
            setSurveyVm(surveyVmResult || {} as SurveyVm);
        }

        setSurveyView("survey");
    }

    if (loading || surveyLoading) {
        return (
            <PageFrame>
                <HtcSpin />
            </PageFrame>
        )
    }

    const getView = () => {
        switch (surveyView) {
            case "welcome": return <WelcomePage appConfig={appConfig} survey={surveyVm} onStart={startSurvey} />;
            case "completed": return <SurveysCompleted survey={surveyVm} />;
            case "surveyWithAnswers": return <SurveyWithAnswers appConfig={appConfig} canCompleteSurvey={canCompleteSurvey} onOpenSurvey={openSurvey} onCompleteSurvey={completeSurvey} />
            case "survey": return surveyVm?.pages?.length &&
                <Survey
                    pages={surveyVm.pages}
                    onUpdatePages={updatePages}
                    onCompleteSurvey={completeSurvey}
                />;
        }
    };

    return (
        <Layout className={styles.layout}>
            <AppHeader />
            <Layout className={styles.layout}>
                <Content className={styles.content}>
                    {!authorized && <TokenLogin authStatus={(token?.length > 0) ? "error" : undefined} />}
                    {authorized && getView()}
                </Content>
            </Layout>
        </Layout>);
};

const WelcomePage: FC<SurveysStartProps> = ({ survey, onStart }) => {
    const appConfig = settingsService.getConfig();
    return (
        <Result
            className={styles.result}
            icon={<SnippetsOutlined />}
            title={survey.title}
            subTitle={survey.description}
            extra={<Button type="primary" size="large" onClick={onStart}>{appConfig.mainTitle} starten</Button>}
        />
    );
}

const SurveyWithAnswers: FC<SurveyWithAnswersProps> = ({ appConfig, canCompleteSurvey, onOpenSurvey, onCompleteSurvey }) => {
    const [selectedOption, setSelectedOption] = useState<string>("start");

    const onStart = () => {
        switch(selectedOption){
            case "start": onOpenSurvey(false); break;
            case "startWithAnswers": onOpenSurvey(true); break;
            case "complete": onCompleteSurvey(); break;
        }
    }

    return (
        <Result
            className={styles.result}
            icon={<CopyOutlined />}
            title={`Antworten aus früheren ${appConfig.mainTitle} übernehmen`}
            subTitle={`Wollen Sie Antworten aus Ihrer zuletzt durchgeführten ${appConfig.mainTitle} übernehmen?`}
            extra={<Space direction='vertical'>
                <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
                    <Space className={styles.radioitems} direction="vertical">
                        <Radio value="start">Ich möchte meine alten Antworten nicht übernehmen</Radio>
                        <Radio value="startWithAnswers">Ich möchte meine alten Antworten übernehmen und vor dem Versand überprüfen</Radio>
                        {canCompleteSurvey && <Radio value="complete">Ich möchte meine alten Antworten ohne Überprüfung sofort versenden</Radio>}
                    </Space>
                </Radio.Group>
                <Button type="primary" size="large" onClick={onStart}>Weiter</Button>
            </Space>}
        />
    )
}

const SurveysCompleted: FC<{ survey: SurveyVm }> = ({ survey }) => {
    return (
        <Result
            className={styles.result}
            icon={<FileDoneOutlined />}
            title={survey.closingWords ?? "Vielen Dank für Ihre Bewertung!"}
        />
    );
}

export default { Surveys, WelcomePage, SurveysCompleted };

interface SurveysStartProps {
    appConfig: IAppConfig;
    survey: SurveyVm;
    onStart: () => void;
}

interface SurveyWithAnswersProps {
    appConfig: IAppConfig;
    canCompleteSurvey: boolean;
    onOpenSurvey: (withAnswers: boolean) => void;
    onCompleteSurvey: () => void;
}