import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { Input, Space, Switch } from "antd";
import { EditorItem } from "../../../../../models/Editor";
import { SurveyTemplate, QuestionTypes } from "../../../../../models/SurveyTemplate";
import styles from "./GeneralProperties.module.css";
import TextArea from "antd/lib/input/TextArea";
import { settingsService } from "../../../../../shared/services/settingsService";

const GeneralProperties: FC<GeneralPropertiesProps> = ({ editorItem, titleRef, descriptionRef, mailSenderRef, onUpdateItem }) => {
    const appConfig = settingsService.getConfig();
    const { type, item } = editorItem;
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [closingWords, setClosingWords] = useState("");
    const [mailSender, setMailSender] = useState("");

    useEffect(() => setTitle(item.title), [item.title]);
    useEffect(() => {
        if (type !== "page") {
            const description = type === "question" ? (item as QuestionTypes).description : (item as SurveyTemplate).description;
            setDescription(description);
        }

        if (type === "survey") {
            setClosingWords((item as SurveyTemplate).closingWords);
            setMailSender((item as SurveyTemplate).mailSender);
        }
    }, [item]);

    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <Input
                ref={e => titleRef.current = e}
                placeholder="Titel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => onUpdateItem({ type: type, item: { ...item, title: e.target.value } } as EditorItem)}
            />
            {type !== "page" &&
                <TextArea
                    ref={e => descriptionRef.current = e}
                    placeholder="Beschreibung"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={(e) => onUpdateItem({ type: type, item: { ...item, description: e.target.value } } as EditorItem)}
                />
            }
            {type === "survey" &&
                <TextArea
                    placeholder="Abschlusstext (optional)"
                    value={closingWords}
                    onChange={(e) => setClosingWords(e.target.value)}
                    onBlur={(e) => onUpdateItem({ type: type, item: { ...item, closingWords: e.target.value } } as EditorItem)}
                />
            }
            {type === "survey" &&
                <Space style={{ width: "100%" }} direction="vertical">
                    <Space>
                        <Switch
                            className={styles.switch}
                            size="small"
                            checked={(item as SurveyTemplate).isAnonymous}
                            onChange={(e) => onUpdateItem({ type: type, item: { ...item, isAnonymous: e, useAutoMailSend: e ? false : (item as SurveyTemplate).useAutoMailSend } } as EditorItem)}
                        />
                        <span>Anonym</span>
                    </Space>
                    {(appConfig.enableLastTokenAnswerTransfer && !(item as SurveyTemplate).isAnonymous) &&
                        <Space>
                            <Switch
                                className={styles.switch}
                                size="small"
                                checked={(item as SurveyTemplate).enableLastTokenAnswerTransfer}
                                onChange={(e) => onUpdateItem({ type: type, item: { ...item, enableLastTokenAnswerTransfer: e } } as EditorItem)}
                            />
                            <span>Übernahme vorangegangener Antworten möglich</span>
                        </Space>
                    }
                    {appConfig.enableRiosConnection &&
                        <Space>
                            <Switch
                                className={styles.switch}
                                size="small"
                                checked={(item as SurveyTemplate).useAutoMailSend}
                                onChange={(e) => onUpdateItem({ type: type, item: { ...item, useAutoMailSend: e } } as EditorItem)}
                            />
                            <span>Automatische Benachrichtigung an</span>
                        </Space>
                    }
                    {appConfig.enableRiosConnection && (item as SurveyTemplate).useAutoMailSend && <TextArea
                        ref={e => mailSenderRef.current = e}
                        rows={1}
                        placeholder="Absender (Bitte beachten Sie, dass es sich hierbei um eine existierende E-Mail-Adresse handeln muss)"
                        value={mailSender}
                        onChange={(e) => setMailSender(e.target.value)}
                        onBlur={(e) => onUpdateItem({ type: type, item: { ...item, mailSender: e.target.value } } as EditorItem)}
                    />}
                </Space>
            }
        </Space>
    );
}

export default GeneralProperties;

interface GeneralPropertiesProps {
    editorItem: EditorItem;
    titleRef: MutableRefObject<Input | null>;
    descriptionRef: MutableRefObject<TextArea | null>;
    mailSenderRef: MutableRefObject<TextArea | null>;
    onUpdateItem: (updated: EditorItem) => void;
}