import React from "react";
import { Button, Table, Upload } from "antd";
import { FC } from "react";
import { SurveyReceiver } from "../../../../models/SurveyTokens";
import { MetadataForm } from "../../../../shared/components/MetadataForm/MetadataForm";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";

export const ReceiversList: FC<ReceiversListProps> = ({ receivers, onAddReceiver, metadataColumns }) => {
    const addReceiver = (values: any) => {
        onAddReceiver(values as SurveyReceiver);
    };

    const columns = metadataColumns.map(column => ({
        title: column,
        dataIndex: column,
        key: column,
        render: (text: string, record: any) => record?.metadata[column]
    }));

    return (
        <>
            <MetadataForm metadataColumns={metadataColumns} onSave={addReceiver} />
            <Table
                columns={columns}
                dataSource={receivers}
            />
        </>
    );
}

export const ReceiversListDownload: FC<{ onDownloadExcel: () => void }> = ({ onDownloadExcel }) => {
    return (
        <Button
            onClick={onDownloadExcel}
            icon={<DownloadOutlined />}
        >
            Empfängervorlage
        </Button>

    );
}

export const ReceiversListUpload: FC<{ onUploadExcel: (options: RcCustomRequestOptions) => void }> = ({ onUploadExcel }) => {
    return (
        <Upload customRequest={onUploadExcel} accept=".xls, .xlsx">
            <Button icon={<UploadOutlined />} type="primary">Empfängerupload</Button>
        </Upload>
    );
}

interface ReceiversListProps {
    metadataColumns: string[];
    receivers: SurveyReceiver[];
    onAddReceiver: (receiver: SurveyReceiver) => void;
}