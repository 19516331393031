import { Input } from "antd";
import React, { FC } from "react";

export const UserSearch: FC<UserSearchProps> = ({ onSearch }) => {
    return (
        <Input.Search
            allowClear
            placeholder="Benutzer suchen"
            onSearch={onSearch}
        />
    );
}

interface UserSearchProps {
    onSearch: (searchText: string) => void;
}