import { Layout } from "antd";
import React, { FC } from "react";
import AppHeader from "../../../AppHeader";
const { Content } = Layout;

const PageFrame: FC = ({ children }) => {
    return (
        <Layout>
            <AppHeader />
            <Layout>
                <Content>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default PageFrame;