import { Layout, Result } from "antd";
import React from "react";
import { FC } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import SurveyTemplates from "./templates/SurveyTemplates";
import { SurveyTemplateEdit, SurveyTemplateNew } from "./templates/editor/SurveyTemplateEditor/SurveyTemplateEditor";
import { Surveys } from "../administration/surveys/Surveys";
import { Survey } from "../administration/survey/Survey";
import { Settings } from "./settings/Settings";
import { AppSider } from "../AppSider";
import { Users } from "./security/Users";
import { UserInfoContext } from "../UserInfoContext";
import { userService } from "../shared/services/userService";
import { WarningOutlined } from "@ant-design/icons";
import { useAsync } from "react-use";
import HtcSpin from "../shared/components/HtcSpin/HtcSpin";

const { Content } = Layout;

export const AdministrationNavigation: FC = () => {
    const { value, loading } = useAsync(() => userService.init())

    if (loading)
        return (<HtcSpin />);

    if (!value)
        return (<Result
            icon={<WarningOutlined />}
            title="Anmeldung fehlgeschlagen"
        />);

    const userInfo = userService.getUserInfoObject();
    const userClaims = userService.getUserClaimsObject();

    return (
        <UserInfoContext.Provider value={{ userInfo }}>
            <Layout>
                <Layout >
                    <AppSider />
                    <Content>
                        <Switch>
                            <Route exact path="/administration/templates" component={SurveyTemplates} />
                            <Route path="/administration/templates/edit/:id" component={SurveyTemplateEdit} />
                            <Route path="/administration/templates/copy/:id" component={SurveyTemplateEdit} />
                            <Route path="/administration/templates/new" component={SurveyTemplateNew} />
                            <Route exact path="/administration/surveys" component={Surveys} />
                            <Route path="/administration/surveys/:id" component={Survey} />
                            {userClaims.isAdministrator() && <Route path="/administration/settings" component={Settings} />}
                            {userClaims.isAdministrator() && <Route path="/administration/users" component={Users} />}
                            <Redirect exact from="/administration" to="/administration/templates" />
                            <Redirect exact from="/administration" to="/administration/surveys" />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </UserInfoContext.Provider>
    );
}