import React, { FC, ReactNode } from "react";
import styles from "./CommandBar.module.less";

const CommandBar: FC<ICommandBarProps> = ({ children, extra, title }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.headertitle}>
                    {title}
                </div>
                <div className={styles.headerextra}>
                    {extra}
                </div>
            </div>
            <div className={styles.commands}>
                {children}
            </div>
        </div>
    );
}

export default CommandBar;

interface ICommandBarProps {
    title?: ReactNode;
    extra?: ReactNode[];
}