import { Form, InputNumber, Modal } from "antd";
import React from "react";
import { FC } from "react";
import { Store } from "antd/lib/form/interface";

export const GenerateTokenModal: FC<GenerateTokenModalProps> = ({ surveyId, onSubmit, onCancel, visible }) => {
    const [form] = Form.useForm();
    const onModalCancel = () => {
        onCancel();
        form.resetFields()
    }

    return (
            <Modal title="Tokens anlegen"
                width={400}
                closable={false}
                visible={visible}
                okText="Anlegen"
                cancelText="Abbrechen"
                onOk={form.submit}
                onCancel={onModalCancel}>
                <Form form={form} onFinish={onSubmit} initialValues={{ tokens: 1}}>
                    <Form.Item label="Tokens Anzahl" name="tokens" rules={[{ required: true, message: "Bitte geben Sie eine gültige Anzahl an" }]}>
                        <InputNumber min={1} max={100} />
                    </Form.Item>
                </Form>
            </Modal>
    );
}

interface GenerateTokenModalProps {
    surveyId: string;
    onSubmit: (values: Store) => void;
    onCancel: () => void;
    visible: boolean;
}