import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { UserChangePassword, IUserInfo } from "../../../../models/IUserInfo";
import { Store } from "antd/lib/form/interface";
import { Rule } from "antd/lib/form";
import styles from "./UserChangePassword.module.css";
import { CommandFooter } from "../UserForm/FormItems";

export const ChangePasswordForm: FC<NewUserFormProps> = ({ userInfo, onSave, onCancel, wrongPassword, oldInput }) => {

    const [isWrongPassword, setIsWrongPassword] = useState(wrongPassword);
    const [form] = Form.useForm();
    const onFinish = (values: Store) => {
        const userChangePassword = values as UserChangePassword;
        userChangePassword.userId = userInfo.id;
        onSave(userChangePassword);
    };

    const formItemLayout: Rule[] = [
        {
            required: true,
            message: 'Bitte bestätigen Sie Ihr Passwort',
        },

        ({ getFieldValue }) => ({
            validator(_, value) {
                if (getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Das von Ihnen eingegebene neue Passwort stimmt nicht überein'));
            },
        }),

    ];

    useEffect(() => {
        if (isWrongPassword) {
            form.setFields([
                {
                    name: "oldPassword",
                    value: oldInput?.oldPassword,
                    errors: ["Falsches Passwort"]
                }
            ]);
            setIsWrongPassword(false);
        }
    })

    return (
        <Form form={form} className={styles.form} labelAlign="left" initialValues={oldInput} {...formItemLayout} onFinish={onFinish}>
            <Form.Item name="oldPassword" label="Altes Password" rules={[{ required: true, message: "Bitte geben Sie ihr altes Passwort ein" }]}>
                <Input.Password className={styles.input} />
            </Form.Item>
            <Form.Item name="newPassword" label="Neues Password" rules={[{ required: true, message: "Bitte geben Sie ihr neues Passwort ein" }]}>
                <Input.Password className={styles.input} />
            </Form.Item>
            <Form.Item name="passwordConfirm" label="Passwort bestätigen" dependencies={['newPassword']} rules={formItemLayout}>
                <Input.Password className={styles.input} />
            </Form.Item>
            <Form.Item >
                <CommandFooter onClose={onCancel} />
            </Form.Item>
        </Form>);
}
interface NewUserFormProps {
    userInfo: IUserInfo;
    onSave: (userData: UserChangePassword) => void;
    onCancel: () => void;
    wrongPassword: boolean;
    oldInput: UserChangePassword | undefined;
}