import { MappingDefinition } from "../../models/MappingDefinition";
import httpClient from "./httpClient";

interface MappingDefinitionsQueryResult {
    mappingDefinitions: MappingDefinition[];
}

const getMappingDefinitions = async (): Promise<MappingDefinition[]> => {
    const client = await httpClient.getInstance();
    const response = await client.get<MappingDefinitionsQueryResult>("mapping/definitions");
    return response.data.mappingDefinitions;
}

export default { getMappingDefinitions };