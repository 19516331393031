import React, { FC, ReactNode, CSSProperties } from "react";
import _ from "lodash";
import classnames from "classnames";
import styles from "./FormBox.module.css";

export const FormBox: FC<{
    title: string;
    icon?: ReactNode;
    style?: CSSProperties;
    flex?: boolean;
    contentflex?: boolean;
    overflowX?: boolean;
    requiredIndicator?: boolean;
}> = ({ title, icon, children, style, flex, contentflex, overflowX }) => {
    if (flex) {
        style = _.assign({ flex: "1 1 auto" }, style);
    }

    let contentStyle: CSSProperties = {};

    if (overflowX) {
        contentStyle = { overflowX: "auto", overflowY: "hidden" };
    }
    return (
        <div id="formbox" className={styles.formbox} style={style}>
            <div className={styles.formbox_header}>
                <span>
                    {title}
                </span>
                {icon}
            </div>
            <div style={contentStyle} className={classnames(styles.formbox_content, contentflex && styles.formbox_content_flex)}>
                {children}
            </div>
        </div>
    );
};