import { FC, useEffect, useState } from "react";
import { SurveyVm } from "../../../models/SurveyVm";
import React from "react";
import { Button, Descriptions, Empty, Select, Space, Tabs } from "antd";
import CommandBar from "../../../shared/components/CommandBar/CommandBar";
import { FileExcelOutlined, BarChartOutlined } from "@ant-design/icons";
import surveyService, { BarChartRecord, TextChartRecord } from "../../../shared/services/surveyService";
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAsync } from "react-use";
import HtcSpin from "../../../shared/components/HtcSpin/HtcSpin";
import _ from "lodash";
import styles from "./SurveyStatistics.module.css";

export const SurveyStatistics: FC<SurveyAnalyticsProps> = ({ survey }) => {
  const { value, loading } = useAsync(() => surveyService.getSurveyStatisticChartData(survey.id));
  const [records, setRecords] = useState(value);

  useEffect(() => setRecords(value), [value]);

  if (loading) {
    return <HtcSpin />
  }

  const onDownloadStatisticsExcel = () => {
    surveyService.downloadAnswersExcelFile(survey.id);
  }

  const empty = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  const getBarChartContent = () => records?.barChartRecords.length ? <SurveyBarChart records={records.barChartRecords} /> : empty;
  const getTextChartContent = () => records?.textChartRecords.length ? <SurveyTextChart records={records.textChartRecords} /> : empty;

  return (<>
    <CommandBar title={<Space><BarChartOutlined /> Auswertung</Space>}>
      <Button type="primary" onClick={onDownloadStatisticsExcel} icon={<FileExcelOutlined />}>Excel Export</Button>
    </CommandBar>
    <ResponsiveContainer width="100%" height="100%">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Auswahlfragen" key="1">
          {getBarChartContent()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Freitext" key="2">
          {getTextChartContent()}
        </Tabs.TabPane>
      </Tabs>
    </ResponsiveContainer>
  </>);
};


const SurveyBarChart: FC<SurveyBarChartProps> = ({ records }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  useEffect(() => {
    if (records?.length) {
      setSelectedQuestion(records[0].questionId);
    }
  }, [records]);

  const onQuestionChange = (value: string) => {
    setSelectedQuestion(value);
  };

  const options = _.chain(records)
    .uniqBy(record => record.questionId)
    .orderBy(record => record.question)
    .map(record => ({ label: record.question, value: record.questionId }))
    .value();

  const chartRecords = records.filter(record => record.questionId === selectedQuestion);

  return (<>
    <Select className={styles.select} value={selectedQuestion} options={options} onChange={onQuestionChange} />
    <BarChart width={500} height={300} data={chartRecords} margin={{ top: 50, bottom: 20 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis />
      <XAxis dataKey="option" />
      <Bar dataKey="amount" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
    </BarChart>
  </>);
};

const SurveyTextChart: FC<SurveyTextChartProps> = ({ records }) => {
  const groupByQuestion = _.groupBy(records, record => record.questionId);
  const groupByQuestionKeys = Object.keys(groupByQuestion);
  const descriptionItems = groupByQuestionKeys.map(key => {
    const questionRecords = groupByQuestion[key];
    return (
      <Descriptions.Item label={questionRecords[0].question} span={3}>
        {questionRecords.map(r => <div>{r.answer}</div>)}
      </Descriptions.Item>
    );
  });

  return (
    <Descriptions className={styles.textchart} bordered size="small">
      {descriptionItems}
    </Descriptions>
  );
};

interface SurveyAnalyticsProps {
  survey: SurveyVm;
}

interface SurveyBarChartProps {
  records: BarChartRecord[];
}

interface SurveyTextChartProps {
  records: TextChartRecord[];
}