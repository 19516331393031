import { Avatar, Divider, Form, Modal, Space } from "antd";
import { Store } from "antd/lib/form/interface";
import React from "react";
import { FC } from "react";
import { IUserInfo } from "../../../../models/IUserInfo";
import { Claims, CommandFooter, Email, PersonalData, UserName } from "./FormItems";
import { UserAddOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { AdministratorRole, CanAddTemplatePermission, IClaim, UserClaims } from "../../../../models/Claim";

export const NewUserForm: FC<NewUserFormProps> = ({ onSave, onClose }) => {
    const [form] = useForm();

    const onFinish = (values: Store) => {
        const user = values as IUserInfo;
        user.displayName = `${user.lastName}, ${user.firstName}`;
        user.claims = getClaims(user, values.isAdmin, values.canAddTemplates);
        onSave(user);
    };

    const getClaims = (user: IUserInfo, isAdmin: boolean, canAddTemplates: boolean): IClaim[] => {
        const userClaims = new UserClaims(user);
        if (isAdmin) {
            userClaims.addRoleClaim(AdministratorRole);
        }

        if (canAddTemplates) {
            userClaims.addPermissionClaim(CanAddTemplatePermission);
        }

        return userClaims.getRawClaims();
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 20 },
            sm: { span: 10 },
        },
    };

    return (
        <Modal title={<Space><Avatar icon={<UserAddOutlined />} /> Benutzer anlegen</Space>} visible={true} onCancel={onClose} footer={null} closable={false}>
            <Form form={form} labelAlign="left" {...formItemLayout} onFinish={onFinish}>
                <UserName />
                <Email />
                <Divider />
                <PersonalData />
                <Divider />
                <Claims
                    form={form}
                    isdefaultadmin={false}
                />
                <Divider />
                <CommandFooter onClose={onClose} />
            </Form>
        </Modal>
    );
}

interface NewUserFormProps {
    onSave: (userData: IUserInfo) => void;
    onClose: () => void;
}