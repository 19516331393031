import { Modal, Descriptions } from "antd";
import React, { FC, useState, useEffect } from "react";
import { useAsync } from "react-use";
import { TokenAnswers } from "../../../../models/SurveyTemplate";
import CommandBar from "../../../../shared/components/CommandBar/CommandBar";
import HtcSpin from "../../../../shared/components/HtcSpin/HtcSpin";
import surveyService from "../../../../shared/services/surveyService";
import { SurveyToken } from "../../../../models/SurveyTokens";

export const TokenAnswersModal: FC<TokenAnswersModalProps> = ({ surveyToken, visible, onClose }) => {
    return (
        <Modal
            title={<CommandBar title="Antworten" />}
            width="70%"
            closable={true}
            visible={visible}
            footer={null}
            onCancel={onClose}
        >
            <TokenAnswersComponent surveyToken={surveyToken} />
        </Modal>
    );
}

const TokenAnswersComponent: FC<TokenAnswersComponentProps> = ({ surveyToken }) => {
    const [answers, setAnswers] = useState<TokenAnswers[]>([]);
    const { value, loading } = useAsync(() => surveyService.getTokensAnswers(surveyToken.surveyId, surveyToken.token), [surveyToken]);

    useEffect(() => setAnswers(value || []), [value]);

    if (loading) {
        return <HtcSpin />
    }

    const items = answers.map((answer: TokenAnswers) =>
        <Descriptions.Item
            key={answer.token}
            style={{ color: "#000" }}
            span={3}
            label={`${answer.question}${answer.questionDescription && ' - ' + answer.questionDescription}`}>
            {answer.option ? `${answer.option} ${answer.comment || ''}` : answer.answer || ''}
        </Descriptions.Item>
    );

    if (items.length === 0)
        items.push(
            <Descriptions.Item key={surveyToken.token} span={3}>
                Es liegen noch keine Antworten vor
            </Descriptions.Item>
        );

    return (
        <Descriptions bordered children={items} />
    );
}

interface TokenAnswersModalProps {
    surveyToken: SurveyToken;
    visible: boolean;
    onClose: () => void;
}

interface TokenAnswersComponentProps {
    surveyToken: SurveyToken;
}