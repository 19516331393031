import React, { FC, useState } from "react";
import { Button, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PermissionClaim } from "../../../../../models/Claim";
import { PermissionClaimSelection } from "./PermissionClaimSelection";

export const NewPermissionClaim: FC<NewPermissionClaimProps> = ({ title, options, claimoptions, onAdd }) => {
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedClaim, setSelectedClaim] = useState(claimoptions[0]);

    const addPermissionEntities = () => {
        const claims = selectedKeys.map(id => ({...selectedClaim, id}));
        setSelectedKeys([]);
        onAdd(claims);
    };

    return (
        <Space>
            <Select style={{ width: '30em' }}
                mode="multiple"
                allowClear
                showSearch
                placeholder={title}
                value={selectedKeys}
                filterOption={(input, option) => ((option?.label as any)?.key.toString() ?? "").toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                onChange={setSelectedKeys}
                options={options}
            />
            <PermissionClaimSelection 
                selectedClaim={selectedClaim}
                claimOptions={claimoptions}
                onChange={setSelectedClaim}
            />
            <Button
                icon={<PlusOutlined />}
                onClick={addPermissionEntities} />
        </Space>);
};

interface NewPermissionClaimProps {
    title: string;
    options: OptionsType[];
    claimoptions: PermissionClaim[];
    onAdd: (claims: PermissionClaim[]) => void;
}

export interface OptionsType {
    key: string;
    value: string;
    label: string;
}