import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useData } from "../shared/hooks/useData";
import { useHistory } from "react-router";
import HtcSpin from "../shared/components/HtcSpin/HtcSpin";
import { Layout, Result } from "antd";
import surveyService from "../shared/services/surveyService";
import PageFrame from "../shared/components/PageFrame/PageFrame";
import clientStorage from "../shared/services/clientStorage";

const TokenAutoProvisioning: FC = () => {
    const history = useHistory();
    const { surveyId } = useParams<{ surveyId: string }>();
    const { data, loading } = useData("", () => surveyService.getValidSurveyTokens(surveyId));
    const [tokens, setTokens] = useState(data || []);

    useEffect(() => setTokens(data || []), [data]);

    const proceedToSurvey = (token: string) => {
        history.push(`/surveys/${token}`);
    }
    
    const hasParticipated = (): boolean => {
        const completedOnClient = clientStorage.getCompletedSurveys();
        console.log(completedOnClient);
        console.log(surveyId);
        return completedOnClient.some(s => s.toLowerCase() == surveyId.toLowerCase());
    };

    if (loading) {
        return (
            <PageFrame>
                <HtcSpin spinning={loading} />
            </PageFrame>
        );
    }

    if(hasParticipated()) {
        return (
            <PageFrame>
                <Result status="warning" title="Sie haben bereits an dieser Umfrage teilgenommen." />
            </PageFrame>
        );
    }

    if (tokens.length) {
        proceedToSurvey(tokens[Math.floor(Math.random() * (tokens.length - 1))]);
    }

    return (
        <PageFrame>
            <Result status="warning" title="An der Umfrage kann nicht mehr teilgenommen werden." />
        </PageFrame>
    );
};

export default TokenAutoProvisioning;
