import { FC, useEffect, useState } from "react";
import { HtcCard } from "../../../shared/components/HtcCard/HtcCard";
import React from "react";
import { Button, Col, Form, Input, Row, Space, notification } from "antd";
import { ExchangeSettings } from "../../../models/Settings";
import CommandBar from "../../../shared/components/CommandBar/CommandBar";
import { Store } from "antd/lib/form/interface";
import { useForm } from "antd/lib/form/Form";
import { MailOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import configurationService from "../../../shared/services/configurationService";

export const ExchangeSettingsComponent: FC<ExchangeSettingsComponentProps> = ({ settings, onSave }) => {
    const [form] = useForm();
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        form.setFieldsValue(settings);
    }, [settings]);

    const onFinish = (values: Store) => {
        onSave(values as ExchangeSettings);
        setEditMode(false);
    };

    const validateExchange = async () => {
        const result = await configurationService.validateExchangeSettings(settings);

        if (result?.success) {
            notification.success({ message: "Die Einstellungen sind gültig" });
        } else {
            notification.error({ message: "Die Einstellungen sind ungültig" });
        }
    }

    return (
        <Form form={form} onFinish={onFinish}>
            <CommandBar title={<Space><MailOutlined /> Mailsender Konfiguration</Space>}>
                <Button icon={<EditOutlined />} onClick={() => validateExchange()}>Prüfen</Button>
                {!editMode && <Button icon={<EditOutlined />} type="primary" onClick={() => setEditMode(true)}>Bearbeiten</Button>}
                {editMode && <Button icon={<SaveOutlined />} type="primary" onClick={() => form.submit()}>Speichern</Button>}
            </CommandBar>
            <HtcCard>
                <EchangeSettingsEditForm settings={settings} editMode={editMode} />
            </HtcCard>
        </Form>
    );
};

export const EchangeSettingsEditForm: FC<{ settings: ExchangeSettings, editMode: boolean }> = ({ settings, editMode }) => {
    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={2}>SMTP-Servername</Col>
                <Col span={6}>
                    {editMode &&
                        <Form.Item name="url">
                            {editMode && <Input />}
                        </Form.Item>
                    }
                    {!editMode && settings.url}
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={2}>Benutzername</Col>
                <Col span={6}>
                    {editMode &&

                        <Form.Item name="userName">
                            <Input />
                        </Form.Item>
                    }
                    {!editMode && settings.userName}
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={2}>Passwort</Col>
                <Col span={6}>
                    {editMode &&
                        <Form.Item name="password">
                            <Input.Password />
                        </Form.Item>
                    }
                    {!editMode && "**********"}
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={2}>Sender Email</Col>
                <Col span={6}>
                    {editMode &&
                        <Form.Item name="fromEmail">
                            <Input />
                        </Form.Item>
                    }
                    {!editMode && settings.fromEmail}
                </Col>
            </Row>
        </>
    );
}

interface ExchangeSettingsComponentProps {
    settings: ExchangeSettings;
    onSave: (settings: ExchangeSettings) => void;
}