import React, { FC } from "react";
import { CanEditSurveysClaim, CanReadSurveysClaim } from "../../../models/Claim";
import { UsersPermissionClaims } from "../../security/users/UserPermissionClaims/UsersPermissionClaims";
import { SurveyListItem } from "../../../models/SurveyVm";

export const SurveyPermissionClaims: FC<SurveyPermissionClaimsProps> = ({ survey, onClose }) => {
    const canEditSurveys = { type: "Permission", value: "CanEditSurveys", displaytext: "Schreiben", id: survey.id } as CanEditSurveysClaim;
    const canReadSurveys = { type: "Permission", value: "CanReadSurveys", displaytext: "Lesen", id: survey.id } as CanReadSurveysClaim;

    return (
        <UsersPermissionClaims
            title={survey.title}
            claimoptions={[canEditSurveys, canReadSurveys]}
            onClose={onClose}
        />
    );
}

interface SurveyPermissionClaimsProps {
    survey: SurveyListItem;
    onClose: () => void;
}