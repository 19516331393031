import { DatePicker, Select, Space } from "antd";
import moment from "moment";
import React from "react";
import { FC } from "react";

export const SurveyListFilter: FC<SurveyListFilterProps> = ({ filterConfig, onChange }) => {
    const datePickerFormat = 'DD.MM.YYYY';
    const from = moment(filterConfig.from);
    const to = filterConfig.to ? moment(filterConfig.to) : undefined;

    const onFromChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            onChange({ ...filterConfig, from: date.toDate() });
        };
    };

    const onToChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            onChange({ ...filterConfig, to: date.toDate() });
        };
    };

    const onTimespanChange = (value: string) => {
        switch (value) {
            case "current":
                onChange({ from: moment().endOf("day").toDate(), to: null, state: value });
                break;
            case "lastThreeMonth":
                onChange({ from: moment().endOf("day").subtract(3, "month").toDate(), to: null, state: value });
                break;
            case "periodFilter":
                onChange({ ...filterConfig, state: value });
                break;
        };
    };

    return (
        <Space direction="horizontal">
            <Select value={filterConfig.state} onChange={onTimespanChange} style={{ width: 250, textAlign: "left" }}>
                <Select.Option value="current">Aktuell</Select.Option>
                <Select.Option value="lastThreeMonth">Aktuell (inkl. letzter 3 Monate)</Select.Option>
                <Select.Option value="periodFilter">Inidividuell</Select.Option>
            </Select>
            {
                filterConfig.state === "periodFilter" &&
                <>
                    <DatePicker format={datePickerFormat} value={from} onChange={onFromChange} allowClear={false} />
                    <DatePicker format={datePickerFormat} value={to} onChange={onToChange} />
                </>
            }
        </Space>
    );
}

interface SurveyListFilterProps {
    filterConfig: IFilterConfig;
    onChange: (filter: IFilterConfig) => void;
}

export interface IFilterConfig {
    from: Date;
    to: Date | null;
    state: string;
}

export const defaultFilterConfig: IFilterConfig = {
    from: moment().startOf("day").toDate(),
    to: null,
    state: "current"
};