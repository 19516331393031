import { Alert, PageHeader, Space } from "antd";
import _ from "lodash";
import React, { FC } from "react";
import { FormBox } from "../FormBox/FormBox";
import { CloseCircleTwoTone, CloseOutlined } from '@ant-design/icons';
import styles from "./ValidationSummary.module.css";
import { hasMessages, ValidationData, ValidationProperty } from "../../../models/SurveyTemplateValidator";
import { EditorItem } from "../../../models/Editor";

const ValidationSummary: FC<ValidationSummaryProps> = ({ validationData, onFocusItem, onClose }) => {
    return (
        <PageHeader
            className={styles.validationsummary}
            title={
                <Space>
                    <CloseCircleTwoTone twoToneColor="red" />
                Validierungsfehler
            </Space>
            }
            extra={[
                <CloseOutlined key={"closeBtn"} className={styles.closeicon} onClick={onClose} />
            ]}
        >
            <ValidationSummaryTree
                key="ValidationSummaryTree"
                validationData={validationData}
                onFocusItem={onFocusItem}
            />
        </PageHeader>
    );
}

const ValidationSummaryTree: FC<ValidationSummaryTreeProps> = ({ validationData, onFocusItem }) => {
    const { item, title, messages, children } = validationData;
    return (<>
        { hasMessages(validationData) &&
            <FormBox title={title}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    {messages?.map(m =>
                        <Alert
                            className={m.type === "Warning" ? styles.warning : styles.error}
                            key={m.id}
                            message={m.message}
                            type={m.type === "Warning" ? "warning" : "error"}
                            onClick={() => onFocusItem(item, m.propertyName, m.id)}
                        />
                    )}
                </Space>
                {children?.map(c =>
                    <ValidationSummaryTree
                        key={`${c.title}_${c.item.item.id}`}
                        validationData={c}
                        onFocusItem={onFocusItem}
                    />
                )}
            </FormBox>
        }
    </>);
};

export default ValidationSummary;

interface ValidationSummaryProps {
    validationData: ValidationData;
    onClose: () => void;
    onFocusItem: (focusItem: EditorItem, focusType: ValidationProperty, focusId: string) => void;
}

interface ValidationSummaryTreeProps {
    validationData: ValidationData;
    onFocusItem: (focusItem: EditorItem, focusType: ValidationProperty, focusId: string) => void;
}



