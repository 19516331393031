import axios, { AxiosInstance } from "axios";
import { IAppConfig } from "../../models/IAppConfig";

class SettingsService {
    constructor() {
        this.http = this.initHttp();
    }

    public async init() {
        const result = await this.http.get<IAppConfig>("config.json");
        const config = result.data;
        this.config = config;
    }

    private http: AxiosInstance;
    private config: IAppConfig = {} as IAppConfig;

    public getConfig(): IAppConfig {
        return this.config || ({} as IAppConfig);
    }

    initHttp(): AxiosInstance {
        return axios.create({
            baseURL: "/", withCredentials: true
        });
    }
}

export const settingsService = new SettingsService();