import React, { FC } from "react";
import { SurveyTemplate } from "../../../models/SurveyTemplate";
import { CanEditTemplatesClaim, CanReadTemplatesClaim } from "../../../models/Claim";
import { UsersPermissionClaims } from "../../security/users/UserPermissionClaims/UsersPermissionClaims";

export const SurveyTemplatePermissionClaims: FC<SurveyTemplatePermissionClaimsProps> = ({ template, onClose }) => {
    const canEditTemplates = { type: "Permission", value: "CanEditTemplates", displaytext: "Schreiben", id: template.id } as CanEditTemplatesClaim;
    const canReadTemplates = { type: "Permission", value: "CanReadTemplates", displaytext: "Lesen", id: template.id } as CanReadTemplatesClaim;

    return (
        <UsersPermissionClaims
            title={template.title}
            claimoptions={[canEditTemplates, canReadTemplates]}
            onClose={onClose}
        />
    );
}

interface SurveyTemplatePermissionClaimsProps {
    template: SurveyTemplate;
    onClose: () => void;
}