import { IsValidMailAdress } from "../shared/ValidationHelpers";
import { EditorItem } from "./Editor";
import { MappingDefinition } from "./MappingDefinition";
import { Page, QuestionTypes, Subquestion, SurveyTemplate, Option } from "./SurveyTemplate";

export interface ValidationData {
    item: EditorItem;
    title: string;
    messages: ValidationTypes[];
    children: ValidationData[];
}

export type ValidationTypes = ValidationError | ValidationWarning;

export interface ValidationBase {
    id: string;
    propertyName: ValidationProperty;
    message: string;
}

export interface ValidationError extends ValidationBase {
    type: "Error"
}

export interface ValidationWarning extends ValidationBase {
    type: "Warning"
}

export enum ValidationProperty {
    Title,
    Description,
    Subject,
    Body,
    MailSender,
    Pages,
    Questions,
    Options,
    Option,
    Subquestions,
    Subquestion,
    MappingDefinition,
    MappingFields,
    MappingValue
}

export class SurveyTemplateValidator {
    getSurveyValidationData(template: SurveyTemplate, mappingDefinitions: MappingDefinition[]): ValidationData {
        const mappingDefinition = mappingDefinitions.find(def => def.name === template.mappingDefinition);
        const mappedFields = this.getMappedFields(template);
        
        return {
            item: { type: "survey", item: template },
            title: template.title || "Umfrage",
            messages: this.validateSurveyTemplate(template, mappingDefinition),
            children: [...template.pages?.map((p, i) => this.getPageValidationData(p, i, mappedFields, template.allowMultiMapping, mappingDefinition))]
        }
    }

    private validateSurveyTemplate(template: SurveyTemplate, mappingDefinition?: MappingDefinition): ValidationTypes[] {
        const { id, title, description, mailSettings, pages, mailSender } = template;
        const messages: ValidationTypes[] = [];
        if (isEmpty(title)) messages.push(emptyTitleError(id));
        if (isEmpty(description)) messages.push(emptyDescrError(id));
        if (template.useAutoMailSend) {
            if (!mailSettings || isEmpty(mailSettings.subject)) messages.push(emptySubjectError(id));
            if (!mailSettings || isEmpty(mailSettings.body)) messages.push(emptyBodyError(id));
            if (!isEmpty(mailSender) && !IsValidMailAdress(mailSender)) messages.push(inValidMailAdressError(id));
        }
        if (isEmptyArray(pages)) messages.push(emptyPagesError(id));
        return messages.concat(this.validateMapping(template, mappingDefinition));
    }

    private validateMapping(template: SurveyTemplate, mappingDefinition?: MappingDefinition): ValidationTypes[] {
        const messages: ValidationTypes[] = [];
        if (!mappingDefinition) {
            return messages;
        }

        const requiredFields = mappingDefinition.fields.filter(field => field.required);
        const mappedFields = this.getMappedFields(template);
        const missingFields = requiredFields?.filter(field => !mappedFields.includes(field.field))?.map(field => field.field);
        if(missingFields.length > 0) messages.push(requiredMappingFieldsError(template.id, missingFields));

        return messages;
    }

    private getMappedFields(template: SurveyTemplate): string[] {
        return template.pages
        .flatMap(page => page.questions)
        .filter(question => question.mappingField)
        .map(question => question.mappingField);
    }

    private getPageValidationData(page: Page, index: number, mappedFields: string[], allowMultiMapping: boolean, mappingDefinition?: MappingDefinition): ValidationData {
        const getRequiredMappingValue = (question: QuestionTypes) => {
            if(question.mappingField?.length <= 0){
                return false;
            }
            const mappingField = mappingDefinition?.fields?.find(f => f.field === question.mappingField);
            return mappingField?.requiredValue || false;
        }

        return {
            item: { type: "page", item: page },
            title: page.title || `Seite ${index + 1}`,
            messages: this.validatePage(page),
            children: page.questions?.map((q, i) => this.getQuestionValidationData(q, i, getRequiredMappingValue(q), mappedFields, allowMultiMapping))
        }
    }

    private validatePage(page: Page): ValidationTypes[] {
        const { id, title, questions } = page;
        const messages: ValidationTypes[] = [];
        if (isEmpty(title)) messages.push(emptyTitleError(id));
        if (isEmptyArray(questions)) messages.push(emptyQuestionsError(id));
        return messages;
    }

    private getQuestionValidationData(question: QuestionTypes, index: number, requiredMappingValue: boolean, mappedFields: string[], allowMultiMapping: boolean): ValidationData {
        return {
            item: { type: "question", item: question },
            title: question.title || `Frage ${index + 1}`,
            messages: this.validateQuestion(question, mappedFields, allowMultiMapping),
            children: this.getQuestionChildrenValidationData(question, requiredMappingValue)
        }
    }

    private validateQuestion(question: QuestionTypes, mappedFields: string[], allowMultiMapping: boolean): ValidationTypes[] {
        const { id, title } = question;
        const messages: ValidationTypes[] = [];
        if (isEmpty(title)) messages.push(emptyTitleError(id));
        if (question.mappingField && (mappedFields?.filter(m => m === question.mappingField)?.length > 1)) {
            messages.push(allowMultiMapping ? multiMappingFieldWarning(id, question.mappingField) : multiMappingFieldError(id, question.mappingField));
        }

        if (question.type === "SingleSelection"
            || question.type === "MultiSelection"
            || question.type === "RadioGroup"
            || question.type === "Matrix") {
            if (isEmptyArray(question.options)) messages.push(emptyOptionsError(id));
            if (question.mappingField && !question.options?.some(o => o.mappingValue)) messages.push(emptyMappingValueError(id));
        }

        if (question.type === "Matrix"){
            if (isEmptyArray(question.questions)) messages.push(emptySubquestionsError(id));
        }

        return messages;
    }

    private getQuestionChildrenValidationData(question: QuestionTypes, requiredMappingValue: boolean): ValidationData[] {
        switch (question.type) {
            case "SingleSelection": return question.options ? question.options.map((o, i) => this.getOptionValidationData(question, o, i, requiredMappingValue)) : [];
            case "MultiSelection": return question.options ? question.options.map((o, i) => this.getOptionValidationData(question, o, i, requiredMappingValue)) : [];
            case "RadioGroup": return question.options ? question.options.map((o, i) => this.getOptionValidationData(question, o, i, requiredMappingValue)) : [];
            case "Matrix": {
                return [...question.options ? question.options.map((o, i) => this.getOptionValidationData(question, o, i, requiredMappingValue)) : [],
                ...question.questions ? question.questions.map((q, i) => this.getSubquestionValidationData(question, q, i)) : []];
            }
            default: return [];
        }
    }

    private getOptionValidationData(
        question: QuestionTypes,
        option: Option,
        index: number,
        requiredMappingValue: boolean
    ): ValidationData {
        return {
            item: { type: "question", item: question },
            title: option.text || `Antwort ${index + 1}`,
            messages: this.validateOption(option, question.mappingField?.length > 0, requiredMappingValue),
            children: []
        };
    }

    private validateOption(option: Option, isMappedField: boolean, requiredMappingValue: boolean): ValidationTypes[] {
        const { value, text, mappingValue } = option;
        const messages: ValidationTypes[] = [];
        if (isEmpty(text)) messages.push(emptyOptionError(value));
        if (isMappedField && isEmpty(mappingValue)) {
            messages.push(requiredMappingValue ? emptyMappingRequiredValueError(value) : emptyMappingValueWarning(value));
        }  

        return messages;
    }

    private getSubquestionValidationData(question: QuestionTypes, subquestion: Subquestion, index: number): ValidationData {
        return {
            item: { type: "question", item: question },
            title: `Frage ${index + 1}`,
            messages: this.validateSupquestion(subquestion),
            children: []
        }
    }

    private validateSupquestion(question: Subquestion): ValidationTypes[] {
        return isEmpty(question.description) ? [emptySubquestionError(question.id)] : [];
    }
}

const emptyTitleError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Title,
        message: "Der Titel darf nicht leer sein."
    }
}

const emptyDescrError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Description,
        message: "Die Beschreibung darf nicht leer sein."
    }
}

const emptySubjectError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Subject,
        message: "Der Betreff darf nicht leer sein."
    }
}
const emptyBodyError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Body,
        message: "Der Text darf nicht leer sein."
    }
}
const inValidMailAdressError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.MailSender,
        message: "Die Absender-Mailadresse ist ungültig."
    }
}
const emptyPagesError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Pages,
        message: "Die Umfrage muss aus mindestens einer Seite bestehen."
    }
}
const emptyQuestionsError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Questions,
        message: "Die Seite muss aus mindestens einer Frage bestehen."
    }
}
const requiredMappingFieldsError = (id: string, mappingFields: string[]): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.MappingFields,
        message: `Folgende RIOS-Felder wurden noch nicht über externe Zuordnungen an Fragen zugeordnet: ${mappingFields.join(", ")}`
    }
}
const emptyMappingValueError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Options,
        message: "Es muss mind. einer Antwortmöglichkeit ein externes Feld zugeordnet sein."
    }
}
const emptyMappingRequiredValueError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.MappingValue,
        message: "Der Antwortmöglichkeit wurde kein externes Feld zugeordnet."
    }
}
const emptyMappingValueWarning = (id: string): ValidationWarning => {
    return {
        type: "Warning",
        id: id,
        propertyName: ValidationProperty.MappingValue,
        message: "Der Antwortmöglichkeit wurde kein externes Feld zugeordnet."
    }
}
const multiMappingFieldError = (id: string, mappingField: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.MappingDefinition,
        message: `Das externe Feld ${mappingField} wurde mehrfach zugeordnet.`
    }
}
const multiMappingFieldWarning = (id: string, mappingField: string): ValidationWarning => {
    return {
        type: "Warning",
        id: id,
        propertyName: ValidationProperty.MappingDefinition,
        message: `Das externe Feld ${mappingField} wurde mehrfach zugeordnet.`
    }
}
const emptyOptionsError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Options,
        message: "Die Frage muss mindestens eine Antwortmöglichkeit haben."
    }
}
const emptyOptionError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Option,
        message: "Die Antwortmöglichkeit darf nicht leer sein."
    }
}
const emptySubquestionsError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Subquestions,
        message: "Die Frage muss mindestens eine Unterfrage haben."
    }
}
const emptySubquestionError = (id: string): ValidationError => {
    return {
        type: "Error",
        id: id,
        propertyName: ValidationProperty.Subquestion,
        message: "Die Beschreibung darf nicht leer sein."
    }
}


function isEmpty(str: string): boolean {
    return !str || str.length === 0
}
function isEmptyArray(array: any[]) {
    return !array || array.length === 0
}

export function hasMessages(valData: ValidationData): boolean {
    let result = false;
    if (valData.messages.length > 0) {
        result = true;
    }
    else {
        valData.children.forEach(c => {
            if (hasMessages(c)) {
                result = true;
                return;
            }
        });
    }

    return result;
}

export function hasMessagesFromType(valData: ValidationData, type: "Warning" | "Error"): boolean {
    if (valData.messages.length > 0 && valData.messages.some(m => m.type === type)) {
        return true;
    }

    return valData.children.some(c => hasMessagesFromType(c, type));
}

