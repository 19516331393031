const COMPLETEDSURVEYKEY = "clientstorage_completedsurvey";

const getCompletedSurveys = () => {
    const saved = localStorage.getItem(COMPLETEDSURVEYKEY);
    if (saved) {
        return JSON.parse(saved) as string[];
    }
    return [];
}

const addCompletedSurvey = (surveyId: string) => {
    const completed = getCompletedSurveys();
    completed.push(surveyId);
    localStorage.setItem(COMPLETEDSURVEYKEY, JSON.stringify(completed));
}

export default { addCompletedSurvey, getCompletedSurveys };