import httpClient from "./httpClient";
import { User, UserManager } from "oidc-client";
import { UserChangePassword, IUserInfo } from "../../models/IUserInfo";
import { settingsService } from "./settingsService";
import { IClaim, UserClaims } from "../../models/Claim";

class UserService {
    private userManager: UserManager = {} as UserManager;
    private loggingIn = false;
    private initialized = false;
    private currentUser: User = {} as User;
    private userInfo: IUserInfo = {} as IUserInfo;
    private userClaims: UserClaims = new UserClaims(this.userInfo);

    public init = async (): Promise<User> => {
        this.initialized = true;
        this.loggingIn = false;
        this.userManager = this.createUserManager();

        try {
            const user = await this.userManager.getUser();
            if (!user || user.expired) {
                this.login();
                return {} as User;
            }
            this.currentUser = user;
            this.userInfo = await this.getUserInfo();
            this.userClaims = new UserClaims(this.userInfo);
            return user;
        }
        catch (error) {
            console.log(error);
        }
        return {} as User;
    }

    public login = () => {
        this.loggingIn = true;
        this.userManager.signinRedirect({ state: window.location.href });
    };

    public getUserInfoObject = () => {
        return this.userInfo;
    }

    public getUserClaimsObject = () => {
        return this.userClaims;
    }

    public setUserClaimsObject = (update: UserClaims) => {
        this.userClaims = update;
    }

    public getUserInfo = async (userId?: string): Promise<IUserInfo> => {
        const client = await httpClient.getInstance();

        const result = userId ?
            await client.get(`users/${userId}`) :
            await client.get("users/me");

        return result.data.user;
    }

    public isLoggingIn = () => this.loggingIn;

    public isInitialized = async () => this.initialized;

    public isExpired = async () => {
        const user = await this.userManager.getUser();
        return user?.expired ?? true;
    };

    public logout = async (): Promise<void> => await this.userManager.signoutRedirect({ extraQueryParams: { redirectUrl: window.location.href } });

    public signinSilent = async (): Promise<User> => {
        return this.userManager.signinSilent();
    };

    public getCurrentUser = (): User => {
        return this.currentUser;
    };

    public getUsers = async () => {
        const client = await httpClient.getInstance();
        const response = await client.get<UsersResponse>("users");
        return response.data.users;
    };

    public getUsersByClaim = async (claims: IClaim[]) => {
        const client = await httpClient.getInstance();
        const response = await client.post<UsersResponse>("users/byclaims", { claims });
        return response.data.users;
    }

    public addUser = async (user: IUserInfo) => {
        user.notifyUser = true;
        const client = await httpClient.getInstance();
        var response = await client.post("users", { user });
        return response.data;
    };

    public updateUser = async (user: IUserInfo): Promise<UpdateUserResponse> => {
        const client = await httpClient.getInstance();
        var response = await client.post(`users/${user.id}`, { user });
        return response.data;
    };

    public updateUsers = async (users: IUserInfo[]): Promise<UpdateUserResponse[]> => {        
        const client = await httpClient.getInstance();
        var response = await client.post(`users/updatemultiple`, { users });
        return response.data;
    };

    public deleteUser = async (userId: string): Promise<DeleteUserResponse> => {
        const client = await httpClient.getInstance();
        var response = await client.delete(`users/${userId}`);
        return response.data;
    };

    public changePassword = async (changePasswordCommand: ChangePasswordCommand): Promise<ChangePasswordResponse> => {
        const client = await httpClient.getInstance();
        var response = await client.post("users/changepassword", changePasswordCommand.changePassword);
        return response.data as ChangePasswordResponse;
    };

    private createUserManager = () => {
        const appConfig = settingsService.getConfig();
        const config: any = {
            authority: appConfig.identity.authority,
            client_id: "AdministrationClient",
            redirect_uri: `${appConfig.identity.redirectBaseUrl}/callback.html`,
            response_type: "code",
            scope: "api.administration offline_access",
            post_logout_redirect_uri: `${appConfig.identity.redirectBaseUrl}/index.html`,
            automaticSilentRenew: false,
            silent_redirect_uri: `${appConfig.identity.redirectBaseUrl}/silentrenew.html`
        };

        const um = new UserManager(config);
        um.events.addSilentRenewError(this.handleSilentRenewError);
        um.events.addAccessTokenExpired(this.handleAccessTokenExpired);
        um.events.addAccessTokenExpiring(this.handleAccessTokenExpiring);
        return um;
    }

    private handleSilentRenewError = (ev: Error) => {
        console.log("silent renew failed");
        console.log(ev);
    };

    private handleAccessTokenExpired = (ev: any[]) => {
        console.log("access token expired");
        console.log(ev);
    };

    private handleAccessTokenExpiring = (ev: any[]) => {
        console.log("access token expiring");
        this.userManager.signinSilent().then(u => this.currentUser = u);
    };
}

export const userService = new UserService();

interface UsersResponse {
    users: IUserInfo[];
}

interface ChangePasswordCommand {
    changePassword: UserChangePassword;
}

interface ChangePasswordResponse {
    success: Boolean;
    errors: string[];
}

interface UpdateUserResponse {
    success: Boolean;
    user: IUserInfo;
}

interface DeleteUserResponse {
    success: Boolean;
    errors: string[];
}