import React, { FC, useState } from "react";
import { Page, InputQuestion, NumberQuestion, SingleSelectionQuestion, MultiSelectionQuestion, RadioGroupQuestion, MatrixQuestion, QuestionTypes, DateQuestion, TextareaQuestion, isQuestionAvailable } from "../../models/SurveyVm";
import { Store } from "antd/lib/form/interface";
import _ from "lodash";
import SurveyPage from "../SurveyPage/SurveyPage";
import styles from "./Survey.module.css";
import { Steps } from "antd";
import moment from "moment";
import { isMobile } from "react-device-detect";

const Survey: FC<SurveyProps> = ({ pages, onUpdatePages, onCompleteSurvey }) => {
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [showMatrixMessage, setShowMatrixMessage] = useState(false);

    const onNext = () => {
        const showMessage = getCurrentPage().questions.some(q => isMatrixRequired(q))
        setShowMatrixMessage(showMessage);

        if (showMessage) {
            return;
        }

        for (let i = currentPageIndex + 1; i < pages.length; i++) {
            if (hasRelevantQuestions(i)) {
                setCurrentPageIndex(i);
                return;
            }
        }

        if (isLastPage()) {
            onCompleteSurvey();
        }
    }

    const onPrevious = () => {
        for (let i = currentPageIndex - 1; i >= 0; i--) {
            if (hasRelevantQuestions(i)) {
                setCurrentPageIndex(i);
                return;
            }
        }
    }

    const isFirstPage = () => currentPageIndex === 0;
    const isLastPage = () => currentPageIndex === getLastPageIdxWithQuestions();

    const getCurrentPage = () => pages[currentPageIndex];
    const getQuestionsOfPage = (pageIdx: number) => {
        return pages[pageIdx].questions.filter(q => isQuestionAvailable(q, pages));
    }
    const hasRelevantQuestions = (pageIdx: number) => getQuestionsOfPage(pageIdx).length > 0;
    const getLastPageIdxWithQuestions = () => {
        for (let i = pages.length - 1; i >= 0; i--) {
            if (hasRelevantQuestions(i)) {
                return i;
            }
        }

        return pages.length - 1;
    }

    const findQuestionById = (id: string) => pages.flatMap(p => p.questions).find(q => q.id === id);
    const findQuestionIndexById = (id: string) => getCurrentPage().questions.findIndex(q => q.id === id);

    const changeValues = (changedValues: Store) => {
        for (var prop in changedValues) {
            const question = setQuestionValue(prop, changedValues[prop]);
            if (question) {
                updateQuestion(question);
            }
        }
    }

    const changeCommentValue = (questionId: string, value: string, comment: string) => {
        const question = _.cloneDeep(findQuestionById(questionId) as RadioGroupQuestion);
        if (question.value === value) {
            question.comment = comment;
        }

        const option = question.options.find(o => o.value === value);
        if (option) {
            option.comment = comment;
            updateQuestion(question);
        }
    }

    const changeMatrixValue = (questionId: string, subquestionId: string, value: string) => {
        const question = _.cloneDeep(findQuestionById(questionId) as MatrixQuestion);
        const subquestion = question.questions.find(q => q.id === subquestionId);
        if (subquestion) {
            subquestion.value = value;
            updateQuestion(question);
        }
    }

    const isMatrixRequired = (question: QuestionTypes) => question.required
        && question.type === "Matrix"
        && (question as MatrixQuestion).questions?.some(q => q.value === "" || !q.value);

    const updateQuestion = (question: QuestionTypes) => {
        const updatedPages = _.cloneDeep(pages);
        const page = _.cloneDeep(getCurrentPage());
        page.questions.splice(findQuestionIndexById(question.id), 1, question);
        updatedPages.splice(currentPageIndex, 1, page);
        onUpdatePages(updatedPages);
    }

    const setQuestionValue = (questionId: string, value: string | string[] | moment.Moment) => {
        let question = _.cloneDeep(findQuestionById(questionId));
        switch (question?.type) {
            case "Input": (question as InputQuestion).value = value as string; break;
            case "Textarea": (question as TextareaQuestion).value = value as string; break;
            case "Number": (question as NumberQuestion).value = parseInt(value as string); break;
            case "Date": (question as DateQuestion).value = moment.utc(value as string).format(); break;
            case "SingleSelection": (question as SingleSelectionQuestion).value = value as string; break;
            case "MultiSelection": (question as MultiSelectionQuestion).values = value as string[]; break;
            case "RadioGroup": {
                question = question as RadioGroupQuestion;
                question.value = value as string;
                const option = question.options.find(o => o.value === value);
                question.comment = option?.comment;
                break;
            }
        }
        return question;
    }

    const renderPageStep = (page: Page, pageIdx: number) => {
        if (hasRelevantQuestions(pageIdx)) {
            return <Steps.Step key={page.title} subTitle={page.title} />;
        }
    }

    return (
        <div className={styles.survey}>
            <SurveyPage
                title={getCurrentPage().title}
                questions={getQuestionsOfPage(currentPageIndex)}
                showMatrixMessage={showMatrixMessage}
                isLast={isLastPage()}
                isFirst={isFirstPage()}
                onNext={onNext}
                onValueChange={changeValues}
                onMatrixValueChange={changeMatrixValue}
                onCommentValueChange={changeCommentValue}
                onBack={onPrevious}
            />
            {!isMobile &&
                <footer className={styles.footer}>
                    <Steps current={currentPageIndex} progressDot={true}>
                        {pages.map((page, i) => renderPageStep(page, i))}
                    </Steps>
                </footer>
            }
        </div>
    );
}

export default Survey;

interface SurveyProps {
    pages: Page[];
    onUpdatePages: (pages: Page[]) => void;
    onCompleteSurvey: () => void;
}

