import { Button, Layout, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import CommandBar from "../../shared/components/CommandBar/CommandBar";
import { UserList } from "./users/UserList/UserList";
import { NewUserForm } from "./users/UserForm/NewUserForm";
import HtcPageHeader from "../../shared/components/HtcPageHeader/HtcPageHeader";
import { HtcCard } from "../../shared/components/HtcCard/HtcCard";
import { userService } from "../../shared/services/userService";
import { IUserInfo } from "../../models/IUserInfo";
import { useData } from "../../shared/hooks/useData";
import { UserAddOutlined } from "@ant-design/icons";
import styles from "./Users.module.css";
import { EditUserForm } from "./users/UserForm/EditUserForm";
import { UserSearch } from "./users/UserSearch/UserSearch";
import { UserPermissionClaims } from "./users/UserPermissionClaims/UserPermissionClaims";
const Content = Layout;

export const Users: FC = () => {
    const { data, loading, triggerReload } = useData("", () => userService.getUsers());
    const [users, setUsers] = useState<IUserInfo[]>(data || []);
    const [selectedUser, setSelectedUser] = useState<IUserInfo>();
    const [searchText, setSearchText] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [openPermissions, setOpenPermissions] = useState(false);

    useEffect(() => setUsers(data || []), [data]);

    const openNewUserForm = () => setOpenNewUser(true);
    const closeNewUserForm = () => setOpenNewUser(false);
    const openEditUserForm = (user: IUserInfo) => {
        setOpenEditUser(true);
        setSelectedUser(user);
    };
    const closeEditUserForm = () => {
        setOpenEditUser(false);
        setSelectedUser(undefined);
    };
    const onOpenPermissions = (user: IUserInfo) => {
        setOpenPermissions(true);
        setSelectedUser(user);
    };
    const closePermissions = () => {
        setOpenPermissions(false);
        setSelectedUser(undefined);
    };

    const onSearchUser = (searchText: string) => setSearchText(searchText);

    const createUser = async (userData: IUserInfo) => {
        const userAlreadyExists = users.some(u => u.email === userData.email || u.userName === userData.userName);
        if (userAlreadyExists) {
            notification.error({ message: "Der Benutzer konnte nicht angelegt werden. Benutzername oder E-Mail existiert bereits." });
            return;
        }

        await userService.addUser(userData);
        triggerReload();
        closeNewUserForm();
    }

    const updateUser = async (userData: IUserInfo) => {
        await userService.updateUser(userData);
        triggerReload();
        closeEditUserForm();
    }

    const deleteUser = async (userData: IUserInfo) => {
        if (!confirm("Sind Sie sicher den Benutzer endgültig zu löschen?")) {
            return;
        }

        const result = await userService.deleteUser(userData.id);
        if (result?.success) {
            notification.success({ message: "Der Benutzer wurde erfolgreich gelöscht." });
        } else {
            notification.error({ message: "Der Benutzer konnte nicht gelöscht werden. Bitte kontaktieren Sie den Systemadministrator." });
            console.log(result?.errors);
        }

        triggerReload();
    }

    const getFilteredUsers = () => {
        const lowered = searchText.toLowerCase();
        const filtered = users.filter(user => user.firstName.toLowerCase().includes(lowered)
            || user.lastName.toLowerCase().includes(lowered)
            || user.userName.toLowerCase().includes(lowered)
            || user.email.toLowerCase().includes(lowered)
        );

        return filtered;
    }

    return (
        <Layout className={styles.layout}>
            <HtcPageHeader title={<Space>Benutzer</Space>}>
                <CommandBar>
                    <Space>
                        <UserSearch onSearch={onSearchUser} />
                        <Button type="primary" icon={<UserAddOutlined />} onClick={openNewUserForm}>Benutzer erstellen</Button>
                    </Space>
                </CommandBar>
            </HtcPageHeader>
            <Content className={styles.content}>
                <HtcCard>
                    <UserList
                        users={searchText && searchText !== '' ? getFilteredUsers() : users}
                        loading={loading}
                        onEdit={openEditUserForm}
                        onDelete={deleteUser}
                        onClose={closeEditUserForm}
                        onOpenPermissions={onOpenPermissions}
                    />
                </HtcCard>
            </Content>

            {openNewUser && <NewUserForm onSave={createUser} onClose={closeNewUserForm} />}
            {(openEditUser && selectedUser) && <EditUserForm user={selectedUser} onSave={updateUser} onClose={closeEditUserForm} />}
            {(openPermissions && selectedUser) && <UserPermissionClaims visible={openPermissions} user={selectedUser} onClose={closePermissions} />}
        </Layout>
    );
}