import { Table } from "antd";
import React, { FC } from "react";
import { SurveyReceiver, SurveyToken } from "../../../../models/SurveyTokens";
import { MetadataForm } from "../../../../shared/components/MetadataForm/MetadataForm";

export const TokenSelectionList: FC<TokenSelectionListProps> = ({ surveyTokens, metadataColumns, selectedTokenIds, onSelectionChange, onAddReceiver }) => {
    const addReceiver = (values: any) => {
        onAddReceiver({ metadata: values } as SurveyReceiver);
    };

    const columns = metadataColumns.map(column => ({
        title: column,
        dataIndex: column,
        render: (text: string, record: any) => record?.metadata[column]
    }));

    return (<>
        <MetadataForm metadataColumns={metadataColumns} onSave={addReceiver} />
        <Table
            columns={columns}
            dataSource={surveyTokens}
            rowKey={(record: SurveyToken) => record.id}
            rowSelection={{
                selectedRowKeys: selectedTokenIds,
                onChange: onSelectionChange,
                type: "checkbox"
            }}
        />
    </>);
}

interface TokenSelectionListProps {
    metadataColumns: string[];
    surveyTokens: SurveyToken[];
    selectedTokenIds: string[];
    onSelectionChange: (newSelectedRowKeys: React.Key[], selectedRows: SurveyToken[]) => void;
    onAddReceiver: (values: SurveyReceiver) => void;
}