import React, { FC, useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import { SurveyTemplate, QuestionTypes as QuestionTypesTemplate } from '../../../models/SurveyTemplate';
import Survey from '../../../surveys/Survey/Survey';
import Surveys from '../../../surveys/Surveys';
import { DateQuestion, InputQuestion, TextareaQuestion, MatrixQuestion, MultiSelectionQuestion, Page, QuestionTypes, RadioGroupQuestion, SingleSelectionQuestion, NumberQuestion, SurveyVm } from '../../../models/SurveyVm';
import { CloseOutlined } from '@ant-design/icons';
import styles from './SurveyPreview.module.css';
import { Prompt } from 'react-router';
import { settingsService } from '../../../shared/services/settingsService';
import CommandBar from '../../../shared/components/CommandBar/CommandBar';
import { HtcCard } from '../../../shared/components/HtcCard/HtcCard';
const { Content } = Layout;
const ABORT_MESSAGE = "Wollen Sie die Bearbeitung wirklich abbrechen? Alle bisher vorgenommenen Änderungen gehen hiermit verloren.";

const SurveyPreview: FC<SurveyPreviewProps> = ({ surveyOrTemplate, onClose }) => {
    const appConfig = settingsService.getConfig();
    const [surveyView, setSurveyView] = useState<"welcome" | "survey" | "completed">("welcome");
    const [pages, setPages] = useState<Page[] | undefined>();
    useEffect(() => {
        if (isSurveyVm(surveyOrTemplate)) {
            console.log(surveyOrTemplate.pages);
            setPages(surveyOrTemplate.pages);
        }
        else {
            setPages(mapPages())
        }
    }, [surveyOrTemplate]);

    const mapSurvey = (): SurveyVm => ({
        id: surveyOrTemplate.id,
        title: surveyOrTemplate.title,
        description: surveyOrTemplate.description,
        closingWords: surveyOrTemplate.closingWords,
        pages: mapPages(),
        validFrom: new Date(),
        validTo: new Date(),
        enableLastTokenAnswerTransfer: surveyOrTemplate.enableLastTokenAnswerTransfer,
        isAnonymous: surveyOrTemplate.isAnonymous,
        mailSender: surveyOrTemplate.mailSender,
        mailSettings: surveyOrTemplate.mailSettings,
        reminderMailSettings: surveyOrTemplate.reminderMailSettings,
        metadataColumns: []
    })

    const mapPages = (): Page[] => surveyOrTemplate?.pages?.map(p => {
        return ({
            title: p.title,
            questions: p.questions.map(q => mapQuestionType(q))
        })
    });

    const isSurveyVm = (survey: SurveyTemplate | SurveyVm): survey is SurveyVm => {
        return (survey as SurveyVm).validFrom !== undefined;
    }

    const mapQuestionType = (question: QuestionTypesTemplate): QuestionTypes => {
        switch (question.type) {
            case "Input": return question as InputQuestion;
            case "Textarea": return question as TextareaQuestion;
            case "Number": return question as NumberQuestion;
            case "SingleSelection": return question as SingleSelectionQuestion;
            case "MultiSelection": return question as MultiSelectionQuestion;
            case "RadioGroup": return question as RadioGroupQuestion;
            case "Matrix": return question as MatrixQuestion;
            case "Date": return question as DateQuestion;
            default: throw "not supported question type";
        };
    };

    const completeSurvey = () => setSurveyView("completed");
    const startSurvey = () => setSurveyView("survey");
    const updatePages = (updatePages: Page[]) => setPages(updatePages);

    const getView = () => {
        let surveyVm = surveyOrTemplate as SurveyVm;

        if (!isSurveyVm(surveyOrTemplate)) {
            surveyVm = mapSurvey();
        }

        switch (surveyView) {
            case "welcome": return <Surveys.WelcomePage appConfig={appConfig} survey={surveyVm} onStart={startSurvey} />;
            case "completed": return <Surveys.SurveysCompleted survey={surveyVm} />;
            case "survey": return pages &&
                <Survey
                    pages={pages}
                    onUpdatePages={updatePages}
                    onCompleteSurvey={completeSurvey}
                />;
        }
    };

    return (<>
        {surveyOrTemplate &&
            <Layout className={styles.layout}>
                <HtcCard>
                    <CommandBar>
                        <Button className={styles.closebtn} type="primary" icon={<CloseOutlined />} onClick={onClose}>Schließen</Button>
                    </CommandBar>
                    <Layout>
                        <Content className={styles.content}>
                            {getView()}
                        </Content>
                    </Layout>
                    <Prompt message={(location, action) => {
                        return action === "POP"
                            ? ABORT_MESSAGE
                            : true;
                    }} />
                </HtcCard>
            </Layout>

        }
    </>);
};

export default SurveyPreview;


interface SurveyPreviewProps {
    surveyOrTemplate: SurveyTemplate | SurveyVm;
    onClose: () => void;
}