import React, { useState } from "react";
import { Button, Space, Table } from "antd";
import { FC } from "react";
import { NumberOutlined, DeleteOutlined, BarChartOutlined } from "@ant-design/icons";
import { SurveTokenStateEnum, SurveyToken } from "../../../../models/SurveyTokens";
import { settingsService } from "../../../../shared/services/settingsService";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { TokenAnswersModal } from "./TokenAnswersModal";

const dateFormat = "DD.MM.YYYY";

export const TokensList: FC<TokensListProps> = ({ tokens, metadataColumns, isAnonymous, onDeleteToken, hasEditAccess }) => {
    const config = settingsService.getConfig();
    const [tokenAnswersModalOpen, setTokenAnswersModalOpen] = useState(false);
    const [surveyToken, setSurveyToken] = useState<SurveyToken | null>(null);

    const openTokenAnswersModal = (token: SurveyToken) => {
        setTokenAnswersModalOpen(true);
        setSurveyToken(token);
    }

    const closeTokenAnswersModal = () => setTokenAnswersModalOpen(false);

    const columns: ColumnsType<SurveyToken> = [
        {
            title: "Token",
            dataIndex: "token",
            key: "token",
            render: (text: string, record: any) => <Space><NumberOutlined /><a href={`${config.identity.redirectBaseUrl}/surveys/${record.token}`} target="_blank">{record.token}</a></Space>,
            sorter: (a: SurveyToken, b: SurveyToken) => a.token?.localeCompare(b.token.toString())
        },
        {
            title: "Gültig von",
            dataIndex: "validFrom",
            key: "validFrom",
            render: (text: string, record: any) => `${moment(record.validFrom).format(dateFormat)}`,
            sorter: (a: SurveyToken, b: SurveyToken) => moment(a.validFrom).unix() - moment(b.validFrom).unix()
        },
        {
            title: "Gültig bis",
            dataIndex: "validTo",
            key: "validTo",
            render: (text: string, record: any) => `${moment(record.validTo).format(dateFormat)}`,
            sorter: (a: SurveyToken, b: SurveyToken) => moment(a.validTo).unix() - moment(b.validTo).unix()
        }

    ];

    if (!isAnonymous) {
        columns.push(...metadataColumns.map(field => ({
            title: field,
            dataIndex: field,
            key: field,
            render: (text: string, record: any) => record.metadata ? record.metadata[field] : "",
            sorter: (a: any, b: any) => a?.localeCompare(b)
        })));
    }

    columns.push({
        key: "actions",
        align: "right",
        render: (text, record: SurveyToken) =>
            <Space>
                {record.surveyTokenState && record.surveyTokenState.state === SurveTokenStateEnum.Completed &&
                    <Button
                        title="Antworten anzeigen"
                        type="text"
                        icon={<BarChartOutlined />}
                        onClick={() => openTokenAnswersModal(record)} />}
                {hasEditAccess &&
                    <Button
                        title="Token löschen"
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => onDeleteToken(record.id)}
                    />}
            </Space>
    });

    return (
        <>
            <Table
                columns={columns}
                dataSource={tokens}
            />
            {surveyToken && tokenAnswersModalOpen &&
                <TokenAnswersModal
                    visible={tokenAnswersModalOpen}
                    surveyToken={surveyToken}
                    onClose={closeTokenAnswersModal}
                />
            }
        </>
    );
};

interface TokensListProps {
    tokens: SurveyToken[];
    metadataColumns: string[];
    isAnonymous: boolean;
    hasEditAccess: boolean;
    onDeleteToken: (tokenId: string) => void;
}

